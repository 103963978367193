import {
  Page,
  Recommendation,
  PatientRecommendation,
  BiomarkerCode,
  Report,
  Scenario,
  Biomarker,
  BiomarkerLevels,
  Result,
  Sex,
  BiomarkerColorCodeRecommendation,
  Observation,
  CmsColorCode,
  ColorCodeDeveloperConfig,
  RecommendationHeaderColor,
  SpecificReportResponse,
  ConclusionCode,
} from 'types';
import reportClient, { GET_COLOR_CODES, GET_RECOMMENDATION_HEADER_COLORS } from 'kit-activation';
import * as Sentry from 'sentry-expo';
import { isQualitativeTest } from './data';

const REPORT_COLORS_SYSTEM = 'https://fhir.imaware.health/codesystems/report-colors';

export const getReport = (content: SpecificReportResponse | undefined, observations: Observation[]): Report => {
  const reportRecord = content?.allReports?.[0] ?? ({} as Report);
  const isQualitative = isQualitativeTest(observations);

  return {
    ...reportRecord,
    pages: isQualitative ? reportRecord.pages.filter((page) => page.breadcrumb !== 'Details') : reportRecord.pages,
  } as Report;
};

export const getPage = (pages: Page[], pageName: string): Page => pages.find((page) => page.__typename === pageName) ?? ({} as Page);

export const getScenarioContent = (scenarios: Scenario[], reportCode: string) => scenarios.find(({ code }) => code.developerConfig.key === reportCode) ?? ({} as Scenario);

export const getBiomarkerLevel = (biomarkers: Biomarker[], results: Result[], sex: Sex): BiomarkerLevels[] => {
  const biomarkerLevels: BiomarkerLevels[] = [];
  // eslint-disable-next-line consistent-return
  biomarkers.forEach((biomarker) => {
    const { name, ranges, elevatedRecs, lowRecs, developerConfig } = biomarker;
    const sexSpecificRanges = ranges[sex] ?? ranges;
    const { segments, boolean, reportable_range } = sexSpecificRanges;
    const isValueStringReportableRangeBoolean = typeof reportable_range[0] === 'string';
    const result = results.find(({ key }) => key === biomarker.developerConfig.key);
    if (!result) return null;
    const value = boolean || isValueStringReportableRangeBoolean ? result.qualitativeResult ?? '' : result.quantitativeResult ?? 0;
    const lowercasedValue = value.toString().toLowerCase();
    let outcome = '';
    if (boolean) {
      outcome = value ? 'Positive' ?? '' : 'Negative' ?? '';
    } else if (isValueStringReportableRangeBoolean) {
      outcome =
        lowercasedValue === 'positive' // eslint-disable-line no-nested-ternary
          ? 'Positive'
          : lowercasedValue === 'negative'
            ? 'Negative'
            : `${value}`;
    } else {
      const targetRange = segments.find((segment) => segment.code === getColorCodeColorWithDevConfigBoolean('targetRange', true));
      const optimalRange = segments.find((segment) => segment.code === getColorCodeColorWithDevConfigBoolean('optimumRange', true));
      if (!targetRange) return null;
      if (!targetRange.upperBoundInclusive && value >= targetRange.range[1]) {
        if (optimalRange && !optimalRange.upperBoundInclusive && value < optimalRange.range[1]) {
          outcome = 'Normal' ?? '';
        } else {
          outcome = 'Elevated' ?? '';
        }
      } else if (targetRange.upperBoundInclusive && value > targetRange.range[1]) {
        if (optimalRange && optimalRange.upperBoundInclusive && value <= optimalRange.range[1]) {
          outcome = 'Normal' ?? '';
        } else {
          outcome = 'Elevated' ?? '';
        }
      } else if (!targetRange.lowerBoundInclusive && value <= targetRange.range[0]) {
        outcome = 'Low' ?? '';
      } else if (targetRange.lowerBoundInclusive && value < targetRange.range[0]) {
        outcome = 'Low' ?? '';
      } else outcome = 'Normal' ?? '';
    }
    biomarkerLevels.push({
      name,
      level: outcome,
      elevatedRecs,
      lowRecs,
      developerConfig,
    });
  });
  return biomarkerLevels;
};

export const getRecommendations = (recommendations: Recommendation[], biomarkerCodes: BiomarkerCode[], biomarkerLevels: BiomarkerLevels[]): PatientRecommendation[] => {
  let patientRecommendations: PatientRecommendation[] = [];
  const defaultRec = recommendations.find(({ recommendationId }) => recommendationId === 999) ?? ({} as Recommendation);
  let positiveBiomarker = false;
  biomarkerLevels.forEach((biomarkerLevel: BiomarkerLevels) => {
    const { developerConfig, level, lowRecs, elevatedRecs } = biomarkerLevel;
    let recColor: string;
    const lowerCasedLevel = level.toLowerCase();
    const positiveOrNegative = lowerCasedLevel === 'positive' || lowerCasedLevel === 'negative';

    if (positiveOrNegative) {
      const recType = lowerCasedLevel === 'negative' ? lowRecs : elevatedRecs;
      const recTypeRecommendations = recType.recommendations as number[];
      recTypeRecommendations.forEach((recId: number) => {
        let value = level;
        if (recId === 40) {
          value = 'Neutral';
        }
        const recommendation = recommendations.find(({ recommendationId }) => recommendationId === recId);
        const biomarker = biomarkerCodes.find(({ key }) => key === developerConfig.key);
        // check if recommendation already exists - prevent duplicates
        const index = patientRecommendations.findIndex(({ rec }) => rec.recommendationId === recommendation?.recommendationId);
        // if recommendation exists, just add biomarker to the recommendation
        if (index > -1) {
          patientRecommendations[index].biomarkers.push(
            {
              key: biomarker!.key,
              value,
            } || {
              key: 'default',
            }
          );
          // otherwise push the new recommendation
        } else {
          patientRecommendations.push({
            rec: recommendation || defaultRec,
            biomarkers: [
              {
                key: biomarker!.key,
                value,
              } || {
                key: 'default',
              },
            ],
          });
        }
        if (lowerCasedLevel === 'positive' || positiveBiomarker) {
          positiveBiomarker = true;
          patientRecommendations = patientRecommendations.filter((rec) => rec.rec.recommendationId !== 41);
        }
      });
    } else if (level !== 'Normal' && !positiveOrNegative) {
      // run block if biomarker level is abnormal
      const recType = level === 'Low' ? lowRecs : elevatedRecs;
      let recTypeRecommendations = recType.recommendations as number[];
      if (level !== 'Low' && developerConfig.specialRecommendations) {
        recColor = biomarkerCodes.find((b) => b.key === developerConfig.key)?.code ?? '';

        const recs = recType.recommendations as BiomarkerColorCodeRecommendation;
        recTypeRecommendations = recs[recColor];
      }
      (recTypeRecommendations ?? []).forEach((recId: number) => {
        const recommendation = recommendations.find(({ recommendationId }) => recommendationId === recId);
        const biomarker = biomarkerCodes.find(({ key }) => key === developerConfig.key);
        // check if recommendation already exists - prevent duplicates
        const index = patientRecommendations.findIndex(({ rec }) => rec.recommendationId === recommendation?.recommendationId);
        // if recommendation exists, just add biomarker to the recommendation
        if (index > -1) {
          patientRecommendations[index].biomarkers.push(biomarker || { key: 'default' });
          // otherwise push the new recommendation
        } else {
          patientRecommendations.push({
            rec: recommendation || defaultRec,
            biomarkers: [biomarker || { key: 'default' }],
          });
        }
      });
    }
  });

  // if all biomarker levels are normal - provide default recommendation
  if (patientRecommendations.length < 1) {
    patientRecommendations.push({
      rec: defaultRec,
      biomarkers: [{ key: 'default' }],
    });
  }
  return patientRecommendations;
};

export const getColorCodes = (): CmsColorCode[] => {
  const colorCodesData = reportClient.cache.readQuery<{
    colorCodes: CmsColorCode[];
  }>({
    query: GET_COLOR_CODES,
  });
  if (!colorCodesData?.colorCodes?.length) {
    Sentry.Browser.captureMessage('No CmsColorCodes found', {
      extra: {
        event: 'getColorCodes',
        message: 'No CmsColorCodes found, this will likely break interpretations based on colors',
      },
    });
    return [];
  }
  return colorCodesData.colorCodes;
};

// eslint-disable-next-line max-len
export const getColorCodeColorWithDevConfigBoolean = (configKey: keyof ColorCodeDeveloperConfig, configValue: boolean) => getColorCodes().find((colorCode) => colorCode.developerConfig[configKey] === configValue)?.color ?? '';

// eslint-disable-next-line max-len
export const getColorCodeIndexWithDevConfigBoolean = (configKey: keyof ColorCodeDeveloperConfig, configValue: boolean) => getColorCodes().findIndex((colorCode) => colorCode.developerConfig[configKey] === configValue);

export const getReportBiomarkersConfig = (content: SpecificReportResponse | undefined): Biomarker[] => {
  const { biomarkers: reportBiomarkers = [] } = getPage((content?.allReports?.[0]?.pages ?? []) as Page[], 'ReportDetailsPageRecord');
  return reportBiomarkers;
};

export const getRecommendationHeaderColors = (): RecommendationHeaderColor[] => {
  const recommendationHeaderColorsData = reportClient.cache.readQuery<{
    headerColors: RecommendationHeaderColor[];
  }>({
    query: GET_RECOMMENDATION_HEADER_COLORS,
  });
  if (!recommendationHeaderColorsData?.headerColors?.length) {
    Sentry.Browser.captureMessage('No RecommendationHeaderColors found', {
      extra: {
        event: 'getRecommendationHeaderColors',
        message: 'No RecommendationHeaderColors found, this will likely break some layout',
      },
    });
    return [];
  }
  return recommendationHeaderColorsData.headerColors;
};

export const calculateReportCode_v2 = (conclusionCode?: ConclusionCode[]): CmsColorCode => {
  const reportCodes = getColorCodes();
  let codeIndex = 0; // start at lowest code
  const conclusionCodeReportColor =
    conclusionCode?.find((cc) => cc.coding?.some((c) => c.system === REPORT_COLORS_SYSTEM))?.coding?.find((c) => c.system === REPORT_COLORS_SYSTEM)?.code || 'white';
  const matchedCodeIndex = reportCodes.findIndex((reportCode) => reportCode.imaIntColorCode === conclusionCodeReportColor.toUpperCase());
  if (codeIndex < matchedCodeIndex) {
    codeIndex = matchedCodeIndex;
  }

  return reportCodes[codeIndex];
};

export const computeReportVersion = (conclusionCode?: ConclusionCode[], conclusion?: string, observations?: Observation[]) => {
  const isv2: boolean = (conclusion && conclusionCode?.length && observations?.some((o) => o.interpretation?.length)) || false;
  return isv2 ? 'imaware_v2' : 'imaware_v1';
};
