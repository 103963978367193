import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { View, StyleSheet, Image, Platform } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';
import { useMediaQuery } from 'react-responsive';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import * as Sentry from 'sentry-expo';

import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import ScreenWrapper from 'components/ScreenWrapper';
import CheckboxKitCode from 'components/CheckboxKitCode';
import HeaderBar from 'components/HeaderBar';
import { Patient, TestHistory, TestRequirements, BackendlessKitRecord, PartnerConfig } from 'types';

import { cmsClient } from 'sample-collection-instructions';
import { GET_PATIENT, GET_PATIENT_TEST_HISTORY } from 'screens/dashboard/PatientDashboardScreen/queries';
import GlobalStyles from 'constants/Styles';

import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';
import { activationIdNormalization } from 'utils/helper';
import TestingRequirements from './components/Requirements';
import { GET_KIT_ID, TEST_REQUIREMENTS_BY_SKU } from './queries';
import { Box } from './styles';
import { ACTIVATE_KIT } from '../EnterKitIdScreen/queries';
import client, { GET_PARTNER_CONFIG } from '../../../kit-activation';

const ConfirmTestingRequirementsScreen = ({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers;
  route: RouteProp<{ params: { kitId: string } }, 'params'>;
}): JSX.Element => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  const partnerEmail = partnerConfig?.contactEmail;

  const { isAuthenticated } = useCheckOrRefreshAuth();

  const [testName, setTestName] = useState('');
  const [collectionDevice, setCollectionDevice] = useState('');
  const [requirements, setRequirements] = useState<TestRequirements | null>(null);
  const [testAgree, setTestAgree] = useState(false);
  const [ownerAgree, setOwnerAgree] = useState(false);
  const [testMatchError, setTestMatchError] = useState(false);
  const [testingRequirementError, setTestingRequirementError] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [testActivated, setTestActivated] = useState(false);
  const [kitRecord, setKitRecord] = useState<BackendlessKitRecord | null>(null);
  const kitId = activationIdNormalization(route.params.kitId);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigation.navigate('Registration');
    }
  }, []);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  /**
   * Right now we fetch the test requirement based on the SKU text field attached to the datocms files.
   * @param test - DatoCMS object filtered by the labPrefix get by the kitRecord.
   */
  const testRequirementHandler = (test: TestRequirements) => {
    if (kitRecord) {
      setTestName(test.title);
      setRequirements(test);
      const { developerConfig } = test;
      const { collectionDevices } = developerConfig;

      if (developerConfig && collectionDevices) {
        const deviceKeys = Object.keys(collectionDevices);
        const matchingDeviceKey = deviceKeys.find((key) => kitRecord.kitId.includes(key));
        if (matchingDeviceKey) {
          const matchingDevice = collectionDevices[matchingDeviceKey];
          setCollectionDevice(matchingDevice);
          setTestMatchError(false);
        }
      }
    }
  };

  useQuery(GET_PATIENT, {
    onCompleted: ({ getPatient }) => {
      setPatient(getPatient);
    },
    onError: (err) => {
      console.error(`An error occurred while retrieving Patient with id: ${kitId}`);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetPatient',
          message: `Patient retrieval for kit id ${kitId} failed`,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
    },
  });

  const { loading: testNameLoading } = useQuery(GET_PATIENT_TEST_HISTORY, {
    fetchPolicy: 'network-only',
    skip: !kitRecord,
    onCompleted: ({ getPatientTestHistory }: { getPatientTestHistory: TestHistory[] }) => {
      const matchingPatientTest = getPatientTestHistory.find((test) => test.kitId === kitRecord?.kitId)?.testType;
      if (matchingPatientTest) {
        setTestActivated(true);
        onShowCollectionInstructions();
      }
    },
    onError: (err) => {
      console.error(`An error occurred while retrieving patient test history for kit id: ${kitId}`, err);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetPatientTestHistory',
          message: `Patient test history retrieval for kit id ${kitId} failed`,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
    },
  });

  const { loading: getKitIdLoading, error: getKitIdError } = useQuery(GET_KIT_ID, {
    fetchPolicy: 'network-only',
    variables: { kitId },
    onCompleted: ({ getKitId: _kitRecord }: { getKitId: BackendlessKitRecord }) => {
      setKitRecord(_kitRecord);
    },
    onError: (err) => {
      console.error(`An error occurred while retrieving the kitId record for kit id: ${kitId}`, err);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetKitId',
          message: `KitId record retrieval for kit id ${kitId} failed`,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
    },
  });

  const { loading, error } = useQuery(TEST_REQUIREMENTS_BY_SKU, {
    client: cmsClient,
    fetchPolicy: 'network-only',
    skip: !kitRecord,
    variables: { pattern: `(${kitRecord?.labPrefix})` },
    onCompleted: (data) => {
      if (!!data.allTestRequirements && data.allTestRequirements.length > 0) {
        testRequirementHandler(data.allTestRequirements[0]);
      } else {
        setTestMatchError(true);
      }
    },
    onError: () => {
      setTestMatchError(true);
      Sentry.Browser.captureException(`Matching test requirements for kit id ${kitId} not found`, {
        extra: {
          message: `Matching test requirements for kit id ${kitId} not found`,
          labPrefix: kitRecord?.labPrefix,
          testRequirementCount: 0,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
    },
  });

  const [activateKit, { loading: activateKitLoading, error: activateKitError }] = useMutation(ACTIVATE_KIT, {
    onCompleted: () => {
      setTestActivated(true);
      onShowCollectionInstructions();
    },
    onError: (err) => {
      console.error(`An error occurred while activating kit with id: ${kitId}`, err);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'ActivateKit',
          message: `Kit activation for kit id ${kitId} failed with error: ${err}`,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
    },
  });

  const onSubmit = () => {
    if (testActivated) {
      onShowCollectionInstructions();
    } else {
      if (!testAgree || !ownerAgree) {
        setTestingRequirementError(true);
        return;
      }
      setTestingRequirementError(false);
      activateKit({ variables: { kitId, partner: window.config?.PARTNER_NAME } });
    }
  };

  const onShowCollectionInstructions = () => {
    localStorage.removeItem('kitid');
    if (Platform.OS === 'web') {
      Linking.openURL(`${partnerConfig?.collectionInstructionsSite}/${collectionDevice}`);
    } else {
      WebBrowser.openBrowserAsync(`${partnerConfig?.collectionInstructionsSite}/${collectionDevice}`).then(() => {
        navigation.navigate('PatientDashboardScreen');
      });
    }
  };

  return (
    <>
      <ScreenWrapper loading={loading || activateKitLoading || testNameLoading || getKitIdLoading || testActivated}>
        {!isMobile ? <HeaderBar navigation={navigation} /> : null}
        <View style={styles.contentBackground}>
          <View style={isMobile ? [styles.contentContainer, styles.mobileContentContainer] : styles.contentContainer}>
            <Box isMobile={isMobile}>
              <Text lora style={styles.headerText}>
                Before you begin your {testName || 'test'}
              </Text>
              {!testMatchError ? (
                <>
                  <Text lato style={styles.requirementsText}>
                    Please agree to and follow the requirements outlined below to ensure your test results will be as accurate as possible.
                  </Text>
                  {requirements ? <TestingRequirements requirements={requirements} setTestAgree={setTestAgree} /> : null}
                  {!testActivated ? (
                    <>
                      <View style={styles.checkboxWrapper}>
                        <CheckboxKitCode onChange={() => setOwnerAgree(!ownerAgree)} checked={ownerAgree} />
                        <Text style={styles.confirmPhrase}>I am ready to collect my sample right now</Text>
                      </View>
                    </>
                  ) : null}
                  <View style={isMobile ? styles.alertContainer : [styles.alertContainer, { maxHeight: 74 }]}>
                    <View style={styles.alertCopyContainer}>
                      <Image style={styles.alertIconContainer} source={require('assets/images/alert-icon.png')} />
                      <Text lato fontWeight="700">
                        Please do not proceed beyond this screen unless you are ready to collect your sample right now.
                      </Text>
                    </View>
                  </View>
                </>
              ) : null}
              {testingRequirementError ? (
                <View style={styles.errorContainer}>
                  <Image source={require('assets/images/error-icon.png')} style={styles.errorIcon} />
                  <Text lato style={styles.errorText}>
                    You must meet all of the above requirements before beginning to collect your sample to ensure accurate results.{' '}
                  </Text>
                </View>
              ) : null}
              {error || getKitIdError ? (
                <View style={styles.errorContainer}>
                  <Text lato style={[GlobalStyles.error, styles.errorText]}>
                    Something went wrong while trying to activate your activation code. Please contact{' '}
                    <a href={`mailto:${partnerEmail}`} style={{ color: '#DC412C' }}>
                      {partnerEmail}
                    </a>{' '}
                    for further assistance
                  </Text>
                </View>
              ) : null}
              {activateKitError ? (
                <View style={styles.errorContainer}>
                  <Text lato style={[GlobalStyles.error, styles.errorText]}>
                    {activateKitError.message}
                    <a href={`mailto:${partnerEmail}`} style={{ color: '#DC412C' }}>
                      {partnerEmail}
                    </a>{' '}
                    for further assistance
                  </Text>
                </View>
              ) : null}
              {testMatchError ? (
                <Text lato style={[GlobalStyles.error, styles.errorText]}>
                  Something went wrong when retrieving your testing requirements. Please contact{' '}
                  <a href={`mailto:${partnerEmail}`} style={{ color: '#DC412C' }}>
                    {partnerEmail}
                  </a>{' '}
                  for further assistance
                </Text>
              ) : null}
              {!isMobile && !testMatchError ? (
                <Button
                  onPress={onSubmit}
                  arrowRight
                  primary
                  style={[styles.continueButton, !testingRequirementError ? styles.noErrorSpacing : null]}
                  textStyle={styles.continueButtonText}
                >
                  Continue
                </Button>
              ) : null}
            </Box>
          </View>
        </View>
      </ScreenWrapper>
      {isMobile && !testMatchError ? (
        <Button onPress={onSubmit} arrowRight primary style={[styles.continueMobileButton]} textStyle={styles.continueMobileButtonText}>
          CONTINUE
        </Button>
      ) : null}
    </>
  );
};

export default ConfirmTestingRequirementsScreen;

const styles = StyleSheet.create({
  middleContainer: {
    marginVertical: 15,
  },
  confirmPhrase: { paddingLeft: 15 },
  testBtn: {
    color: 'white',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 26,
    lineHeight: 34,
    marginBottom: 13,
    fontWeight: '600',
  },
  requirementsText: {
    lineHeight: 22,
    marginBottom: 32,
    fontSize: 18,
  },
  acknowledgementText: {
    lineHeight: 22,
    marginBottom: 7,
    fontSize: 16,
  },
  contentBackground: {
    backgroundColor: '#FBF8F1',
    alignItems: 'center',
    height: '100%',
    overflow: 'scroll',
  },
  contentContainer: {
    width: 576,
    minHeight: 615,
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 40,
    borderRadius: 24,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
    alignItems: 'center',
    paddingTop: 42,
    paddingBottom: 47,
  },
  mobileContentContainer: {
    marginHorizontal: 18,
    width: 'auto',
  },
  alertContainer: {
    backgroundColor: '#E8EEF5',
    padding: 15,
    paddingLeft: 5,
    marginBottom: 15,
    borderRadius: 5,
    marginTop: 22,
    maxWidth: 465,
  },
  alertCopyContainer: { display: 'flex', flexDirection: 'row' },
  alertIconContainer: { width: 49, height: 49, marginRight: 15 },
  continueButton: {
    display: 'flex',
    height: 44,
    width: 232,
    justifyContent: 'center',
  },
  continueMobileButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    height: 70,
    backgroundColor: '#116FA4',
  },
  continueButtonText: {
    fontWeight: '600',
  },
  continueMobileButtonText: {
    letterSpacing: 2,
    lineHeight: 22,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorText: {
    lineHeight: 20,
    letterSpacing: 0.41,
    color: '#DC412C',
    marginTop: 10,
    marginBottom: 20,
  },
  errorIcon: {
    height: 20.8,
    width: 20.8,
    marginRight: 14,
    marginTop: 8,
  },
  noErrorSpacing: {
    marginTop: 26,
  },
  requirementConfirmCheckbox: {
    marginTop: 15,
    marginBottom: 12,
  },
  checkboxWrapper: {
    marginVertical: 8,
    marginHorizontal: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileButtonText: { textTransform: 'uppercase', fontFamily: 'lato_700' },
});
