import { gql } from '@apollo/client';

const GET_TEST_RESULTS = gql`
  mutation GetTestResults($kitId: String!) {
    getTestResults(kitId: $kitId) {
      patient {
        name {
          family
          given
        }
        gender
        birthDate
        id
      }
      observations {
        code {
          coding {
            code
            display
            system
          }
          name: text
        }
        valueQuantity {
          comparator
          unit
          value
        }
        valueString
        interpretation {
          coding {
            code
            display
            system
          }
          text
        }
        status
      }
      testType
      testedDate
      conclusion
      conclusionCode {
        coding {
          code
          display
          system
        }
      }
    }
  }
`;

export default GET_TEST_RESULTS;
