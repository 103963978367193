import { createElement } from 'react';
import { FieldError, Noop } from 'react-hook-form';

const DateTimePicker = ({
  onChange,
  value,
  onBlur,
  error,
  testID,
  accessible,
}: {
  // eslint-disable-next-line no-unused-vars
  onChange: (e: string) => void;
  error: FieldError | undefined;
  value: string;
  onBlur: Noop;
  testID: string;
  accessible: boolean;
}) =>
  createElement('input', {
    type: 'date',
    max: '9999-12-31',
    value,
    onInput: onChange,
    onBlur,
    testID,
    accessible,
    style: {
      flexDirection: 'row-reverse',
      height: 48,
      width: 'auto',
      margin: '10px 0px',
      backgroundColor: '#f7f7f7',
      textAlign: 'center',
      borderWidth: 1,
      borderColor: typeof error === 'object' ? '#DC412C' : '#F7F7F7',
      borderRadius: 4,
      borderStyle: 'solid',
      minWidth: 160,
    },
  });

export default DateTimePicker;
