import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from 'sentry-expo';

import { authenticateUser, isAuthenticated } from 'auth/auth0';
import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import GlobalStyles from 'constants/Styles';

import { checkKitIdParam } from 'screens/registration/EmailRegistrationScreen/utils';
import { GET_PATIENT } from 'screens/dashboard/PatientDashboardScreen/queries';
import HeaderBar from 'components/HeaderBar';
import { checkAuthParams } from './utils';

const LoginScreen = ({ navigation }: { navigation: StackNavigationHelpers }): JSX.Element => {
  const [signinError, setSignInError] = useState<string | null>(null);
  const [getPatient, { loading, error: getPatientError }] = useLazyQuery(GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPatient: getPatientResponse }) => {
      if (getPatientResponse.id) {
        navigation.navigate('KitActivation');
      } else if (getPatientResponse.email_verified) {
        navigation.navigate('CompletePatientProfileScreen');
      } else {
        navigation.navigate('EmailVerificationScreen');
      }
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetPatient',
          message: `Patient retrieval on LoginScreen failed`,
        },
      });
    },
  });

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const onSignIn = () => {
    authenticateUser()
      .then(() => {
        isAuthenticated().then((isAuth: boolean) => {
          if (isAuth) {
            getPatient();
          }
        });
      })
      .catch((err) => {
        console.error('### Error: ', JSON.stringify(err));
        setSignInError(err.message);
      });
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      checkAuthParams()
        .then((token) => {
          if (token && typeof token === 'string') {
            checkKitIdParam();
            getPatient();
          }
        })
        .catch((err) => {
          console.error('### Error: ', JSON.stringify(err));
          setSignInError(err.message);
        });
    }
  }, []);

  return (
    <ScreenWrapper loading={loading} error={getPatientError} miniFooter excludeWrapper={true} excludeFooter={isMobile}>
      <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
        <HeaderBar
          navigation={navigation}
          containerBackgroundColor={{
            backgroundColor: isMobile ? '#FBF8F1' : 'white',
          }}
        />
        <View style={styles.box}>
          <View style={isMobile ? styles.mobileBox : styles.desktopBox}>
            <Text large style={styles.title} testID="titleLogin">
              Log in
            </Text>
            {signinError ? <Text style={[GlobalStyles.error, styles.subtitleDesktop, styles.error]}>{signinError}</Text> : null}
            <Text small style={isMobile ? styles.subtitle : styles.subtitleDesktop} testID="textLogin">
              Please log in to your secure imaware {'\n'} patient portal to activate a new kit or view {'\n'} your results.
            </Text>
            <View style={styles.btnLogin} testID={'signInBtn'}>
              <TouchableOpacity onPress={onSignIn} style={isMobile ? styles.button : styles.desktopButton}>
                <Text lato style={styles.textLabel} testID="signInLogin">
                  Sign In
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.registerBox}>
              <Text lato small testID="accountLogin">
                Don't have an account?
              </Text>
              <TouchableOpacity onPress={() => navigation.navigate('EmailRegistrationScreen')} testID="btnRegistration">
                <Text lato style={styles.signUpLabel} testID="signUpLogin">
                  Sign Up
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  desktopBox: {
    minWidth: 300,
    maxWidth: 600,
    minHeight: 400,
    maxHeight: 600,
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  mobileBox: {
    width: '100%',
    minHeight: 400,
    maxHeight: 600,
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 28,
    marginVertical: 10,
    fontFamily: 'Lora',
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 22,
    marginVertical: 10,
    width: 400,
    fontFamily: 'Lato',
  },
  subtitleDesktop: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 22,
    marginVertical: 10,
    width: 400,
    fontFamily: 'Lato',
  },
  button: {
    width: 223,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
    backgroundColor: 'black',
    height: 45,
    borderRadius: 100,
  },
  desktopButton: {
    width: 223,
    height: 45,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 40,
    fontSize: 40,
    backgroundColor: 'black',
    borderRadius: 100,
  },
  desktopInput: {
    width: 400,
    height: 40,
    marginVertical: 10,
    borderWidth: 1,
    padding: 10,
  },
  desktopContainer: {
    display: 'flex',
    backgroundColor: '#FBF8F1',
    justifyContent: 'center',
    flex: 1,
  },
  forgotLabel: { textAlign: 'right', fontWeight: 'bold', marginTop: 10 },
  logo: {
    width: 130,
    aspectRatio: 5,
    resizeMode: 'contain',
    display: 'flex',
    alignItems: 'flex-start',
  },
  label: {
    fontSize: 10,
    color: '#808080d9',
    fontWeight: '600',
    marginVertical: 5,
  },
  error: {
    marginTop: 15,
  },
  headerBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
  },
  imawareLogo: {
    width: 125,
    height: 21,
  },
  signUpLabel: {
    textDecorationLine: 'underline',
    marginLeft: 5,
    fontWeight: 'bold',
  },
  box: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  registerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Lato',
  },
  btnLogin: { display: 'flex', justifyContent: 'center' },
  mobileContainer: {
    height: '100%',
    padding: 16,
    display: 'flex',
    flex: 1,
    backgroundColor: '#FBF8F1',
  },
  textLabel: {
    color: 'white',
  },
});
