import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const CheckboxKitCode = ({
  checked,
  onChange,
}: {
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: boolean) => void;
}): JSX.Element => {
  const onCheckmarkPress = () => onChange(!checked);
  return (
    <Pressable
      style={[styles.checkboxBase]}
      onPress={onCheckmarkPress}
      testID="checkboxKitCode"
    >
      {checked && <Ionicons name="checkmark" size={22} color="green" />}
    </Pressable>
  );
};

export default CheckboxKitCode;

const styles = StyleSheet.create({
  checkboxBase: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2.66,
    borderWidth: 0.66,
    borderColor: '#000000',
    backgroundColor: 'transparent',
  },
});
