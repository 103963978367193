/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

/* Note: We may not need to check conditionally whether the Platform is web since the
linking configuration is for desktop onplay, but leaving this as is until launch to be safe */
export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      KitActivation: {
        screens: {
          Drawer: {
            path: '',
            screens: {
              Dashboard: {
                path: '',
                screens: {
                  PatientDashboardScreen: 'dashboard',
                  EnterKitIdScreen: 'enter-kitid',
                  ScanQRCodeScreen: 'scan-code',
                  ConfirmTestingRequirementsScreen: 'patient-confirmation',
                  SuccessKitActivation: 'success-kit-code',
                  ForgotPasswordScreen: 'forgot-password',
                  SuccessResetPasswordScreen: 'success-reset-password',
                  Report: {
                    screens: {
                      RecommendationsScreen: 'recommendations',
                      OverviewScreen: 'overview',
                      ResultsScreen: 'results',
                      DetailsScreen: 'details',
                      ResourcesScreen: 'resources',
                    },
                  },
                  AccountDetailScreen: 'account-details',
                },
              },
            },
          },
        },
      },
      Registration: {
        screens: {
          EmailRegistrationScreen: 'registration',
          LoginScreen: 'login',
          CompletePatientProfileScreen: 'complete-profile',
          EmailVerificationScreen: 'email-verification',
          ResetPasswordScreen: 'reset-password',
        },
      },
      Report: {},
      NotFound: 'not-found',
    },
  },
};
