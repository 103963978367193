import { generateToken } from 'auth/auth0';
import * as Linking from 'expo-linking';

export const checkAuthParams = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(searchParams.entries());
  if (params.error) {
    throw new Error(params.error_description);
  }
  const { code, state } = params;
  const codeVerifier = localStorage.getItem('cv');
  if (code && state && codeVerifier) {
    return generateToken(codeVerifier, code, Linking.makeUrl('/'));
  }
  return undefined;
};
