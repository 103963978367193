export interface SendEmail {
  event: string;
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
  partner: string | undefined;
}

export const ACCOUNT_EXISTS = 'AccountAlreadyExists';
export const FAVOR_ACCOUNT_EXISTS = 'FavorAccountAlreadyExists';
export const BRC_ACCOUNT_EXISTS = 'BRCAccountAlreadyExists';
