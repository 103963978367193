 
import StyledButton from 'components/StyledButton';
import styled from 'styled-components/native';
import { PartnerConfig } from 'types';

interface ElementProps {
  active?: boolean;
  partnerConfig?: PartnerConfig;
}

export const SideNav = styled.View`
  min-width: 240px;
  height: 100%;
  margin-top: 26px;
`;

export const SideNavElement = styled.TouchableOpacity<ElementProps>`
  flex-direction: row;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: ${({ active }) => (active ? '0' : '29px')};
  font-family: Lato;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  background-color: ${({ active, partnerConfig }) =>
    active ? partnerConfig?.theme.secondaryColor : 'white'};
`;

export const LogoutButton = styled(StyledButton)`
  background: white !important;
  color: black !important;
  width: 192px;
  justify-content: center;
  margin-left: 24px;
  border-radius: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #000000;
  font-family: Lato;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 76px;
`;
