import { calculateReportCode as calculateReportCode_v1, calculateBiomarkerCode as calculateBiomarkerCode_v1 } from '../bridge/imaware_v1';
import { Biomarker, BiomarkerCode, CmsColorCode, Coding, ConclusionCode, Observation, Result, Sex } from '../types';
import { calculateReportCode_v2 } from './content';

export const formatObservations = (observations: Observation[]): Result[] =>
  observations
    .filter((observation) => {
      if (observation.valueQuantity?.value !== undefined) {
        const { valueQuantity: { value: observationValue } = {} } = observation;
        if (observationValue === undefined) {
          return false;
        }
        return true;
      }
      if (observation.valueString) {
        // eslint-disable-next-line no-param-reassign
        delete observation.valueQuantity;
        return true;
      }
      return null;
    })
    .map((observation: Observation) => {
      const {
        code: { coding = [] },
        valueQuantity: { value: observationValue, comparator } = {},
        valueString = '',
        status,
        name,
        interpretation,
      } = observation;
      const key = coding?.find((c) => c?.system?.includes('imaware.health'))?.code ?? '';
      let outOfReportableRange = false;
      if (observationValue !== undefined) {
        outOfReportableRange = !!comparator;
      }
      return {
        key,
        ...(observationValue !== undefined && { quantitativeResult: observationValue }),
        ...(valueString && { qualitativeResult: valueString }),
        outOfReportableRange,
        comparator,
        status,
        name,
        interpretation,
      };
    });

export const calculateReportCode = (
  results: Result[],
  biomarkers: Biomarker[],
  sex: Sex,
  reportVersion = 'imaware_v1',
  conclusionCode: Coding[] | ConclusionCode[] | undefined = undefined
): CmsColorCode => {
  console.info(`calculateReportCode called [reportVersion: ${reportVersion}]`);
  switch (reportVersion) {
    case 'imaware_v1':
      return calculateReportCode_v1(results, biomarkers, sex);
    case 'imaware_v2':
      return calculateReportCode_v2(conclusionCode as ConclusionCode[]);
    default:
      throw new Error('invalid report version');
  }
};

export const calculateBiomarkerCode = (results: Result[], biomarkers: Biomarker[], sex: Sex, reportVersion = 'imaware_v1'): BiomarkerCode[] => {
  switch (reportVersion) {
    case 'imaware_v1':
      return calculateBiomarkerCode_v1(results, biomarkers, sex);
    default:
      throw new Error('invalid report version');
  }
};

export const isQualitativeTest = (observations: Observation[]) =>
  (observations || []).every(({ valueString, valueQuantity }) => (valueString && valueString.length > 0) || !valueQuantity);

export const filterQuantitativeResults = (results: Result[]) =>
  (results || []).filter(({ quantitativeResult, qualitativeResult }) => quantitativeResult !== undefined && qualitativeResult === undefined);
