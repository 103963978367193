import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Styles } from 'types';

type FieldError = {
  message?: string;
};

const Checkbox = ({
  checked,
  onChange,
  error,
  testID,
  accessibilityLabel,
  style,
}: {
  checked: string | boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: boolean) => void;
  error?: FieldError;
  testID?: string;
  accessibilityLabel?: string;
  style?: Styles;
}): JSX.Element => {
  function onCheckmarkPress() {
    onChange(!checked);
  }

  return (
    <Pressable
      style={[styles.checkboxBase, style, error && styles.checkboxError]}
      onPress={onCheckmarkPress}
      testID={testID}
      accessible={true}
      accessibilityLabel={accessibilityLabel}
    >
      {checked && (
        <Ionicons name="checkmark" size={style?.height || 35} color="black" />
      )}
    </Pressable>
  );
};

export default Checkbox;

const styles = StyleSheet.create({
  checkboxBase: {
    width: 35,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#000000',
    backgroundColor: 'transparent',
  },
  checkboxError: {
    borderColor: 'red',
  },
});
