import { Platform } from 'react-native';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logoutUser } from 'auth/auth0';
import { RetryLink } from '@apollo/client/link/retry';
import { persistCache, LocalStorageWrapper, AsyncStorageWrapper } from 'apollo3-cache-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import fetch from 'cross-fetch';
import Cookie from 'js-cookie';

import env from 'constants/Config';

const GRAPHQL_ENDPOINT = (): string => {
  if (env.PROXY === 'true') {
    return env.PROXY_GRAPHQL_ENDPOINT;
  }
  return env.LOCAL_GRAPHQL_ENDPOINT;
};

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT(),
  fetch: async (input, init) => {
    console.info(JSON.stringify(init));
    const res = await fetch(input, init);
    console.info(JSON.stringify(res));
    return res;
  },
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, extensions: ${extensions.code}`,
        JSON.stringify(message, null, 2),
        JSON.stringify(locations, null, 2),
        JSON.stringify(path, null, 2)
      );
      if (extensions?.code === 'UNAUTHENTICATED') {
        logoutUser();
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async (req, { headers }) => {
  let token = '';
  if (Platform.OS === 'web') {
    token = Cookie.get('accessToken') ?? '';
  } else {
    token = (await SecureStore.getItemAsync('accessToken')) ?? '';
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        partnerConfig: {
          read(value) {
            return value;
          },
        },
        kitId: {
          read(value) {
            return value;
          },
        },
      },
    },
  },
});

const persist = async () => {
  await persistCache({
    cache,
    storage: Platform.OS === 'web' ? new LocalStorageWrapper(window.localStorage) : new AsyncStorageWrapper(AsyncStorage),
  });
};

persist();

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, retryLink, httpLink]),
  cache,
});

export const SET_KIT_ID = gql`
  query SetKitId {
    kitId @client
  }
`;

export const GET_KIT_ID = gql`
  query GetKitId {
    kitId @client
  }
`;

export const SET_PARTNER_CONFIGS = gql`
  query SetPartnerConfigs {
    partnerConfig {
      name @client
      navigationItems @client
      theme @client
      key @client
      messages @client
      contactEmail @client
      website @client
      collectionInstructionsSite @client
    }
  }
`;

export const GET_PARTNER_CONFIG = gql`
  query GetPartnerConfigs {
    partnerConfig {
      name @client
      navigationItems @client
      theme @client
      key @client
      messages @client
      contactEmail @client
      website @client
      collectionInstructionsSite @client
    }
  }
`;

export const SET_COLOR_CODES = gql`
  query SetColorCodes {
    colorCodes {
      color @client
      order @client
      summaryBackgroundColor @client
      developerConfig @client
      imaIntColorCode @client
    }
  }
`;

export const GET_COLOR_CODES = gql`
  query GetColorCodes {
    colorCodes {
      color @client
      order @client
      summaryBackgroundColor @client
      developerConfig @client
      imaIntColorCode @client
    }
  }
`;

export const SET_RECOMMENDATION_HEADER_COLORS = gql`
  query SetRecommendationHeaderColors {
    headerColors {
      color @client
      valueOrKey @client
      defaultHeaderColor @client
    }
  }
`;

export const GET_RECOMMENDATION_HEADER_COLORS = gql`
  query setRecommendationHeaderColors {
    headerColors {
      color @client
      valueOrKey @client
      defaultHeaderColor @client
    }
  }
`;

export const SET_REJECTION_REASONS = gql`
  query SetRejectionReasons {
    rejectionReasons {
      labReason @client
      customReasonCopy @client
    }
  }
`;

export const GET_REJECTION_REASONS = gql`
  query GetRejectionReasons {
    rejectionReasons {
      labReason @client
      customReasonCopy @client
    }
  }
`;

export default client;
