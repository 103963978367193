import * as React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image, ScrollView, ImageSourcePropType, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import * as WebBrowser from 'expo-web-browser';
import uuid from 'react-native-uuid';

import { ReportLocalization } from 'sample-collection-instructions';
import { Localization, PatientRecommendation, BiomarkerCode } from 'types';

import parseMarkdown from 'utils/markdown';
import { Layout } from './StyledView';
import Text from './StyledText';
import Button from './StyledButton';
import { isValidHttpUrl } from '../utils/helper';
import { getRecommendationHeaderColors } from '../utils/content';

export const Sections = ({ recommendations }: { recommendations: PatientRecommendation[] }): JSX.Element => {
  const [numberToShow, setNumberToShow] = React.useState(3);
  const handleClick = () => {
    setNumberToShow(numberToShow + 3);
  };
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <ReportLocalization.Consumer>
      {(localization) => (
        <SectionContainer isMobile={isMobile}>
          {isMobile && <Layout paddingLeft={'large'} />}
          {recommendations.slice(0, numberToShow).map((recommendation) => (
            <Section isMobile={isMobile} key={`${recommendation.rec.link}-${uuid.v4()}`} isDefault={recommendation.biomarkers[0].key === 'default'}>
              <SectionColour biomarkers={recommendation.biomarkers} />
              <SectionTitle>{recommendation.rec.title}</SectionTitle>
              <SectionDescription isDefault={recommendation.biomarkers[0].key === 'default'}>
                {parseMarkdown(undefined, recommendation.rec.description, 14) as string}
              </SectionDescription>
              {recommendation.rec.link !== '' ? <SectionButton learnMore={recommendation.rec.link}>{localization?.learnMore ?? 'Learn more'}</SectionButton> : null}
            </Section>
          ))}
          {numberToShow >= recommendations.length ? null : <ShowMore handleClick={handleClick} localization={localization} />}

          {isMobile && <Layout paddingRight={'large'} />}
        </SectionContainer>
      )}
    </ReportLocalization.Consumer>
  );
};
export const SectionContainer = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }): JSX.Element => {
  if (isMobile) {
    return <Layout style={styles.mobileContainer}>{children}</Layout>;
  }
  return (
    <ScrollView horizontal={true} style={styles.desktopContainer} contentContainerStyle={styles.scrollViewContentContainer}>
      {children}
    </ScrollView>
  );
};
export const SectionIcon = ({ source }: { source: ImageSourcePropType }): JSX.Element => <Image style={styles.sectionIcon} source={source} />;

export const SectionColour = ({ biomarkers }: { biomarkers: BiomarkerCode[] }): JSX.Element => {
  let colour;
  if (biomarkers[0].value) {
    colour = getRecommendationHeaderColor(biomarkers[0], biomarkers[0].value);
  } else if (biomarkers.length === 1) {
    colour = getRecommendationHeaderColor(biomarkers[0], biomarkers[0].key);
  } else {
    colour = '#C3D2DF';
  }

  return (
    <Layout style={styles.layoutSectionColour}>
      <Layout
        style={[
          styles.sectionColour,
          {
            backgroundColor: colour,
          },
        ]}
      />
    </Layout>
  );
};

export const SectionTitle = ({ children }: { children: string }): JSX.Element => (
  <Layout marginHorizontal={'regular'} marginVertical={'regular'} style={styles.sectionTitle}>
    <Text style={styles.sectionText} fontWeight={'500'} regular>
      {children}
    </Text>
  </Layout>
);
export const SectionDescription = ({ children, isDefault }: { children: string; isDefault: boolean }): JSX.Element => (
  <Layout paddingBottom={isDefault ? 'regular' : null} style={styles.sectionDescription}>
    <Text style={styles.sectionLabel} label>
      {children}
    </Text>
  </Layout>
);

export const SectionButton = ({ children, learnMore }: { children: string; learnMore: string }): JSX.Element => {
  const navigation = useNavigation();
  return (
    <Layout marginTop={'small'}>
      <Button
        onPress={() => {
          if (!isValidHttpUrl(learnMore) && ['/resources'].includes(learnMore)) {
            navigation.navigate('ResourcesScreen');
          } else if (typeof window !== 'undefined') {
            window.open(learnMore, '_blank');
          } else {
            WebBrowser.openBrowserAsync(learnMore);
          }
        }}
        small
        bold
        arrowRight
        style={styles.sectionBtn}
      >
        {children}
      </Button>
    </Layout>
  );
};

export const Section = ({ children, isMobile, isDefault }: { children: React.ReactNode; isMobile: boolean; isDefault: boolean }): JSX.Element => (
  <Layout
    marginBottom={isMobile && 'medium'}
    style={{
      // eslint-disable-next-line no-nested-ternary
      width: isMobile ? '100%' : isDefault ? '60%' : '33.33%',
      minWidth: 290,
    }}
    paddingHorizontal={'small'}
  >
    <Layout marginVertical={'small'} paddingBottom={'small'} style={styles.sectionLayout}>
      {children}
    </Layout>
  </Layout>
);
export const MessageBubble = ({ children }: { children: string | React.ReactNode }): JSX.Element => (
  <Layout marginTop={'regular'} style={styles.messageBubble}>
    <Text style={styles.messageBubbleText} regular>
      {children}
    </Text>
  </Layout>
);

export const ShowMore = ({ handleClick, localization }: { handleClick: () => void; localization: Localization | null }): JSX.Element => (
  <Layout>
    <Button small onPress={handleClick} style={styles.showMoreBtn}>
      {localization?.showMore ?? 'Show more'}
    </Button>
  </Layout>
);

 
const getRecommendationHeaderColor = (biomarkerCode: BiomarkerCode, valueOrKey: string): string =>
  biomarkerCode.biomarkerDevConfig?.recommendationHeaderColor || getCmsRecommendationHeaderColor(valueOrKey);

const styles = StyleSheet.create({
  mobileContainer: {
    width: '100%',
    maxWidth: 330,
    alignSelf: 'center',
  },
  desktopContainer: {
    flexDirection: 'row',
    maxWidth: 980,
    alignSelf: 'center',
    marginBottom: 60,
  },
  sectionColour: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 25,
    width: '100%',
  },
  sectionTitle: { paddingHorizontal: 15, paddingVertical: 10 },
  sectionText: { textAlign: 'center' },
  sectionDescription: { paddingHorizontal: 15 },
  sectionLabel: { lineHeight: 32, textAlign: 'center', color: '#666666' },
  sectionBtn: { borderWidth: 0 },
  sectionLayout: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    alignItems: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    flexGrow: 1,
  },
  messageBubble: {
    backgroundColor: '#F6F9FD',
    borderRadius: 10,
    maxWidth: 660,
    alignSelf: 'center',
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  showMoreBtn: {
    paddingHorizontal: 60,
    marginHorizontal: 'auto',
    marginTop: 30,
  },
  scrollViewContentContainer: {
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: 'max-content',
  },
  sectionIcon: {
    width: 64,
    height: 64,
    backgroundColor: '#f7f9fb',
    borderRadius: 64,
  },
  messageBubbleText: {
    textAlign: 'center',
  },
  layoutSectionColour: {
    width: '100%',
  },
});

const getCmsRecommendationHeaderColor = (valueOrKey: string): string => {
  const headerColors = getRecommendationHeaderColors();
  const recommendationHeaderColor = headerColors.find(({ valueOrKey: cmsValueOrKey }) => cmsValueOrKey === valueOrKey);
  if (!recommendationHeaderColor?.color) {
    return headerColors.find(({ defaultHeaderColor }) => defaultHeaderColor === true)!.color;
  }
  return recommendationHeaderColor.color;
};
