import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import axios from 'axios';
import * as AuthSession from 'expo-auth-session';
import * as SecureStore from 'expo-secure-store';
import * as Crypto from 'expo-crypto';
import * as Random from 'expo-random';
import Cookie from 'js-cookie';
import Constants from 'expo-constants';
import { Buffer } from 'buffer';
import * as Sentry from 'sentry-expo';
import env from 'constants/Config';

import {
  AuthResult,
  AuthTokenRequestBody,
  AuthTokenRequestResponse,
} from 'types';

interface StringMap {
  [key: string]: string;
}

const generateShortUUID = () => Math.random().toString(36).substring(2, 15);

const toQueryString = (params: StringMap) =>
  `?${Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')}`;

const URLEncode = (str: string) =>
  str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

const sha256 = (buffer: string) =>
  Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, buffer, {
    encoding: Crypto.CryptoEncoding.BASE64,
  });

export const authenticateUser = async (): Promise<string | void> => {
  const isWeb = Platform.OS === 'web';
  const state = generateShortUUID();
  const randomBytes = await Random.getRandomBytesAsync(32);
  const base64String = Buffer.from(randomBytes).toString('base64');
  const code_verifier = URLEncode(base64String);
  localStorage.setItem('cv', code_verifier);
  const code_challenge = URLEncode(await sha256(code_verifier));
  // Determine if the app is running in dev(not null) or standalone(null) and should use a proxy https://docs.expo.dev/versions/latest/sdk/auth-session/
  const useProxy = Constants.appOwnership === null;
  const scheme = 'redirect';
  const redirectUrl = isWeb
    ? Linking.makeUrl('/login')
    : AuthSession.makeRedirectUri({ useProxy, scheme });
  const authenticationOptions = {
    response_type: 'code',
    code_challenge,
    code_challenge_method: 'S256',
    client_id: env.AUTH0_CLIENT_ID ?? '',
    redirect_uri: redirectUrl,
    scope: 'openid profile email offline_access',
    audience: env.AUTH0_AUDIENCE ?? '',
    state,
  };
  const authUrl = `${env.AUTH0_DOMAIN}/authorize${toQueryString(
    authenticationOptions
  )}`;
  let result: AuthResult | undefined;

  if (isWeb) {
    await Linking.openURL(authUrl);
  } else {
    result = await AuthSession.startAsync({ authUrl });
  }

  // If Web Platform...
  if (result === undefined) {
    return;
  }

  // For the new login process (w/o popup), this should execute only for Mobile Platform, since
  // I'm not sure of the side effects of the new stuff on Mobile
  if (
    result.type === 'success' &&
    ((result.params && result.params.code && result.params.state === state) ||
      result?.url?.includes('code'))
  ) {
    let code = '';
    if (result?.url?.includes('code')) {
      code = result.url.split('code=')[1].split('&state')[0];
    } else {
      code = result?.params?.code ?? '';
    }
    // eslint-disable-next-line consistent-return
    return generateToken(code_verifier, code, redirectUrl);
  } else if (
    (result.type === 'success' || result.type === 'error') &&
    result.params
  ) {
    throw Error(result.params.error_description);
  } else {
    const params = result.url?.match(/((\?.*)?)?$/)?.toString();
    const urlParams = new URLSearchParams(params);
    let error_description = urlParams.get('error_description');
    if (error_description?.includes('window.open()')) {
      error_description =
        'Pop-up windows are currently blocked on this browser. Please enable pop-ups, then refresh the page to log in.';
    }
    throw Error(error_description || undefined);
  }
};

export const generateToken = async (
  code_verifier: string,
  code: string,
  redirectUrl: string
): Promise<string> =>
  axios
    .request<AuthTokenRequestBody, AuthTokenRequestResponse>({
      method: 'POST',
      url: `${env.AUTH0_DOMAIN}/oauth/token`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        grant_type: 'authorization_code',
        client_id: env.AUTH0_CLIENT_ID,
        code_verifier,
        code,
        redirect_uri: redirectUrl,
      },
    })
    .then(async (response) => {
      const { access_token, id_token, refresh_token } = response.data;
      const today = new Date();
      const expirationDate = today.setDate(today.getDate() + 1).toString();
      if (Platform.OS === 'web') {
        Cookie.set('accessToken', access_token);
        Cookie.set('idToken', id_token);
        Cookie.set('refreshToken', refresh_token);
        Cookie.set('expirationDate', expirationDate);
      } else {
        await SecureStore.setItemAsync('accessToken', access_token);
        await SecureStore.setItemAsync('idToken', id_token);
        await SecureStore.setItemAsync('refreshToken', refresh_token);
        await SecureStore.setItemAsync('expirationDate', expirationDate);
      }
      return access_token;
    })
    .catch((err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GenerateToken',
          message: `Auth0 token generation failed`,
        },
      });
      throw err;
    });

export const logoutUser = async (): Promise<void> => {
  const redirectUrl = Linking.makeUrl('/login');
  const logoutUrl = `${env.AUTH0_DOMAIN}/v2/logout?client_id=${env.AUTH0_CLIENT_ID}&returnTo=${redirectUrl}`;

  if (Platform.OS === 'web') {
    Cookie.remove('accessToken');
    Cookie.remove('idToken');
    Cookie.remove('refreshToken');
    Cookie.remove('expirationDate');
  } else {
    await SecureStore.deleteItemAsync('accessToken');
    await SecureStore.deleteItemAsync('idToken');
    await SecureStore.deleteItemAsync('refreshToken');
    await SecureStore.deleteItemAsync('expirationDate');
  }
  localStorage.removeItem('cv');

  await Linking.openURL(logoutUrl);
};

export const isAuthenticated = async (): Promise<boolean> => {
  let accessToken;
  if (Platform.OS === 'web') {
    accessToken = Cookie.get('accessToken');
  } else {
    accessToken = await SecureStore.getItemAsync('accessToken');
  }
  if (!accessToken) {
    return false;
  }
  let expirationDate = '';
  let refreshToken = '';
  if (Platform.OS === 'web') {
    expirationDate = Cookie.get('expirationDate') ?? '';
    refreshToken = Cookie.get('refreshToken') ?? '';
  } else {
    expirationDate = (await SecureStore.getItemAsync('expirationDate')) ?? '';
    refreshToken = (await SecureStore.getItemAsync('refreshToken')) ?? '';
  }
  if (
    Platform.OS !== 'web' &&
    (!refreshToken || refreshToken === 'undefined')
  ) {
    return false;
  }
  if (new Date(parseInt(expirationDate)) < new Date()) {
    return false;
  }

  return true;
};
