import React from 'react';
import { StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import { PartnerConfig } from 'types';

import ActivateKitContainer, {
  ActivateBox,
  Title,
  SubTitle,
  ActivateButton,
  ActivateBoxButton,
} from './styles';

const ActivateKitLink = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  return (
    <ActivateKitContainer isMobile={isMobile} partnerConfig={partnerConfig}>
      <ActivateBox isMobile={isMobile}>
        <Title fontWeight="500" lora isMobile={isMobile}>
          Have a new test?
        </Title>
        <SubTitle lora>Get started by activating your kit.</SubTitle>
      </ActivateBox>
      <ActivateBoxButton isMobile={isMobile}>
        <ActivateButton
          isMobile={isMobile}
          onPress={() => navigation.navigate('EnterKitIdScreen')}
          textStyle={styles.button}
        >
          Activate Kit
        </ActivateButton>
      </ActivateBoxButton>
    </ActivateKitContainer>
  );
};

const styles = StyleSheet.create({
  button: {
    color: '#000',
    fontWeight: '500',
    fontFamily: 'roboto_500',
  },
});

export default ActivateKitLink;
