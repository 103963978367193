import * as React from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { Doctor } from 'types';

import Text from './StyledText';
import { Layout } from './StyledView';

export const MessageContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => <View style={styles.messageContainer}>{children}</View>;
export const MessageBubble = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => <Layout style={styles.messageBubble}>{children}</Layout>;
export const MessageTitle = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <Text lora fontWeight={'500'} xl style={styles.title}>
    {children}
  </Text>
);

export const Avatars = ({ doctors }: { doctors: Doctor[] }) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Layout
      style={{
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
      }}
    >
      {doctors.map(({ name, positionTitle, avatar: { url: uri } }) => (
        <Layout
          marginBottom={isMobile && 'regular'}
          marginRight={'small'}
          style={styles.doctorContainer}
          key={uri}
        >
          <Image style={styles.avatarMobile} source={{ uri }} />
          <Layout style={styles.doctorTextContainer} paddingLeft={'small'}>
            <Text style={styles.doctorName}>{name}</Text>
            <Text tiny>{positionTitle}</Text>
          </Layout>
        </Layout>
      ))}
    </Layout>
  );
};
// export const AvatarMobile = ({ source }) => {
//     const isMobile = useMediaQuery({
//         maxWidth: 600
//     });
//     if (!isMobile) return null;
//     return <Image style={styles.avatarMobile} source={source} />
// }
const styles = StyleSheet.create({
  title: {
    lineHeight: 25,
    marginTop: 16,
    marginBottom: 16,
  },
  messageContainer: {
    width: '100%',
    maxWidth: 915,
    alignSelf: 'center',
    flex: 1,
  },
  messageBubble: {
    backgroundColor: '#F6F9FD',
    borderRadius: 4,
    alignSelf: 'flex-end', // todo - needs max width removed for mobile? or something.
    padding: 30,
  },
  avatarDesktop: {
    width: 100,
    height: 100,
    borderRadius: 100,
    marginTop: -24,
    alignSelf: 'flex-start',
    backgroundColor: '#f7f9fb',
  },
  avatarMobile: {
    width: 42,
    height: 42,
    borderRadius: 42,
  },
  doctorContainer: {
    flexDirection: 'row',
  },
  doctorTextContainer: {
    maxWidth: 230,
    marginLeft: 15,
  },
  doctorName: {
    marginBottom: 5,
  },
});
