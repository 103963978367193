import { View } from 'components/Themed';
import styled from 'styled-components/native';
import Button from 'components/StyledButton';
import Text from 'components/StyledText';

import { PartnerConfig } from 'types';

export default styled.View<{ isMobile: boolean; partnerConfig?: PartnerConfig }>`
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: ${(row) => (row.isMobile ? '28px' : 0)};
  margin-right: ${(row) => (row.isMobile ? '28px' : 0)};
  margin-bottom: 38px;
  border-radius: 16px;
  padding-left: ${({ isMobile }) => (isMobile ? '17px' : '28px')};
  padding-right: ${({ isMobile }) => (isMobile ? '17px' : '28px')};
  padding-top: ${(row) => (row.isMobile ? '0px' : '30px')};
  padding-bottom: ${(row) => (row.isMobile ? '0px' : '28px')};
  height: auto;
  display: flex;
  flex-direction: ${(row) => (!row.isMobile ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  background: ${({ partnerConfig }) => partnerConfig?.theme ? partnerConfig.theme.primaryColor : '#116fa4'};
`;

export const ActivateButton = styled(Button)<{ isMobile: boolean }>`
  width: ${(row) => (row.isMobile ? '100%' : '200px')};
  display: flex;
  justify-content: center;
  padding: 10px 0;
  text-align: center;
  border-width: 0px;
`;

export const ActivateBoxButton = styled(View)<{ isMobile: boolean }>`
  display: flex;
  width: ${(row) => (row.isMobile ? '100%' : '200px')};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  margin-top: ${(row) => (row.isMobile ? '10px' : 0)};
  margin-bottom: ${(row) => (row.isMobile ? '18px' : 0)};
`;

export const ActivateBox = styled(View)<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-evenly;
  height: ${(row) => (!row.isMobile ? '100%' : '70px')};
  align-items: flex-start;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  background: transparent;
`;

export const Title = styled(Text)<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '24px' : '28px')};
  margin-top: 5px;
  color: #ffffff;
  font-weight: 500;
`;

export const SubTitle = styled(Text)`
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
`;
