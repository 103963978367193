import * as React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { ButtonProps } from 'types';
import { formatPascalCase } from 'utils/formatPascalCase';
import Text from './StyledText';

// TODO: Determine whether specific style and textStyle types can be defined
export default (props: ButtonProps): JSX.Element => {
  const {
    primary,
    secondary,
    bold,
    tinyFeather,
    mediumFeather,
    tiny,
    medium,
    small,
    children,
    arrowLeft,
    arrowRight,
    style,
    textStyle,
    ignoreWidth,
    onPress,
    disabled,
  } = props;
  const ref = React.useRef(null);
  let arrowSize;
  if (tinyFeather) {
    arrowSize = 8;
  } else if (mediumFeather) {
    arrowSize = 12;
  } else {
    arrowSize = 16;
  }
  const arrowColor = primary ? 'white' : 'black';
  const buttonContainerStyles = ignoreWidth ? null : { width: '100%' };

  return (
    <View ref={ref} style={buttonContainerStyles}>
      <TouchableOpacity
        onPress={onPress}
        style={[
          styles.button,
          medium && styles.mediumButton,
          small && styles.smallButton,
          tiny && styles.tinyButton,
          primary && styles.buttonPrimary,
          secondary && styles.buttonSecondary,
          style,
        ]}
        testID={`${formatPascalCase(children)}Btn`}
        disabled={disabled}
      >
        {arrowLeft && (
          <Feather name="arrow-left" size={arrowSize} color={arrowColor} />
        )}
        <Text
          style={[
            primary && styles.buttonTextPrimary,
            secondary && styles.buttonTextSecondary,
            arrowLeft && styles.buttonTextLeftMargin,
            arrowRight && styles.buttonTextRightMargin,
            textStyle,
            styles.buttonText,
          ]}
          fontWeight={bold ? '700' : '400'}
          button={!tiny && !medium}
          tiny={tiny}
          label={medium}
          units={small}
        >
          {children}
        </Text>
        {arrowRight && (
          <Feather name="arrow-right" size={arrowSize} color={arrowColor} />
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  hoverContainer: {
    paddingBottom: 3,
    marginBottom: -3,
  },
  button: {
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderRadius: 50,
    paddingHorizontal: 30,
    paddingVertical: 15,
    alignItems: 'center',
  },
  mediumButton: {
    paddingHorizontal: 25,
    paddingVertical: 7.5,
  },
  smallButton: {
    paddingHorizontal: 22.5,
    paddingVertical: 6.25,
  },
  tinyButton: {
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  buttonPrimary: {
    backgroundColor: '#000',
  },
  buttonSecondary: {
    backgroundColor: '#fff',
  },
  buttonHover: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    elevation: 5,
    transform: [
      {
        translateY: -2,
      },
    ],
  },
  buttonTextPrimary: {
    color: '#fff',
  },
  buttonTextSecondary: {
    color: '#000',
  },
  buttonTextLeftMargin: {
    marginLeft: 10,
  },
  buttonTextRightMargin: {
    marginRight: 10,
  },
  buttonText: {
    textAlign: 'center',
  },
});
