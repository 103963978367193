import ModalComponent from 'components/Modal';
import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { ModalMissingBiomarkersTypes, PartnerConfig } from 'types';
import Text from 'components/StyledText';
import client, { GET_PARTNER_CONFIG } from '../../../kit-activation';

const getMissingBiomarkersText = () => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  if (partnerConfig?.key === 'imaware') {
    return (
      <Text lato style={styles.text}>
        Additional questions? Visit our{' '}
        <TouchableOpacity onPress={() => Linking.openURL('https://support.imaware.health/hc/en-us')}>
          <Text style={styles.helpCenter}>help center</Text>
        </TouchableOpacity>{' '}
        or email support@imaware.health and an imaware team member will reply within one business day.
      </Text>
    );
  }
  return (
    <Text lato style={styles.text}>
      If you have additional questions, please reach out to our support team.
    </Text>
  );
};

const ModalMissingBiomarkers = ({ unresultedBiomarkers, onHidden }: ModalMissingBiomarkersTypes) => (
  <ModalComponent hideTitle={true} loading={false} actions={[]} onHidden={onHidden}>
    <View>
      <Text lato style={styles.text}>
          The biomarkers that were not able to be tested are listed below.
      </Text>
      <View style={styles.biomarkersContainer}>
        {unresultedBiomarkers.length > 0 &&
            unresultedBiomarkers.map((data, index) => (
              <Text style={styles.biomarkerText} key={index}>
                {data}
              </Text>
            ))}
      </View>
      <Text lato style={styles.text}>
          This is either due to insufficient volume of the sample or the sample not passing quality inspection at the lab.
      </Text>
      {getMissingBiomarkersText()}
    </View>
  </ModalComponent>
);

export default ModalMissingBiomarkers;

const styles = StyleSheet.create({
  text: {
    lineHeight: 19.2,
    paddingVertical: 10,
    fontSize: 16,
  },
  biomarkerText: {
    fontSize: 16,
    lineHeight: 19.2,
    fontWeight: '600',
    paddingVertical: 5,
  },
  biomarkersContainer: {
    minHeight: 30,
  },
  helpCenter: {
    textDecorationLine: 'underline',
  },
});
