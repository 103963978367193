import * as React from 'react';
import {
  ScrollView,
  Image,
  TouchableOpacity,
  LayoutRectangle,
} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { useMediaQuery } from 'react-responsive';

import parseMarkdown from 'utils/markdown';
import { Layout as LayoutProps, Resource, ThumbnailStyle } from 'types';

import { Layout } from './StyledView';
import Text from './StyledText';

export const Sections = ({
  resources,
}: {
  resources: Resource[];
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <SectionContainer isMobile={isMobile} count={resources.length}>
      {isMobile && <Layout paddingLeft={'large'} />}
      {resources.map((resource) => (
        <Section isMobile={isMobile} key={resource.link} resource={resource} />
      ))}
      {isMobile && <Layout paddingRight={'large'} />}
    </SectionContainer>
  );
};

export const SectionContainer = ({
  children,
  isMobile,
  count,
}: {
  children: React.ReactNode;
  isMobile: boolean;
  count: number;
}): JSX.Element => {
  if (isMobile) {
    return (
      <Layout
        style={{
          width: '100%',
          maxWidth: 420,
          alignSelf: 'center',
        }}
      >
        {children}
      </Layout>
    );
  }
  const marginLeft = count > 2 ? '0%' : `${100/3 / count}%`;
  return (
    <ScrollView
      style={{
        marginLeft,
        flexDirection: 'row',
        width: '100%',
        maxWidth: 1400,
        alignSelf: 'center',
        height: 'fit-content',
        maxHeight: 420,
        marginTop: 30,
      }}
      horizontal={true}
      contentContainerStyle={{
        width: '100%',
      }}
    >
      {children}
    </ScrollView>
  );
};
export const SectionThumbnail = ({
  layout,
  source,
}: {
  layout: LayoutProps | LayoutRectangle;
  source: { uri: string };
}): JSX.Element => {
  const aspectRatio = 1 / 2.07;
  const style: ThumbnailStyle[] = [
    {
      width: '100%',
      backgroundColor: '#f7f9fb',
      aspectRatio,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
  ];
  if (layout) {
    const { width = 0, height = 0 } = layout;
    if (width === 0) {
      style.push({ width: height * aspectRatio, height });
    } else {
      style.push({ width, height: width * aspectRatio });
    }
  }
  return <Image resizeMode={'cover'} source={source} style={style}></Image>;
};
export const SectionTitle = ({
  children,
}: {
  children: string;
}): JSX.Element => (
  <Text regular fontWeight={'500'}>
    {children}
  </Text>
);
export const SectionDescription = ({
  children,
}: {
  children: string | React.ReactElement[];
}): JSX.Element => (
  <Layout marginVertical={'small'}>
    <Text small style={{ color: '#4A4A4A' }}>
      {children}
    </Text>
  </Layout>
);
export const SectionAuthor = ({
  children,
}: {
  children: string;
}): JSX.Element => (
  <Text tiny style={{ textTransform: 'uppercase' }}>
    {children}
  </Text>
);
export const Section = ({
  resource,
  isMobile,
}: {
  resource: Resource;
  isMobile: boolean;
}): JSX.Element => {
  const [layout, setLayout] = React.useState<LayoutRectangle | null>(null); // React native web doesn't support aspectRatio style
  const description = parseMarkdown(undefined, resource.preview);
  return (
    <Layout
      marginBottom={isMobile && 'medium'}
      paddingHorizontal={'small'}
      style={{
        width: isMobile ? '100%' : '33.33%',
        minWidth: 290,
        height: 'fit-content',
      }}
    >
      <TouchableOpacity
        onPress={() => {
          if (typeof window !== 'undefined') {
            window.open(resource.link, '_blank');
          } else {
            WebBrowser.openBrowserAsync(resource.link);
          }
        }}
        onLayout={({ nativeEvent: { layout: l } }) => setLayout(l)}
        style={{
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <SectionThumbnail
          layout={layout}
          source={{
            uri: resource.thumbnail.url,
          }}
        />
        <SectionContent>
          <SectionTitle>{resource.title}</SectionTitle>
          <SectionDescription>{description}</SectionDescription>
          <SectionAuthor>{resource.author}</SectionAuthor>
        </SectionContent>
      </TouchableOpacity>
    </Layout>
  );
};
export const SectionContent = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <Layout
    marginBottom={'regular'}
    style={{
      shadowColor: '#000000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.08,
      shadowRadius: 10,
      paddingHorizontal: 20,
      paddingVertical: 25,
    }}
  >
    {children}
  </Layout>
);
