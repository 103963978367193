import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StatusBar } from 'expo-status-bar';
import 'react-native-gesture-handler';
import '@expo/match-media';
import * as Sentry from 'sentry-expo';
import { BrowserTracing } from '@sentry/tracing';

import Navigation from 'navigation';
import kitActivationClient from 'kit-activation';
import env from 'react-native-dotenv';

Sentry.init({
  dsn: 'https://b0905316979b42c7964a16256fb5eec7@o1212911.ingest.sentry.io/6367278',
  enableInExpoDevelopment: true,
  debug: env.SENTRY_DEBUG === 'true',
  environment: env.ENVIRONMENT,
  release: env.RELEASE_TAG,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

export default function App() {
  return (
    <ApolloProvider client={kitActivationClient}>
      <Navigation colorScheme={null} />
      <StatusBar />
    </ApolloProvider>
  );
}

Sentry.Browser.wrap(App);
