import * as React from 'react';
import { View, ScrollView } from 'react-native';
import { BlurView } from 'expo-blur';
import { useMediaQuery } from 'react-responsive';
import {
  LayoutProp,
  LayoutProps,
  LayoutStyles,
  PaddingMarginProp,
} from 'types';

const layoutSizeMap = {
  xl: {
    desktop: 32,
    mobile: 20,
  },
  large: {
    desktop: 28,
    mobile: 24,
  },
  regular: {
    desktop: 24,
    mobile: 18,
  },
  medium: {
    desktop: 16,
    mobile: 13,
  },
  small: {
    desktop: 10,
    mobile: 8,
  },
  tiny: {
    desktop: 5,
    mobile: 4,
  },
};

export const BlurLayout = (props: LayoutProps): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const sizeVariant = isMobile ? 'mobile' : 'desktop';
  const layoutStyles: LayoutStyles = {};
  const paddingMarginProps: PaddingMarginProp[] = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'paddingHorizontal',
    'paddingVertical',
    'padding',
    'marginLeft',
    'marginRight',
    'marginTop',
    'marginBottom',
    'marginHorizontal',
    'marginVertical',
    'margin',
  ];
  paddingMarginProps.forEach((prop: PaddingMarginProp) => {
    const layoutProp = props[prop] as LayoutProp;
    if (layoutProp) {
      layoutStyles[prop] = layoutSizeMap[layoutProp][sizeVariant];
    }
  });
  return (
    <BlurView
      tint={'light'}
      intensity={100}
      {...props}
      style={[props.style, layoutStyles]}
    />
  );
};

export const ScrollLayout = (props: LayoutProps): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const sizeVariant = isMobile ? 'mobile' : 'desktop';
  const layoutStyles: LayoutStyles = {};
  const paddingMarginProps: PaddingMarginProp[] = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'paddingHorizontal',
    'paddingVertical',
    'padding',
    'marginLeft',
    'marginRight',
    'marginTop',
    'marginBottom',
    'marginHorizontal',
    'marginVertical',
    'margin',
  ];
  paddingMarginProps.forEach((prop: PaddingMarginProp) => {
    const layoutProp = props[prop] as LayoutProp;
    if (layoutProp) {
      layoutStyles[prop] = layoutSizeMap[layoutProp][sizeVariant];
    }
  });
  return <ScrollView {...props} style={[props.style, layoutStyles]} />;
};

export const Layout = (props: LayoutProps): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const sizeVariant = isMobile ? 'mobile' : 'desktop';
  const layoutStyles: LayoutStyles = {};
  const paddingMarginProps: PaddingMarginProp[] = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
    'paddingHorizontal',
    'paddingVertical',
    'padding',
    'marginLeft',
    'marginRight',
    'marginTop',
    'marginBottom',
    'marginHorizontal',
    'marginVertical',
    'margin',
  ];
  paddingMarginProps.forEach((prop) => {
    const layoutProp = props[prop] as LayoutProp;
    if (layoutProp) {
      layoutStyles[prop] = layoutSizeMap[layoutProp][sizeVariant];
    }
  });
  const { ...newProps } = props;
  return <View {...newProps} style={[props.style, layoutStyles]} />;
};
