import { Platform } from 'react-native';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { Page } from 'types';
import { ReportPageScreenMapping } from '../constants/reportPageMap';

export const handleReportNavigation = (
  navigation: DrawerNavigationHelpers,
  pages: Page[]
): {
  // eslint-disable-next-line no-unused-vars
  goTo: (screen: string) => void;
  goBack: () => void;
} => ({
  goTo: (screen: string) => navigation.navigate(screen),
  goBack: () => {
    if (Platform.OS !== 'web') {
      navigation.goBack();
      return;
    }
    const state = navigation.getState();
    const currentScreen = state.routes[state.index].name;
    let currentPage = '';
    for (const [key, value] of Object.entries(ReportPageScreenMapping)) {
      if (value === currentScreen) currentPage = key;
    }
    if (!currentPage) return;
    const prevPageIndex = pages.findIndex((page) => page.__typename === currentPage) - 1;
    if (prevPageIndex < 0) return;
    const prevPage = pages[prevPageIndex].__typename;
    const prevScreen = ReportPageScreenMapping[prevPage];
    navigation.navigate(prevScreen);
  },
});
