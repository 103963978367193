import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation, ApolloError, useLazyQuery } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from 'sentry-expo';

import { ReportContainer, Paragraph, ContentHeader } from 'components/ReportCommon';
import { ContentSummaryContainer } from 'components/Common';
import { Sections } from 'components/Recommendations';
import ScreenWrapper from 'components/ScreenWrapper';
import DesktopNavigation from 'components/DesktopNavigation';

import { SPECIFIC_REPORT, cmsClient } from 'sample-collection-instructions';
import reportClient, { GET_KIT_ID, GET_PARTNER_CONFIG } from 'kit-activation';

import { formatObservations, calculateBiomarkerCode } from 'utils/data';
import { getReport, getPage, getRecommendations, getBiomarkerLevel, getReportBiomarkersConfig } from 'utils/content';
import { GetTestResultsData, GetTestResultsVariables, Page, PartnerConfig, PatientRecommendation, SpecificReportResponse } from 'types';
import { RouteProp } from '@react-navigation/native';
import GET_TEST_RESULTS from './queries';

export default ({ navigation, route }: { navigation: DrawerNavigationHelpers; route: RouteProp<{ params?: { kitId?: string } }, 'params'> }): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [recommendations, setRecommendations] = useState<PatientRecommendation[]>([]);
  const [title, setTitle] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [defaultDescription, setDefaultDescription] = useState('');
  const [key, setKey] = useState('');
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] = useState<ApolloError | null>(null);
  const [testResultsData, setTestResultsData] = useState<GetTestResultsData | undefined>();

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = route.params?.kitId || data?.kitId || '';

  const setScreenData = () => {
    const {
      patient: { gender },
      observations,
    } = testResultsData!.getTestResults;

    const testResults = formatObservations(observations);
    const { pages: unfilteredPages, title: reportTitle } = getReport(content, observations);
    const reportBiomarkers = getReportBiomarkersConfig(content);
    const codes = calculateBiomarkerCode(testResults, reportBiomarkers, gender);
    const {
      title: pageTitle,
      description: pageDescription,
      subtitle: pageSubtitle,
      defaultDescription: defaultDesc = '',
      recommendations: allRecommendations = [],
    } = getPage(unfilteredPages, 'ReportRecommendationsPageRecord');
    const biomarkerLevels = getBiomarkerLevel(reportBiomarkers, testResults, gender);
    const calculatedRecommendations = getRecommendations(allRecommendations, codes, biomarkerLevels);

    const { key: biomarkerKey } = calculatedRecommendations[0].biomarkers[0];
    setDefaultDescription(defaultDesc);
    setRecommendations(calculatedRecommendations);
    const filteredPages = unfilteredPages.filter((p) => p.goToButton !== 'View Background');
    setKey(biomarkerKey);
    setPages(filteredPages);
    setTitle(reportTitle);
    setContentTitle(pageTitle);
    setDescription(pageDescription);
    setSubtitle(pageSubtitle);
  };

  const [getSpecificReport, { loading: contentLoading, error: contentError, data: content }] = useLazyQuery<SpecificReportResponse>(
    SPECIFIC_REPORT(testResultsData?.getTestResults.testType),
    {
      client: cmsClient,
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setScreenData();
      },
      onError: (err) => {
        Sentry.Browser.captureException(err, {
          extra: {
            event: 'getSpecificReport',
            message: `Report retrieval on RecommendationsScreen for kit id ${kitId} failed`,
            testName: testResultsData?.getTestResults?.testType,
          },
        });
        console.error('An error occurred during getSpecificReport Query', err);
      },
    }
  );

  const [getTestResults, { loading: resultsLoading, error: resultsError }] = useMutation<GetTestResultsData, GetTestResultsVariables>(GET_TEST_RESULTS, {
    variables: {
      kitId,
    },
    onCompleted: ({ getTestResults: testResultsData }) => {
      setTestResultsData({ getTestResults: testResultsData });
      getSpecificReport();
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetTestResults',
          message: `Test results retrieval on RecommendationsScreen for kit id ${kitId} failed`,
        },
      });
      console.error(`An error occurred during GetTestResults Mutation with kit id: ${kitId}`, err);
    },
  });

  useEffect(() => {
    getTestResults();
  }, [kitId]);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const partnerData = reportClient.cache.readQuery<{
    partnerConfig: PartnerConfig;
  }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = partnerData?.partnerConfig;

  return (
    <ScreenWrapper loading={contentLoading || resultsLoading || reportDownloadLoading} error={contentError || resultsError || reportDownloadError} excludeWrapper excludeFooter>
      <View style={[styles.view, isMobile ? styles.viewMobile : null]}>
        {!isMobile ? <DesktopNavigation navigation={navigation} componentLabel={'PatientDashboardScreen'} partnerConfig={partnerConfig} /> : null}
        <ReportContainer
          pages={pages}
          navigation={navigation}
          title={title}
          screen={'RecommendationsScreen'}
          setLoading={setReportDownloadLoading}
          setError={setReportDownloadError}
        >
          <ContentHeader title={contentTitle} subtitle={subtitle} />
          <ContentSummaryContainer>
            <Paragraph leftAlignOnMobile>{key === 'default' ? defaultDescription : description}</Paragraph>
          </ContentSummaryContainer>
          <Sections recommendations={recommendations} />
        </ReportContainer>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '100%',
  },
  viewMobile: {
    flexDirection: 'column',
    overflow: 'scroll',
  },
});
