/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import '@expo/match-media';
import {
  LongPressGestureHandler,
  PanGestureHandler,
} from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import { ReportPageScreenMapping } from 'constants/reportPageMap';
import { handleReportNavigation } from 'utils/handleReportNavigation';
import { Localization, Page, Screen } from 'types';

import Text from './StyledText';
import Button from './StyledButton';
import { Layout } from './StyledView';

export const Navigation = ({
  children,
  justifyContentFlexStart,
  justifyContentFlexEnd,
}: {
  children: React.ReactNode;
  justifyContentFlexStart?: boolean;
  justifyContentFlexEnd?: boolean;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  if (isMobile) {
    return (
      <Layout
        marginVertical={'regular'}
        marginBottom={'xl'}
        marginHorizontal={'xl'}
        style={[styles.navigationContainer, styles.justifyContentCenter]}
      >
        {children}
      </Layout>
    );
  }
  return (
    <Layout
      paddingTop={'regular'}
      style={[
        styles.navigationContainer,
        justifyContentFlexStart && styles.justifyContentFlexStart,
        justifyContentFlexEnd && styles.justifyContentFlexEnd,
      ]}
    >
      {children}
    </Layout>
  );
};
export const NavigationButton = ({
  onPress,
  children,
  next,
  previous,
}: {
  // eslint-disable-next-line no-unused-vars
  onPress: (event: GestureResponderEvent) => void;
  children: string;
  next?: boolean;
  previous?: boolean;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return isMobile ? (
    <Button
      onPress={onPress}
      arrowLeft={previous}
      arrowRight={next}
      primary={next}
      secondary={previous}
      ignoreWidth
      tiny
    >
      {children}
    </Button>
  ) : (
    <Button
      onPress={onPress}
      arrowLeft={previous}
      arrowRight={next}
      primary={next}
      secondary={previous}
      ignoreWidth
    >
      {children}
    </Button>
  );
};
export const Circle = ({ active }: { active: boolean }): JSX.Element => (
  <View
    style={[styles.circle, { backgroundColor: active ? '#000000' : '#D8D8D8' }]}
  ></View>
);
export const MobileNavigation = ({
  children,
}: {
  children: React.ReactNode;
  navigation: DrawerNavigationHelpers;
  prevScreen: Screen;
  nextScreen: Screen;
}): JSX.Element | null => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  if (!isMobile) return null;
  return (
    <Layout marginTop={'regular'} style={styles.desktopContainerNavigation}>
      <PanGestureHandler
        onGestureEvent={() => {
          // activate panning
        }}
      >
        <LongPressGestureHandler
          onHandlerStateChange={() => {
            // activate move
          }}
          minDurationMs={100}
        >
          <View style={styles.box}>{children}</View>
        </LongPressGestureHandler>
      </PanGestureHandler>
    </Layout>
  );
};
export const DesktopNavigation = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  if (isMobile) return null;
  return <View style={styles.desktopNavigation}>{children}</View>;
};
export const Breadcrumb = ({
  children,
  active,
  navigation,
  pages,
  screen,
}: {
  children: React.ReactNode;
  active?: boolean;
  navigation: DrawerNavigationHelpers;
  pages: Page[];
  screen: string;
}): JSX.Element => (
  <TouchableOpacity
    onPress={() => handleReportNavigation(navigation, pages).goTo(screen)}
    style={{
      flex: 1,
    }}
  >
    <View
      style={{
        height: 3.5,
        backgroundColor: active ? '#000' : '#F0F1F2',
      }}
    ></View>
    <Text
      fontWeight={'500'}
      small
      style={{
        textAlign: 'center',
        paddingVertical: 16,
        height: 52,
        color: active ? '#000' : '#808080',
      }}
    >
      {children}
    </Text>
  </TouchableOpacity>
);
export const NavigationContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  // @ts-ignore
  <View
    style={{
      position: 'relative',
      bottom: 0,
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#ffffff',
    }}
  >
    {children}
  </View>
);

export const ScrollingNavigationContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <Layout
    marginTop={'xl'}
    style={{
      width: '100%',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'flex-end',
    }}
  >
    {children}
  </Layout>
);
export const navigationHelper = ({
  screen,
  pages,
}: {
  screen: string;
  pages: Page[];
}) => {
  const navigationButtons = pages.map((page) => {
    const screenName = ReportPageScreenMapping[page.__typename];
    return {
      screenName,
      active: screenName === screen,
      breadcrumb: page.breadcrumb,
      label: page.goToButton,
    };
  });
  const prevScreenIndex =
    navigationButtons.findIndex((button) => button.active) - 1;
  const nextScreenIndex =
    navigationButtons.findIndex((button) => button.active) + 1;
  const prevScreen =
    prevScreenIndex >= 0 ? navigationButtons[prevScreenIndex] : null;
  const nextScreen =
    nextScreenIndex < navigationButtons.length
      ? navigationButtons[nextScreenIndex]
      : null;
  const currScreen = navigationButtons.find((button) => button.active);
  return {
    navigationButtons,
    currScreen,
    nextScreen,
    prevScreen,
  };
};
export const NavigationButtons = ({
  navigation,
  pages,
  nextScreen,
  prevScreen,
  localization,
}: {
  navigation: DrawerNavigationHelpers;
  pages: Page[];
  nextScreen: Screen;
  prevScreen: Screen;
  localization: Localization;
}): JSX.Element => (
  <Navigation justifyContentFlexStart={!nextScreen} justifyContentFlexEnd={!prevScreen}>
    {prevScreen && (
      <NavigationButton onPress={() => handleReportNavigation(navigation, pages).goTo(prevScreen.screenName)} previous>
        {localization?.navigationBackButton ?? 'Back'}
      </NavigationButton>
    )}
    {!!prevScreen && !!nextScreen && <Layout paddingHorizontal={'small'} />}
    {nextScreen && (
      <NavigationButton onPress={() => handleReportNavigation(navigation, pages).goTo(nextScreen.screenName)} next>
        {nextScreen.label}
      </NavigationButton>
    )}
  </Navigation>
);
export const ScrollingFooterNavigation = ({
  screen,
  navigation,
  pages,
  localization,
}: {
  screen: string;
  navigation: DrawerNavigationHelpers;
  pages: Page[];
  localization: Localization;
}): JSX.Element | null => {
  const { nextScreen, prevScreen } = navigationHelper({ screen, pages });
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const filteredPages = pages.filter((p) => p.goToButton !== 'View Background');
  if (isMobile) return null;
  return (
    <ScrollingNavigationContainer>
      <NavigationButtons
        localization={localization}
        pages={filteredPages}
        navigation={navigation}
        nextScreen={nextScreen}
        prevScreen={prevScreen}
      />
    </ScrollingNavigationContainer>
  );
};
export const Footer = ({
  screen,
  navigation,
  pages,
  localization,
}: {
  screen: string;
  navigation: DrawerNavigationHelpers;
  pages: Page[];
  localization: Localization;
}): JSX.Element => {
  const { navigationButtons, currScreen, nextScreen, prevScreen } =
    navigationHelper({ screen, pages });
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const filteredPages = pages.filter((p) => p.goToButton !== 'View Background');
  return (
    <NavigationContainer>
      <MobileNavigation
        navigation={navigation}
        prevScreen={prevScreen}
        nextScreen={nextScreen}
      >
        <Layout marginBottom={'small'}>
          <Text tiny fontWeight={'500'} style={styles.breadcrumbMobile}>
            {currScreen?.breadcrumb}
          </Text>
        </Layout>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          {navigationButtons.map((button) => (
            <Circle key={button.label} active={button.active} />
          ))}
        </View>
      </MobileNavigation>
      {isMobile && (
        <NavigationButtons
          localization={localization}
          pages={filteredPages}
          navigation={navigation}
          nextScreen={nextScreen}
          prevScreen={prevScreen}
        />
      )}
      <DesktopNavigation>
        {navigationButtons.map((button) => (
          <Breadcrumb
            navigation={navigation}
            screen={button.screenName}
            pages={filteredPages}
            key={button.label}
            active={button.active}
          >
            {button.breadcrumb}
          </Breadcrumb>
        ))}
      </DesktopNavigation>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  navigationContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  breadcrumbMobile: {
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  navigationBlur: {
    position: 'relative', // absolute needed for native
    bottom: 0,
    width: '100%',
    alignItems: 'center',
  },
  circle: {
    width: 8,
    height: 8,
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 8,
  },
  box: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  desktopContainerNavigation: {
    width: '100%',
  },
  desktopNavigation: {
    flexDirection: 'row',
    width: '100%',
  },
});
