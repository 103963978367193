import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation, ApolloError, useLazyQuery } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from 'sentry-expo';
import '@expo/match-media';

import { MessageTitle, MessageContainer, MessageBubble, Avatars } from 'components/Overview';
import { ReportContainer, Paragraph } from 'components/ReportCommon';
import ScreenWrapper from 'components/ScreenWrapper';
import DesktopNavigation from 'components/DesktopNavigation';

import { SPECIFIC_REPORT, cmsClient } from 'sample-collection-instructions';
import { getReport, getPage } from 'utils/content';
import parseMarkdown from 'utils/markdown';
import { Doctor, GetTestResultsData, GetTestResultsVariables, Page, PartnerConfig, SpecificReportResponse } from 'types';
import reportClient, { GET_KIT_ID, GET_PARTNER_CONFIG } from 'kit-activation';
import { RouteProp } from '@react-navigation/native';

import GET_TEST_RESULTS from './queries';

export default ({ navigation, route }: { navigation: DrawerNavigationHelpers; route: RouteProp<{ params?: { kitId?: string } }, 'params'> }): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [title, setTitle] = useState('');
  const [reportText, setReportText] = useState<JSX.Element[] | null>(null);
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] = useState<ApolloError | null>(null);
  const [testResultsData, setTestResultsData] = useState<GetTestResultsData | undefined>();

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });

  const kitId = route.params?.kitId || data?.kitId || '';

  const setScreenData = () => {
    const {
      patient: { name },
      testedDate,
      observations,
    } = testResultsData!.getTestResults;

    const { pages: unfilteredPages, title: reportTitle, doctors: reportDoctors } = getReport(content, observations);
    const page = getPage(unfilteredPages, 'ReportOverviewPageRecord');
    const { welcomeMessage } = page;
    const filteredPages = unfilteredPages.filter((p) => p.goToButton !== 'View Background');
    setPages(filteredPages);
    setTitle(reportTitle);
    setDoctors(reportDoctors);
    const firstName = name[0].given[0];
    const richText = parseMarkdown(
      {
        Header: MessageTitle,
        Paragraph,
        first_name: firstName,
        tested_date: testedDate ? `on ${format(new Date(testedDate), 'MMMM dd, yyyy')} ` : '',
      },
      welcomeMessage
    ) as JSX.Element[];
    setReportText(richText);
  };

  const [getSpecificReport, { loading: contentLoading, error: contentError, data: content }] = useLazyQuery<SpecificReportResponse>(
    SPECIFIC_REPORT(testResultsData?.getTestResults.testType),
    {
      client: cmsClient,
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setScreenData();
      },
      onError: (err) => {
        Sentry.Browser.captureException(err, {
          extra: {
            event: 'getSpecificReport',
            message: `Report retrieval on OverviewScreen for kit id ${kitId} failed`,
            testName: testResultsData?.getTestResults?.testType,
          },
        });
        console.error('An error occurred during getSpecificReport Query', err);
      },
    }
  );

  const [getTestResults, { loading: resultsLoading, error: resultsError }] = useMutation<GetTestResultsData, GetTestResultsVariables>(GET_TEST_RESULTS, {
    variables: {
      kitId,
    },
    onCompleted: ({ getTestResults: testResultsData }) => {
      setTestResultsData({ getTestResults: testResultsData });
      getSpecificReport();
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetTestResults',
          message: `Test results retrieval on OverviewScreen for kit id ${kitId} failed`,
        },
      });
      console.error(`An error occurred during GetTestResults Mutation with kit id: ${kitId}`, err);
    },
  });

  useEffect(() => {
    getTestResults();
  }, [kitId]);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const partnerData = reportClient.cache.readQuery<{
    partnerConfig: PartnerConfig;
  }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = partnerData?.partnerConfig;

  return (
    <ScreenWrapper loading={contentLoading || resultsLoading || reportDownloadLoading} error={contentError || resultsError || reportDownloadError} excludeWrapper excludeFooter>
      <View style={[styles.view, isMobile ? styles.viewMobile : null]}>
        {!isMobile ? <DesktopNavigation navigation={navigation} componentLabel={'PatientDashboardScreen'} partnerConfig={partnerConfig} /> : null}
        <ReportContainer pages={pages} navigation={navigation} title={title} screen={'OverviewScreen'} setLoading={setReportDownloadLoading} setError={setReportDownloadError}>
          <MessageContainer>
            <MessageBubble>
              {reportText?.map((text) => (
                <React.Fragment key={text.key}>{text}</React.Fragment>
              ))}
              <Avatars doctors={doctors} />
            </MessageBubble>
          </MessageContainer>
        </ReportContainer>
      </View>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '100%',
  },
  viewMobile: {
    flexDirection: 'column',
    overflow: 'scroll',
  },
});
