import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { LayoutProps, Styles } from 'types';
import { ScrollLayout } from './StyledView';

const Content = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: LayoutProps;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const mTopxl = 'xl';
  if (isMobile) {
    const isIOS = Platform.OS === 'ios';
    const isAndroid = Platform.OS === 'android';
    let mTop;
    if (isIOS || isAndroid) {
      mTop = mTopxl;
    } else {
      mTop = mTopxl;
    }
    return (
      <ScrollLayout
        marginTop={mTop}
        contentContainerStyle={styles.containerContentMobile}
        style={styles.content}
      >
        {children}
      </ScrollLayout>
    );
  }
  return (
    <ScrollLayout
      padding={mTopxl}
      contentContainerStyle={styles.containerContent}
      style={[styles.content, style] as Styles}
    >
      {children}
    </ScrollLayout>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  containerContent:
    Platform.OS === 'web'
      ? {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        minHeight: '100%',
        backgroundColor: '#FFFFFF',
      }
      : {
        paddingBottom: 270,
        minHeight: '100%',
        backgroundColor: '#FFFFFF',
      },
  containerContentMobile: {
    minHeight: '100%',
  },
});

export default Content;
