/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
import * as React from 'react';
import { Image, View, ImageSourcePropType, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { MaterialIcons } from '@expo/vector-icons';

import { ReportLocalization } from 'sample-collection-instructions';
import parseMarkdown from 'utils/markdown';
import { Biomarker, Localization, Panel as PanelProp, Result, Sex, DeveloperConfig } from 'types';

import Text from './StyledText';
import { Layout } from './StyledView';
import StyledButton from './StyledButton';
import { getColorCodeColorWithDevConfigBoolean } from '../utils/content';

const OBS_INTERPRETATIONS_SYSTEM = 'https://fhir.imaware.health/codesystems/observation-interpretations';
const OBS_COLORS_SYSTEM = 'https://fhir.imaware.health/codesystems/observation-colors';

export const PanelContainer = ({ children, onDownloadReport }: { children: React.ReactNode; onDownloadReport: () => void }): JSX.Element => {
  const childWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onDownloadReport });
    }
    return child;
  });
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const style = isMobile
    ? {
        flex: 1,
        minHeight: 'auto',
      }
    : {
        marginVertical: 20,
        flex: 1,
        marginHorizontal: 30,
        minHeight: 'auto',
      };

  return (
    <View style={style}>
      {/* @ts-ignore */}
      {childWithProps}
    </View>
  );
};
export const ResultSummary = ({ children, bgColor }: { children: string; bgColor: string }): JSX.Element => {
  const summary = parseMarkdown(
    {
      Header: ({ children: headerChildren }: { children: string }) => (
        <Layout paddingBottom={'tiny'} style={{ display: 'flex' }}>
          <Text lora large fontWeight={'500'}>
            {headerChildren}
          </Text>
        </Layout>
      ),
      Paragraph: ({ children: paragraphChildren }: { children: string }) => <Text regular>{paragraphChildren}</Text>,
    },
    children
  );
  return (
    <Layout
      padding={'small'}
      style={{
        maxWidth: 525,
        borderRadius: 8,
        backgroundColor: bgColor,
        alignSelf: 'center',
      }}
    >
      <View>
        <Text>{summary}</Text>
      </View>
    </Layout>
  );
};

const PanelLeft = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }) => (
  <Layout
    marginBottom={isMobile && 'large'}
    marginRight={!isMobile && 'xl'}
    style={{
      flex: 40,
    }}
  >
    {children}
  </Layout>
);

const PanelRight = ({
  isMobile,
  children,
}: {
  children: React.ReactNode;
  isMobile: boolean;
  // @ts-ignore
}) => <View style={isMobile ? {} : { flex: 60 }}>{children}</View>;

const BiomarkerRange = ({ biomarker, result, localization, isMobile, sex }: { biomarker: Biomarker; result: Result; localization: Localization; isMobile: boolean; sex: Sex }) => {
  const { name, shortName = '', units, ranges, developerConfig, note } = biomarker;
  const { resourceSource, resourceURL } = developerConfig;
  const [expandedNote, setExpandedNote] = React.useState(false);
  const sexSpecificRanges = ranges[sex] || ranges;
  const { segments, reportable_range, boolean } = sexSpecificRanges;
  const value = result.qualitativeResult ?? result.quantitativeResult ?? '';
  let val = value;
  if (result.quantitativeResult) {
    if (val > reportable_range[1]) {
      val = reportable_range[1];
    } else if (val < reportable_range[0]) {
      val = reportable_range[0];
    }
  }
  let outcome = '';
  let valuePresentation = String(value);
  if (result.qualitativeResult) {
    val = val as string;
    valuePresentation = '';
    const localizationMap: { [key: string]: string } = {
      positive: 'biomarkerPositiveOutcome',
      negative: 'biomarkerNegativeOutcome',
      'n/a': 'biomarkerInsufficientSampleOutcome',
    };
    const localizationKey = localizationMap[val.toLowerCase()];
    outcome = localization?.[localizationKey] ?? '';
  } else {
    const targetRange = segments.find((segment) => segment.code === getColorCodeColorWithDevConfigBoolean('targetRange', true));
    const optimalRange = segments.find((segment) => segment.code === getColorCodeColorWithDevConfigBoolean('optimumRange', true));
    if (!targetRange) return null;
    if (!targetRange.upperBoundInclusive && val >= targetRange.range[1]) {
      if (optimalRange && !optimalRange.upperBoundInclusive && val < optimalRange.range[1]) {
        outcome = localization?.biomarkerOptimalOutcome ?? '';
      } else {
        outcome = localization?.biomarkerElevatedOutcome ?? '';
      }
    } else if (targetRange.upperBoundInclusive && val > targetRange.range[1]) {
      if (optimalRange && optimalRange.upperBoundInclusive && val <= optimalRange.range[1]) {
        outcome = localization?.biomarkerOptimalOutcome ?? '';
      } else {
        outcome = localization?.biomarkerElevatedOutcome ?? '';
      }
    } else if (!targetRange.lowerBoundInclusive && val <= targetRange.range[0]) {
      outcome = localization?.biomarkerLowOutcome ?? '';
    } else if (targetRange.lowerBoundInclusive && val < targetRange.range[0]) {
      outcome = localization?.biomarkerLowOutcome ?? '';
    } else outcome = localization?.biomarkerNormalOutcome ?? '';

    if (result.outOfReportableRange) {
      valuePresentation = `${result.comparator} ${value}`;
    }
  }
  const nameWidth = isMobile ? 92 : 'auto';
  // eslint-disable-next-line no-nested-ternary
  const valueWidth = isMobile ? (result.qualitativeResult ? 0 : 82) : 'auto';

  const statusColorMap: { [key: string]: string } = {
    'Insufficient Sample': 'rgba(245, 203, 22, 0.17)',
    Low: '#FCECEB',
    High: 'rgba(245, 203, 22, 0.17)',
    Elevated: '#FFE8CD', // orange
    Detected: '#FFE8CD', // orange
    Positive: '#FFE8CD', // orange
    'Not Detected': 'rgba(122, 220, 109, 0.17)', // green
    Negative: 'rgba(122, 220, 109, 0.17)', // green
    Normal: 'rgba(122, 220, 109, 0.17)', // green
    Optimal: 'rgba(122, 220, 109, 0.17)', // green
  };
  const statusBorderColorMap: { [key: string]: string } = {
    'Insufficient Sample': '#F5CB16',
    Low: '#E25245',
    High: '#F5CB16',
    Elevated: '#FFA337', // orange
    Detected: '#FFA337', // orange
    Positive: '#FFA337', // orange
    'Not Detected': '#7ADC6D', // green
    Negative: '#7ADC6D', // green
    Optimal: '#7ADC6D', // green
  };

  if (boolean) {
    return (
      <Layout
        marginTop={'tiny'}
        marginBottom={'regular'}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text label fontWeight={'700'} style={{ color: '#4A4A4A', width: nameWidth }}>
          {shortName || name}
        </Text>
        <Text
          fontWeight={'700'}
          label
          style={{
            textAlign: 'right',
            color: '#4A4A4A',
            width: valueWidth,
            textTransform: 'uppercase',
          }}
        >
          {outcome}
        </Text>
      </Layout>
    );
  }
  return (
    <Layout
      marginTop={'tiny'}
      marginBottom={'regular'}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
        minHeight: 'auto',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            label
            fontWeight={'700'}
            style={[
              { color: '#4A4A4A', width: nameWidth },
              isMobile && {
                alignSelf: 'flex-start',
                width: '100%',
              },
            ]}
          >
            {shortName || name}
          </Text>
          {note && note !== 'Calculation' ? (
            <TouchableOpacity onPress={() => setExpandedNote((prevVal) => !prevVal)}>
              <Text lato style={{ fontSize: 14, color: '#4A55E2', marginTop: 3 }}>
                {expandedNote ? 'Show Less -' : 'Show More +'}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
        <Layout
          marginBottom={isMobile && 'regular'}
          marginTop={isMobile && 'medium'}
          style={[
            {
              flexDirection: 'row',
            },
            isMobile
              ? {
                  width: '100%',
                }
              : {},
          ]}
        >
          <Layout
            style={{
              width: valueWidth,
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <div />
            <Layout
              style={{
                width: valueWidth,
                flexDirection: 'row',
                alignItems: 'flex-end',
                ...(!isMobile && { marginHorizontal: 50 }),
              }}
            >
              <Text
                fontWeight={'700'}
                label
                style={{
                  textAlign: isMobile ? 'left' : 'right',
                  color: '#000000',
                }}
              >
                {valuePresentation}
              </Text>
              {units ? <Text fontWeight={'500'} units style={{ color: '#000000' }}>{` ${units}`}</Text> : null}
            </Layout>
            <View
              style={{
                backgroundColor: statusColorMap[outcome],
                borderColor: statusBorderColorMap[outcome],
                justifyContent: 'center',
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: 100,
                paddingVertical: 0,
                paddingHorizontal: 17,
                maxHeight: 25,
              }}
            >
              <Text label fontWeight={'700'} style={{ color: '#4A4A4A', textTransform: 'uppercase' }}>
                {outcome}
              </Text>
            </View>
          </Layout>
        </Layout>
      </View>
      <View style={{ marginTop: 5 }}>
        {expandedNote ? (
          <>
            <Text style={{ fontSize: 14, color: '#4A4A4A' }}>{note}</Text>
            {resourceSource ? (
              <>
                <br />
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontSize: 14, color: '#4A4A4A' }}>Source: </Text>
                  <TouchableOpacity onPress={() => window.open(resourceURL, '_blank')}>
                    <Text
                      style={{
                        fontSize: 14,
                        color: '#4A4A4A',
                        textDecorationLine: 'underline',
                      }}
                    >
                      {resourceSource}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => window.open(resourceURL, '_blank')}>
                    <MaterialIcons name="open-in-new" size={17} color="#4A4A4A" style={{ paddingLeft: 4 }} />
                  </TouchableOpacity>
                </View>
              </>
            ) : null}
          </>
        ) : null}
      </View>
    </Layout>
  );
};

const BiomarkerRange_v2 = ({ biomarker, result, isMobile }: { biomarker: Biomarker; result: Result; isMobile: boolean }) => {
  const { name, shortName = '', units, developerConfig, note } = biomarker;
  const { resourceSource, resourceURL } = developerConfig;
  const [expandedNote, setExpandedNote] = React.useState(false);
  const value = result.qualitativeResult ?? result.quantitativeResult ?? '';
  const outcome =
    result.interpretation?.find((i) => i.coding?.some((c) => c.system === OBS_INTERPRETATIONS_SYSTEM))?.coding?.find((c) => c.system === OBS_INTERPRETATIONS_SYSTEM)?.display || '';
  const color = result.interpretation?.find((i) => i.coding?.some((c) => c.system === OBS_COLORS_SYSTEM))?.coding?.find((c) => c.system === OBS_COLORS_SYSTEM)?.code || 'white';
  let valuePresentation = String(value);
  if (result.qualitativeResult) {
    valuePresentation = '';
  }
  if (result.outOfReportableRange) {
    valuePresentation = `${result.comparator} ${String(value)}`;
  }
  const nameWidth = isMobile ? 92 : 'auto';
  // eslint-disable-next-line no-nested-ternary
  const valueWidth = isMobile ? (result.qualitativeResult ? 0 : 82) : 'auto';

  const statusColorMap: { [key: string]: string } = {
    'IMA-OBS-RED': '#FCECEB',
    'IMA-OBS-YELLOW': 'rgba(245, 203, 22, 0.17)',
    'IMA-OBS-ORANGE': '#FFE8CD',
    'IMA-OBS-GREEN': 'rgba(122, 220, 109, 0.17)',
    WHITE: '#FFF',
  };
  const statusBorderColorMap: { [key: string]: string } = {
    'IMA-OBS-RED': '#E25245',
    'IMA-OBS-YELLOW': '#F5CB16',
    'IMA-OBS-ORANGE': '#FFA337',
    'IMA-OBS-GREEN': '#7ADC6D',
    WHITE: '#000',
  };
  return (
    <Layout
      marginTop={'tiny'}
      marginBottom={'regular'}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
        minHeight: 'auto',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            label
            fontWeight={'700'}
            style={[
              { color: '#4A4A4A', width: nameWidth },
              isMobile && {
                alignSelf: 'flex-start',
                width: '100%',
              },
            ]}
          >
            {shortName || name}
          </Text>
          {note && note !== 'Calculation' ? (
            <TouchableOpacity onPress={() => setExpandedNote((prevVal) => !prevVal)}>
              <Text lato style={{ fontSize: 14, color: '#4A55E2', marginTop: 3 }}>
                {expandedNote ? 'Show Less -' : 'Show More +'}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
        <Layout
          marginBottom={isMobile && 'regular'}
          marginTop={isMobile && 'medium'}
          style={[
            {
              flexDirection: 'row',
            },
            isMobile
              ? {
                  width: '100%',
                }
              : {},
          ]}
        >
          <Layout
            style={{
              width: valueWidth,
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <div />
            <Layout
              style={{
                width: valueWidth,
                flexDirection: 'row',
                alignItems: 'flex-end',
                ...(!isMobile && { marginHorizontal: 50 }),
              }}
            >
              <Text
                fontWeight={'700'}
                label
                style={{
                  textAlign: isMobile ? 'left' : 'right',
                  color: '#000000',
                }}
              >
                {valuePresentation}
              </Text>
              {units ? <Text fontWeight={'500'} units style={{ color: '#000000' }}>{` ${units}`}</Text> : null}
            </Layout>
            <View
              style={{
                backgroundColor: statusColorMap[color.toUpperCase()],
                borderColor: statusBorderColorMap[color.toUpperCase()],
                justifyContent: 'center',
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: 100,
                paddingVertical: 0,
                paddingHorizontal: 17,
                maxHeight: 25,
              }}
            >
              <Text label fontWeight={'700'} style={{ color: '#4A4A4A', textTransform: 'uppercase' }}>
                {outcome}
              </Text>
            </View>
          </Layout>
        </Layout>
      </View>
      <View style={{ marginTop: 5 }}>
        {expandedNote ? (
          <>
            <Text style={{ fontSize: 14, color: '#4A4A4A' }}>{note}</Text>
            {resourceSource ? (
              <>
                <br />
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontSize: 14, color: '#4A4A4A' }}>Source: </Text>
                  <TouchableOpacity onPress={() => window.open(resourceURL, '_blank')}>
                    <Text
                      style={{
                        fontSize: 14,
                        color: '#4A4A4A',
                        textDecorationLine: 'underline',
                      }}
                    >
                      {resourceSource}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => window.open(resourceURL, '_blank')}>
                    <MaterialIcons name="open-in-new" size={17} color="#4A4A4A" style={{ paddingLeft: 4 }} />
                  </TouchableOpacity>
                </View>
              </>
            ) : null}
          </>
        ) : null}
      </View>
    </Layout>
  );
};

const BiomarkerRanges = ({
  biomarkers,
  results,
  isMobile,
  sex,
  reportVersion,
}: {
  biomarkers: Biomarker[];
  results: Result[];
  isMobile: boolean;
  sex: Sex;
  reportVersion: string;
}) => (
    <ReportLocalization.Consumer>
      {(localization) =>
        biomarkers.map((biomarker) => {
          const result = results.find(({ key }) => key === biomarker.developerConfig.key);
          if (!result) return null;
          console.log(`\n\n\nreportVersion: ${reportVersion}\n\n\n`);
          if (reportVersion === 'imaware_v1') {
            return <BiomarkerRange isMobile={isMobile} key={biomarker.name} biomarker={biomarker} result={result} localization={localization} sex={sex} />;
          }
          return <BiomarkerRange_v2 isMobile={isMobile} key={biomarker.name} biomarker={biomarker} result={result} />;
        })
      }
    </ReportLocalization.Consumer>
  );

const Panel = ({
  panel,
  results,
  isMobile,
  sex,
  reportVersion,
  onDownloadReport,
}: {
  panel: PanelProp;
  results: Result[];
  isMobile: boolean;
  sex: Sex;
  reportVersion: string;
  onDownloadReport: () => void;
}) => {
  const { biomarkers, addPdfForClinicianOption } = panel;
  const panelNotEmpty = panel.biomarkers.some(({ developerConfig }) => results.some(({ key }: DeveloperConfig) => key === developerConfig?.key));
  if (panelNotEmpty) {
    return (
      <Layout
        marginBottom={'regular'}
        style={{
          backgroundColor: '#F7F9FB',
          borderRadius: 4,
          flexDirection: isMobile ? 'column' : 'row',
          padding: 30,
        }}
      >
        <PanelLeft isMobile={isMobile}>
          <Layout marginBottom={'small'}>
            <Text
              style={{
                textTransform: 'uppercase',
              }}
              regular
              fontWeight={'700'}
            >
              {panel.title}
            </Text>
          </Layout>
          <Text regular>{panel.description}</Text>
          {addPdfForClinicianOption ? (
            <StyledButton
              ignoreWidth
              onPress={onDownloadReport}
              style={{
                fontSize: 11,
                padding: 5,
                lineHeight: 24,
                justifyContent: 'center',
                marginTop: 30,
              }}
            >
              Download PDF for Clinician
            </StyledButton>
          ) : null}
        </PanelLeft>
        <PanelRight isMobile={isMobile}>
          <BiomarkerRanges isMobile={isMobile} biomarkers={biomarkers} results={results} sex={sex} reportVersion={reportVersion} />
        </PanelRight>
      </Layout>
    );
  } else {
    return null;
  }
};

export const Panels = ({
  panels,
  results,
  sex,
  reportVersion,
  onDownloadReport,
}: {
  panels: PanelProp[];
  results: Result[];
  sex: Sex;
  reportVersion: string;
  onDownloadReport: () => void;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <>
      {panels.map((panel) => (
        <Panel isMobile={isMobile} key={panel.title} panel={panel} results={results} sex={sex} onDownloadReport={onDownloadReport} reportVersion={reportVersion} />
      ))}
    </>
  );
};

export const SpeechBubble = ({ avatarSource, children }: { avatarSource: ImageSourcePropType; children: string }): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Layout
      style={{
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignItems: isMobile ? 'flex-start' : 'flex-end',
        justifyContent: isMobile ? 'center' : 'center',
        alignSelf: 'center',
      }}
      marginTop={'regular'}
      marginHorizontal={'xl'}
    >
      <Layout
        style={{
          width: 40,
          height: 40,
          top: isMobile ? -23 : 27,
        }}
        marginRight={'tiny'}
      >
        <Image
          style={{
            width: 40,
            height: 40,
            borderRadius: 40,
          }}
          source={avatarSource}
        />
      </Layout>
      <Image
        style={{
          width: 23,
          height: 46,
          top: isMobile ? -23 : 0,
        }}
        source={isMobile ? require('../assets/images/triangle-down.png') : require('../assets/images/triangle.png')}
      />
      <Layout
        style={{
          backgroundColor: '#FAF7ED',
          borderRadius: 10,
          borderBottomLeftRadius: 0,
          maxWidth: 660,
        }}
        paddingHorizontal={'xl'}
        paddingVertical={'regular'}
      >
        <Text regular>{children}</Text>
      </Layout>
    </Layout>
  );
};
