import { ApolloError } from '@apollo/client';
import React from 'react';
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';

const LoadingErrorWrapper = ({
  loading,
  error,
  children,
}: {
  loading: boolean;
  error: Error | ApolloError | null;
  children: React.ReactNode;
}): JSX.Element => {
  if (error) {
    return <ErrorModal error={error} />;
  }
  if (loading) {
    return <LoadingModal />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default LoadingErrorWrapper;
