import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import ScreenWrapper from 'components/ScreenWrapper';
import GlobalStyles from 'constants/Styles';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';

const SuccessResetPasswordScreen = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}): JSX.Element => (
  <ScreenWrapper>
    <View style={styles.headerBar}>
      <Image
        style={styles.imawareLogo}
        source={require('assets/images/logo.png')}
      />
    </View>
    <View style={styles.box}>
      <View style={styles.desktopContainer}>
        <Text lora large fontWeight="500" testID='resetPass'>
          Reset Password Email Sent
        </Text>
        <Text style={styles.label} testID='textTest'>
          Please check your email for instructions on how to reset your
          password.
        </Text>
        <Button
          onPress={() => navigation.navigate('PatientDashboardScreen')}
          style={[GlobalStyles.button]}
          primary
          arrowRight
        >
          Back to Dashboard
        </Button>
      </View>
    </View>
  </ScreenWrapper>
);

const styles = StyleSheet.create({
  desktopContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  headerBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
  },
  imawareLogo: {
    width: 125,
    height: 21,
  },
  label: {
    textAlign: 'justify',
    marginTop: 12,
    marginBottom: 30,
  },
  box: {
    flex: 2,
    alignItems: 'center',
    backgroundColor: '#e6f9eb',
  },
});

export default SuccessResetPasswordScreen;
