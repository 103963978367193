import React, { useEffect, useState } from 'react';
import { useMutation, ApolloError, useLazyQuery } from '@apollo/client';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import * as Sentry from 'sentry-expo';

import { ReportContainer, ContentHeader, Paragraph, InformationParagraph } from 'components/ReportCommon';
import { ContentSummaryContainer, ContentUnresultedBiomarkers } from 'components/Common';
import { PanelContainer, Panels, ResultSummary } from 'components/Results';
import ScreenWrapper from 'components/ScreenWrapper';

import { cmsClient, SPECIFIC_REPORT } from 'sample-collection-instructions';
import reportClient, { GET_KIT_ID } from 'kit-activation';
import { getReport, getPage, getScenarioContent, getReportBiomarkersConfig, computeReportVersion } from 'utils/content';
import { formatObservations, calculateReportCode } from 'utils/data';
import { GetTestResultsData, GetTestResultsVariables, Page, Panel, Result, Sex, SpecificReportResponse } from 'types';

import ModalMissingBiomarkers from 'components/report/ModalMissingBiomarkers';
import ErrorModalMissingBiomarkers from 'components/report/ErrorModalMissingBiomarkers';
import { RouteProp } from '@react-navigation/native';
import GET_TEST_RESULTS from './queries';

export default ({ navigation, route }: { navigation: DrawerNavigationHelpers; route: RouteProp<{ params?: { kitId?: string } }, 'params'> }): JSX.Element => {
  const [pages, setPages] = useState<Page[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [unresultedBiomarkers, setUnresultedBiomarkers] = useState<string[]>([]);
  const [biomarkerPanels, setBiomarkerPanels] = useState<Panel[]>([]);
  const [summaryBgColor, setSummaryBgColor] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [sex, setSex] = useState<Sex>('' as Sex);
  const [explanation, setExplanation] = useState('');
  const [title, setTitle] = useState('');
  const [modalInformation, setModalInformation] = useState(false);
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [reportDownloadError, setReportDownloadError] = useState<ApolloError | null>(null);
  const [testResultsData, setTestResultsData] = useState<GetTestResultsData | undefined>();
  const [reportVersion, setReportVersion] = useState<string>('imaware_v1');

  const data = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });
  const kitId = route.params?.kitId || data?.kitId || '';

  const setScreenData = () => {
    const {
      patient: { gender },
      observations,
      conclusion,
      conclusionCode,
    } = testResultsData!.getTestResults;

    const computedReportVersion = computeReportVersion(conclusionCode, conclusion, observations); // added
    setReportVersion(computedReportVersion); // added
    const testResults = formatObservations(observations); // changed
    const { pages: unfilteredPages, title: reportTitle } = getReport(content, observations);
    const page = getPage(unfilteredPages, 'ReportResultsPageRecord');
    const { biomarkerPanels: panels = [], scenarios = [] } = page;
    const biomarkers = getReportBiomarkersConfig(content);
    const calculatedReportCode = calculateReportCode(testResults, biomarkers, gender, computedReportVersion, conclusionCode); // changed
    const { summary: scenarioSummary, explanation: scenarioExplanation = '' } = getScenarioContent(scenarios, calculatedReportCode.color);
    setSex(gender);
    setResults(testResults);
    if (computedReportVersion === 'imaware_v1') {
      setSummary(scenarioSummary);
    } else {
      setSummary(conclusion);
    }
    setExplanation(scenarioExplanation);
    const filteredPages = unfilteredPages.filter((p) => p.goToButton !== 'View Background');
    const unresultedBiomarkerNames = observations.filter(({ status }) => status === 'registered').map(obs => obs.code.name);
    setUnresultedBiomarkers(unresultedBiomarkerNames);
    setSummaryBgColor(calculatedReportCode.summaryBackgroundColor);
    setBiomarkerPanels(panels);
    setPages(filteredPages);
    setTitle(reportTitle);
  };

  const [getSpecificReport, { loading: contentLoading, error: contentError, data: content }] = useLazyQuery<SpecificReportResponse>(
    SPECIFIC_REPORT(testResultsData?.getTestResults.testType),
    {
      client: cmsClient,
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setScreenData();
      },
      onError: (err) => {
        Sentry.Browser.captureException(err, {
          extra: {
            event: 'getSpecificReport',
            message: `Report retrieval on ResultsScreen for kit id ${kitId} failed`,
            testName: testResultsData?.getTestResults?.testType,
          },
        });
        console.error('An error occurred during getSpecificReport Query', err);
      },
    }
  );

  const [getTestResults, { loading: resultsLoading, error: resultsError }] = useMutation<GetTestResultsData, GetTestResultsVariables>(GET_TEST_RESULTS, {
    variables: {
      kitId,
    },
    onCompleted: ({ getTestResults: testResultsData }) => {
      setTestResultsData({ getTestResults: testResultsData });
      getSpecificReport();
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetTestResults',
          message: `Test results retrieval on ResultsScreen for kit id ${kitId} failed`,
        },
      });
      console.error(`An error occurred during GetTestResults Mutation with kit id: ${kitId}`, err);
    },
  });

  useEffect(() => {
    getTestResults();
  }, [kitId]);

  const showModalInformation = () => setModalInformation(true);

  return (
    <ScreenWrapper loading={contentLoading || resultsLoading || reportDownloadLoading} error={contentError || resultsError || reportDownloadError} excludeWrapper excludeFooter>
      <ReportContainer pages={pages} navigation={navigation} title={title} screen={'ResultsScreen'} setLoading={setReportDownloadLoading} setError={setReportDownloadError}>
        <ContentHeader title={title} />
        <ContentSummaryContainer>
          <ResultSummary bgColor={summaryBgColor}>{summary}</ResultSummary>
          <Paragraph leftAlignOnMobile>{explanation}</Paragraph>
        </ContentSummaryContainer>
        <PanelContainer>
          <ContentUnresultedBiomarkers>{unresultedBiomarkers.length ? <InformationParagraph onPress={showModalInformation} /> : null}</ContentUnresultedBiomarkers>
          <Panels panels={biomarkerPanels} results={results} sex={sex} reportVersion={reportVersion} />
        </PanelContainer>
        {modalInformation && unresultedBiomarkers.length > 0 ? (
          <ModalMissingBiomarkers unresultedBiomarkers={unresultedBiomarkers} onHidden={() => setModalInformation(false)} />
        ) : null}
        {modalInformation && unresultedBiomarkers.length === 0 ? <ErrorModalMissingBiomarkers onHidden={() => setModalInformation(false)} /> : null}
      </ReportContainer>
    </ScreenWrapper>
  );
};
