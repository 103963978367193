import axios from 'axios';
import * as Sentry from 'sentry-expo';
import { KlaviyoRequests } from 'types';
import axiosRetry from 'axios-retry';
import env from 'constants/Config';
import { SendEmail, ACCOUNT_EXISTS, FAVOR_ACCOUNT_EXISTS, BRC_ACCOUNT_EXISTS } from './types';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const klaviyoRequestWithProperties = (req: KlaviyoRequests) =>
  axios
    .request({
      method: 'POST',
      url: 'https://a.klaviyo.com/api/track',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        data: `{"token": "${env.KLAVIYO_PUBLIC_APIKEY}", "event": "${req.event}", 
              "customer_properties": {"$email": "${req.email}", "$first_name": 
              "${req.firstName}", "$last_name": "${req.lastName}"}, ${req.properties}}`,
      }),
    })
    .then((response) => console.log(response.status))
    .catch((error) => {
      Sentry.Browser.captureException(error, {
        extra: {
          event: req.event,
          message: 'Klaviyo request failed',
        },
        user: {
          email: req.email,
        },
      });
      console.log(error);
    });

export const klaviyoRequest = (req: KlaviyoRequests) =>
  axios
    .request({
      method: 'POST',
      url: 'https://a.klaviyo.com/api/track',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: new URLSearchParams({
        data: `{"token": "${env.KLAVIYO_PUBLIC_APIKEY}", "event": "${req.event}", 
              "customer_properties": {"$email": "${req.email}", "$first_name": 
              "${req.firstName}", "$last_name": "${req.lastName}"}}`,
      }),
    })
    .then((response) => console.log(response.status))
    .catch((error) => {
      Sentry.Browser.captureException(error, {
        extra: {
          event: req.event,
          message: 'Klaviyo request failed',
        },
        user: {
          email: req.email,
        },
      });
      console.log(error);
    });

export const sendAccountAlreadyExistsEmail = async ({ event, email, partner }: SendEmail) => {
  let klaviyoEvent = event;
  if (partner && !partner.startsWith('imaware')) {
    if (event === ACCOUNT_EXISTS && partner.toLowerCase() === 'favor') {
      klaviyoEvent = FAVOR_ACCOUNT_EXISTS;
    } else  if (event === ACCOUNT_EXISTS && partner.toLowerCase() === 'br-center') {
      klaviyoEvent = BRC_ACCOUNT_EXISTS;
    } else  {
      klaviyoEvent = `${partner}_AccountAlreadyExists`;
    }
  }

  try {
    return klaviyoRequest({
      event: klaviyoEvent,
      email,
      firstName: undefined,
      lastName: undefined,
    });
  } catch (err) {
    Sentry.Browser.captureException(err, {
      extra: {
        event,
        partner,
        message: 'Klaviyo request failed',
      },
      user: {
        email,
      },
    });
    throw err;
  }
};
