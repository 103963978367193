import React from 'react';
import { Alert, Platform, StyleSheet, View } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import * as Sentry from 'sentry-expo';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import Button from 'components/StyledButton';
import TextInput from 'components/StyledTextInput';
import GlobalStyles from 'constants/Styles';
import env from 'constants/Config';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import { ForgotPasswordField, PartnerConfig } from 'types';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import DesktopNavigation from '../../../components/DesktopNavigation';

const ResetPasswordScreen = ({
  navigation,
  isDesktop,
}: {
  navigation: StackNavigationHelpers;
  isDesktop: boolean;
}) => {
  const { control, handleSubmit } = useForm<ForgotPasswordField>({
    mode: 'onBlur',
  });
  const onSendPress: SubmitHandler<ForgotPasswordField> = (data) => {
    axios
      .request({
        method: 'POST',
        url: `${env.AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          email: data.email,
          connection: env.AUTH0_CONNECTION,
          client_id: env.AUTH0_CLIENT_ID,
        },
      })
      .then(() => navigation.navigate('SuccessResetPasswordScreen'))
      .catch(({ err }) => {
        Sentry.Browser.captureException(err, {
          extra: {
            event: 'ResetPassword',
            message: `Password reset failed`,
          },
          user: {
            email: data.email,
          },
        });
        Alert.alert(
          err?.message ??
            'An error occurred while generating your reset password link. Please try again later.'
        );
      });
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  return (
    <View style={Platform.OS === 'web' ? styles.container : {}}>
      {isDesktop ? (
        <DesktopNavigation
          navigation={navigation}
          componentLabel={'AccountDetailsScreen'}
          partnerConfig={partnerConfig}
        />
      ) : null}
      <ScreenWrapper>
        <View style={styles.box}>
          <View style={[styles.header, isMobile && { paddingTop: 15 }]}>
            <Button
              style={styles.dashboardButton}
              textStyle={styles.headerButtonText}
              ignoreWidth
              onPress={() => navigation.navigate('PatientDashboardScreen')}
            >
              Back to Dashboard
            </Button>
          </View>
          <View style={styles.subContainer}>
            <View
              style={
                isMobile ? styles.mobileContainer : styles.desktopContainer
              }
            >
              <Text lora large fontWeight="500">
                Enter Email Associated With Account
              </Text>
              <Text style={[GlobalStyles.subheader, { paddingHorizontal: 0 }]}>
                We will email you a link to reset your password
              </Text>
              <Text units style={GlobalStyles.label} fontWeight={'700'}>
                EMAIL ADDRESS
              </Text>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: {
                    value: true,
                    message: 'Email address is required',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <TextInput
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      style={[
                        GlobalStyles.input,
                        styles.inputDesktop,
                        error && GlobalStyles.inputError,
                      ]}
                      keyboardType="email-address"
                      placeholder="example@email.com"
                      placeholderTextColor="grey"
                      testID="email"
                      accessible={true}
                      accessibilityLabel="email"
                    />

                    {error && (
                      <Text style={GlobalStyles.error}>{error.message}</Text>
                    )}
                  </>
                )}
              />
              <Button
                onPress={handleSubmit(onSendPress)}
                style={GlobalStyles.button}
                primary
                arrowRight
              >
                Reset Password
              </Button>
            </View>
          </View>
        </View>
      </ScreenWrapper>
    </View>
  );
};

export default ResetPasswordScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  subContainer: {
    paddingTop: 15,
    backgroundColor: '#fbf8f1',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flex: 2,
    paddingHorizontal: 15,
  },
  inputDesktop: {
    width: '100%',
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    borderWidth: 0,
  },
  desktopContainer: {
    width: 650,
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  mobileContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    borderRadius: 24,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 50,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  headerButtonText: { fontSize: 14, fontFamily: 'lato_400' },
  dashboardButton: {
    width: 192,
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 36,
    marginRight: 50,
    position: 'relative',
    top: -10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 15,
  },
});
