import React, { useState } from 'react';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ColorSchemeName } from 'react-native';
import { useQuery } from '@apollo/client';
import * as Sentry from 'sentry-expo';

import client, { SET_COLOR_CODES, SET_PARTNER_CONFIGS, SET_RECOMMENDATION_HEADER_COLORS, SET_REJECTION_REASONS } from 'kit-activation';
import { ALL_PARTNER_CONFIGS, ALL_COLOR_CODES, ALL_RECOMMENDATION_HEADER_COLORS, cmsClient, ALL_REJECTION_REASONS } from 'sample-collection-instructions';
import NotFoundScreen from 'screens/NotFoundScreen';
import { ColorCodeData, PartnerConfigData, RecommendationHeaderColorsData, RejectionReasonCmsData, RootStackParamList } from 'types';
import env from 'constants/Config';

import LoadingModal from 'components/LoadingModal';
import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';
import { checkKitIdParam } from 'screens/registration/EmailRegistrationScreen/utils';
import KitNavigator from './KitNavigator';
import LinkingConfiguration from './LinkingConfiguration';
import RegistrationNavigator from './RegistrationNavigator';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }): JSX.Element {
  const [configLoading, setConfigLoading] = useState<boolean>(true);
  const [colorCodesLoading, setColorCodesLoading] = useState<boolean>(true);
  const [recommendationHeaderColorsLoading, setRecommendationHeaderColorsLoading] = useState<boolean>(true);
  const [rejectionReasonsLoading, setRejectionReasonsLoading] = useState<boolean>(true);

  useQuery<RecommendationHeaderColorsData>(ALL_RECOMMENDATION_HEADER_COLORS, {
    client: cmsClient,
    onCompleted: ({ allRecommendationHeaderColors }) => {
      client.writeQuery({
        query: SET_RECOMMENDATION_HEADER_COLORS,
        data: { headerColors: allRecommendationHeaderColors },
      });
      setRecommendationHeaderColorsLoading(false);
    },
    onError: (err) => {
      setRecommendationHeaderColorsLoading(false);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'useQuery.ALL_RECOMMENDATION_HEADER_COLORS',
          message: `AllRecommendationHeaderColors retrieval on navigator building failed`,
        },
      });
      console.error(`An error occurred during AllRecommendationHeaderColors retrieval`, err);
    },
  });

  useQuery<ColorCodeData>(ALL_COLOR_CODES, {
    client: cmsClient,
    onCompleted: ({ allColorCodes }) => {
      const sortedColorCodes = [...allColorCodes].sort((a, b) => a.order - b.order);
      client.writeQuery({
        query: SET_COLOR_CODES,
        data: { colorCodes: sortedColorCodes },
      });
      setColorCodesLoading(false);
    },
    onError: (err) => {
      setColorCodesLoading(false);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'useQuery.ALL_COLOR_CODES',
          message: `AllColorCodes retrieval on navigator building failed`,
        },
      });
      console.error(`An error occurred during AllColorCodes retrieval`, err);
    },
  });

  useQuery<PartnerConfigData>(ALL_PARTNER_CONFIGS, {
    client: cmsClient,
    onCompleted: ({ allPartnerConfigs }) => {
      const matchingPartner = allPartnerConfigs.find((config) => config.key === env.PARTNER_NAME);
      client.writeQuery({
        query: SET_PARTNER_CONFIGS,
        data: { partnerConfig: matchingPartner },
      });
      setConfigLoading(false);
    },
    onError: (err) => {
      setConfigLoading(false);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'useQuery.ALL_PARTNER_CONFIGS',
          message: `AllPartnerConfig retrieval on navigator building failed`,
        },
      });
      console.error(`An error occurred during AllPartnerConfig retrieval`, err);
    },
  });

  useQuery<RejectionReasonCmsData>(ALL_REJECTION_REASONS, {
    client: cmsClient,
    onCompleted: ({ allRejectionReasons }) => {
      client.writeQuery({
        query: SET_REJECTION_REASONS,
        data: { rejectionReasons: allRejectionReasons },
      });
      setRejectionReasonsLoading(false);
    },
    onError: (err) => {
      setRejectionReasonsLoading(false);
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'useQuery.ALL_REJECTION_REASONS',
          message: `AllRejectionReasons retrieval on navigator building failed`,
        },
      });
      console.error(`An error occurred during AllRejectionReasons retrieval`, err);
    },
  });

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      documentTitle={{
        formatter: () => 'Patient Portal | imaware',
      }}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <RootNavigator
        configLoading={configLoading}
        colorCodesLoading={colorCodesLoading}
        recommendationHeaderColorsLoading={recommendationHeaderColorsLoading}
        rejectionReasonsLoading={rejectionReasonsLoading}
      />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator({
  configLoading = true,
  colorCodesLoading = true,
  recommendationHeaderColorsLoading = true,
  rejectionReasonsLoading = true,
}: {
  configLoading: boolean;
  colorCodesLoading: boolean;
  recommendationHeaderColorsLoading: boolean;
  rejectionReasonsLoading: boolean;
}) {
  const { isAuthenticated, isLoading } = useCheckOrRefreshAuth();

  if (isLoading || configLoading || colorCodesLoading || recommendationHeaderColorsLoading || rejectionReasonsLoading) {
    return <LoadingModal />;
  }

  checkKitIdParam();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={!isLoading && isAuthenticated ? 'KitActivation' : 'Registration'}>
      <Stack.Screen name="Registration" component={RegistrationNavigator} />
      <Stack.Screen name="KitActivation" component={KitNavigator} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}
