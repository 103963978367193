import * as React from 'react';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { StyleSheet, View, Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import '@expo/match-media';

import { Localization } from 'types';

import { BlurLayout, Layout } from './StyledView';
import Text from './StyledText';
import Button from './StyledButton';

const HeaderContainer = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <BlurLayout style={[styles.header, isMobile && styles.headerMobile]}>
      {children}
    </BlurLayout>
  );
};

const HeaderTitle = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  // Safe area component is broken on iOS, temporary hack
  if (Platform.OS === 'ios') {
    return (
      <Layout marginTop={'xl'} marginBottom={'small'}>
        <Text fontWeight={'500'} medium>
          {children}
        </Text>
      </Layout>
    );
  }
  if (isMobile) {
    return (
      <Layout marginBottom={'small'}>
        <Text fontWeight={'500'} medium>
          {children}
        </Text>
      </Layout>
    );
  }
  return (
    <Text fontWeight={'500'} medium>
      {children}
    </Text>
  );
};

const HeaderButton = ({
  children,
  primary,
  secondary,
  marginLeft,
  marginRight,
  onPress,
}: {
  children: string;
  primary?: boolean;
  secondary?: boolean;
  marginLeft?: string;
  marginRight?: string;
  onPress?: () => void;
}) => {
  const mobile450 = useMediaQuery({
    maxWidth: 450,
  });
  const mobile370 = useMediaQuery({
    maxWidth: 370,
  });
  if (mobile370) {
    return (
      <Layout marginBottom={primary && 'small'}>
        <Button
          medium
          primary={primary}
          secondary={secondary}
          onPress={onPress}
        >
          {children}
        </Button>
      </Layout>
    );
  }
  if (mobile450) {
    return (
      <Layout marginRight={marginRight} marginLeft={marginLeft}>
        <Button tiny primary={primary} secondary={secondary} onPress={onPress}>
          {children}
        </Button>
      </Layout>
    );
  }
  return (
    <Layout marginRight={marginRight} marginLeft={marginLeft}>
      <Button medium primary={primary} secondary={secondary} onPress={onPress}>
        {children}
      </Button>
    </Layout>
  );
};

const HeaderButtons = ({
  children,
  mobile370,
}: {
  children: React.ReactNode;
  mobile370: boolean;
}) => (
  <View
    style={[styles.headerButtons, mobile370 && { flexDirection: 'column' }]}
  >
    {children}
  </View>
);
export const Header = ({
  title,
  localization,
  navigation,
  onDownloadReport,
}: {
  title: string;
  localization: Localization;
  navigation: DrawerNavigationHelpers;
  onDownloadReport: () => void;
}): JSX.Element => {
  const mobile370 = useMediaQuery({
    maxWidth: 370,
  });
  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderButtons mobile370={mobile370}>
        <HeaderButton
          marginRight="small"
          primary
          onPress={() => navigation.navigate('PatientDashboardScreen')}
        >
          {localization?.headerBackToDashboard ?? 'Back to Dashboard'}
        </HeaderButton>
        <HeaderButton marginLeft="small" secondary onPress={onDownloadReport}>
          {localization?.headerDownloadPdfReport ??
            'Download PDF for Clinician'}
        </HeaderButton>
      </HeaderButtons>
    </HeaderContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: '#F0F1F2',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  headerMobile: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: 0,
  },
  headerButtons: {
    flexDirection: 'row',
  },
});
