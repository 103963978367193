import Button from 'components/StyledButton';
import Text from 'components/StyledText';
import styled from 'styled-components/native';
import { buttonColorMap } from '../utils';

interface IndicatorProps {
  testStatus:
    | 'Test activated'
    | 'Shipped'
    | 'Delivered'
    | 'Results unavailable'
    | 'Completed';
}

export default styled.View<{ isMobile: boolean }>`
  background: ${({ isMobile }) => (isMobile ? 'white' : '#f7f9fb')};
  border-radius: ${({ isMobile }) => (isMobile ? '16px' : '4px')};
  margin: 4px 0 4px 0;
  width: 100%;
`;

export const TestTitle = styled(Text)`
  margin-left: 25px;
`;

export const TestStatus = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  color: #f7f9fb;
`;

export const TestTopRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const TestBottomRow = styled.View<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '12px' : '20px')};
  align-items: center;
`;

export const TestRowButton = styled(Button)<{
  isMobile?: boolean;
}>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '200px')};
  margin-left: ${({ isMobile }) => (isMobile ? '0px' : '25px')};
  margin-right: ${({ isMobile }) => (isMobile ? '0px' : '17px')};
  display: flex;
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '5px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : '5px')};
  background-color: 'white';
  justify-content: center;
`;

export const TestStatusContainer = styled.View<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
`;

export const TestStatusIndicator = styled.View<IndicatorProps>`
  width: 5.5px;
  height: 5.5px;
  background-color: ${({ testStatus }) => buttonColorMap[testStatus]};
  border-radius: 50px;
  margin-left: 25px;
`;

export const TestText = styled(Text)`
  letter-spacing: 0.01px;
  color: #4c5862;
  font-size: 14px;
`;