import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Control, Controller } from 'react-hook-form';

import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import GlobalStyles from 'constants/Styles';
import { PersonalInfoRegistrationFields } from 'types';
import { MaskedTextInput } from 'react-native-mask-text';
import DateTimePicker from '../../../../../components/DateTimePicker';
import { validatingOldDate } from '../../utils';

const PatientInformation = ({ control }: { control?: Control<PersonalInfoRegistrationFields> }): JSX.Element => (
  <>
    <View style={styles.nameRow}>
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'firstName'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <View style={styles.marginField}>
            <Text style={styles.fieldLabel}>First Name</Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[GlobalStyles.input, styles.nameField, error && GlobalStyles.inputError]}
              testID={'firstName'}
              accessible={true}
            />
          </View>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'lastName'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <View style={styles.column}>
            <Text style={styles.fieldLabel}>Last Name</Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[GlobalStyles.input, styles.nameField, error && GlobalStyles.inputError]}
              testID={'lastName'}
              accessible={true}
            />
          </View>
        )}
      />
    </View>
    <View style={styles.row}>
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
          minLength: {
            value: 14,
            message: 'Phone number is not valid',
          },
        }}
        name={'phone'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <View style={styles.marginField}>
            <Text style={styles.fieldLabel}>Phone Number</Text>
            <MaskedTextInput
              mask="(999) 999-9999"
              value={value ?? ''}
              style={[GlobalStyles.input, error && GlobalStyles.inputError]}
              keyboardType="numeric"
              testID="inputPhone"
              onBlur={onBlur}
              onChangeText={onChange}
            />
          </View>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
          validate: validatingOldDate,
        }}
        name={'birthDate'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <View style={[styles.marginField]}>
            <Text style={styles.fieldLabel}>Date of Birth</Text>
            <DateTimePicker onChange={onChange} value={value ?? ''} onBlur={onBlur} testID={'birthDate'} accessible={true} error={error} />
          </View>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'gender'}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <View style={styles.column}>
            <Text style={styles.fieldLabel}>Sex Assigned at Birth</Text>
            <View style={styles.row}>
              <TouchableOpacity style={[styles.radioContainer]} testID="maleBtn" onPress={() => onChange('male')}>
                <View style={[styles.radioButton, error && GlobalStyles.inputError]}>
                  {value === 'male' ? <View style={styles.radioSelected} testID="radioMaleSelected" /> : null}
                </View>
                <Text style={[styles.maleLabel, error && styles.errorMessage]}>Male</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.radioContainer} onPress={() => onChange('female')}>
                <View style={[styles.radioButton, error && GlobalStyles.inputError]} testID="femaleBtn">
                  {value === 'female' ? <View style={styles.radioSelected} /> : null}
                </View>
                <Text style={[styles.femaleLabel, error && styles.errorMessage]}>Female</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      />
    </View>
  </>
);

const styles = StyleSheet.create({
  fieldLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 0.8,
    color: '#2F2F2F',
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nameField: {
    width: 275,
  },
  phoneField: {
    width: 175,
  },
  birthDateField: {
    width: 180,
  },
  marginField: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 26,
  },
  radioButton: {
    height: 16,
    width: 16,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioSelected: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: '#000',
  },
  maleLabel: {
    marginLeft: 7,
    marginRight: 30,
    fontSize: 14,
  },
  femaleLabel: { marginLeft: 7, fontSize: 14 },
  errorMessage: {
    color: '#DC412C',
  },
});

export default PatientInformation;
