import React from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';

const RadioButton = ({
  style,
  selected,
  testID,
}: {
  style?: ViewProps;
  selected: boolean;
  testID?: string;
}): JSX.Element => (
  <View style={[styles.radioButton, style]} testID={testID}>
    {selected ? (
      <View
        style={{
          height: 16,
          width: 16,
          borderRadius: 12,
          backgroundColor: '#000',
        }}
      />
    ) : null}
  </View>
);

export default RadioButton;

const styles = StyleSheet.create({
  radioButton: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 3,
  },
});
