import React from 'react';
import { StyleSheet, View, Linking, Pressable } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Text from './StyledText';
import client, { GET_PARTNER_CONFIG } from '../kit-activation';
import { PartnerConfig } from '../types';

const Footer = ({ miniFooter }: { miniFooter?: boolean }): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  const partnerEmail = partnerConfig?.contactEmail;
  return (
    <View style={!miniFooter ? styles.container : null}>
      {!miniFooter ? (
        <>
          <View style={isMobile ? styles.ctaMobile : styles.cta}>
            {(partnerConfig?.navigationItems?.footer?.linksAboveEmail || []).map(({ name, link }) => (
              <Pressable key={name} onPress={() => (isMobile ? Linking.openURL(link) : window.open(link, '_blank'))}>
                <Text small style={styles.footerName}>
                  {name}
                </Text>
              </Pressable>
            ))}
          </View>
          {partnerEmail && (
            <Pressable onPress={() => Linking.openURL(`mailto:${partnerEmail}`)}>
              <Text small style={styles.email}>
                {partnerEmail}
              </Text>
            </Pressable>
          )}
        </>
      ) : null}
      <View style={styles.termsContainer}>
        <View style={styles.terms}>
          {(partnerConfig?.navigationItems?.footer?.linksBelowEmail || []).map(({ name, link }) => (
            <Pressable key={name} onPress={() => (isMobile ? Linking.openURL(link) : window.open(link, '_blank'))}>
              <Text tiny style={styles.footerText}>
                {name}
              </Text>
            </Pressable>
          ))}
        </View>
        <Text tiny style={styles.copyright}>
          Copyright @ {new Date().getFullYear()} {partnerConfig?.navigationItems?.footer?.copyright || 'imaware™ All Rights Reserved'}
        </Text>
      </View>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    height: 180,
    position: 'relative',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
    shadowColor: 'rgba(232, 238, 243, 0.8)',
    shadowOffset: { width: 0, height: -3 },
    shadowRadius: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    width: 150,
    aspectRatio: 5,
    height: 88,
    resizeMode: 'contain',
  },
  logoMobile: {
    width: 75,
    aspectRatio: 4,
    height: 44,
    resizeMode: 'contain',
    marginTop: 15,
    marginBottom: 15,
  },
  ctaMobile: {
    maxWidth: 250,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  cta: {
    minWidth: 540,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  termsContainer: {
    borderTopColor: '#F0F1F2',
    borderTopWidth: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    backgroundColor: 'white',
  },
  terms: {
    minWidth: 180,
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 8,
  },
  email: {
    textDecorationLine: 'underline',
    paddingTop: 12,
    paddingBottom: 20,
  },
  copyright: {
    color: 'rgba(0,0,0,0.7)',
  },
  footerName: { paddingBottom: 12 },
  footerText: { fontSize: 12 },
});
