import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import EmailRegistrationScreen from 'screens/registration/EmailRegistrationScreen';
import EmailVerificationScreen from 'screens/registration/EmailVerificationScreen';
import LoginScreen from 'screens/login/LoginScreen';
import CompletePatientProfileScreen from 'screens/registration/CompletePatientProfileScreen';
import ResetPasswordScreen from 'screens/login/ResetPasswordScreen';

const RegistrationStack = createStackNavigator();

const RegistrationNavigator = (): JSX.Element => (
  <RegistrationStack.Navigator
    headerMode="none"
    initialRouteName="EmailRegistrationScreen"
  >
    <RegistrationStack.Screen
      name="EmailRegistrationScreen"
      component={EmailRegistrationScreen}
    />
    <RegistrationStack.Screen
      name="EmailVerificationScreen"
      component={EmailVerificationScreen}
    />
    <RegistrationStack.Screen
      name="CompletePatientProfileScreen"
      component={CompletePatientProfileScreen}
    />
    <RegistrationStack.Screen name="LoginScreen" component={LoginScreen} />
    <RegistrationStack.Screen
      name="ResetPasswordScreen"
      component={ResetPasswordScreen}
    />
  </RegistrationStack.Navigator>
);

export default RegistrationNavigator;
