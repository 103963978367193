import React, { useEffect } from 'react';
import { View, Alert } from 'react-native';
import { ApolloError } from '@apollo/client';

import GlobalStyles from 'constants/Styles';
import Text from './StyledText';
import StyledView from './styles/StyledView';

const ErrorModal = ({
  error,
}: {
  error: Error | ApolloError | null;
}): JSX.Element => {
  useEffect(() => {
    Alert.alert(error?.message ?? '');
  }, [error]);

  return (
    <View style={GlobalStyles.container}>
      <StyledView>
        <Text fontWeight={'400'} large>
          An error has occurred
        </Text>
      </StyledView>
    </View>
  );
};

export default ErrorModal;
