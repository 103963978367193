import { gql } from '@apollo/client';

export const GET_PATIENT_TEST_HISTORY = gql`
  query GetPatientTestHistory {
    getPatientTestHistory {
      testType
      kitId
      carePlanId
      activationDate
      trackingPatient {
        carrier
        trackingNumber
      }
      trackingLab {
        carrier
        trackingNumber
      }
      kitStatus {
        status
        timestamp
        rejectionReasons {
          code
          message
        }
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query GetPatient {
    getPatient {
      id
      email_verified
      gender
      birthDate
      name {
        family
        given
      }
      address {
        city
        state
        postalCode
        country
        line
      }
      telecom {
        system
        value
      }
    }
  }
`;
