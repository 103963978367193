import Button from 'components/StyledButton';
import styled from 'styled-components/native';

export const ContinueButton = styled(Button)<{ isMobile: boolean }>`
  display: flex;
  height: ${(row) => (row.isMobile ? '100px' : '44px')};
  width: ${(row) => (row.isMobile ? 'auto' : '232px')};
  justify-content: center;
`;

export const Box = styled.View<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 'auto' : '470px')};
  margin: ${({ isMobile }) => (isMobile ? '0 21px' : 'auto')};
`;
