import { Platform } from 'react-native';

export const checkKitIdParam = () => {
  if (Platform.OS === 'web') {
    const searchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(searchParams.entries());
    const kitIdFromParams = params.kitid;
    const getKitIdFromStorage = localStorage.getItem('kitid');
    const kitIdNotStoredLocally =
      getKitIdFromStorage === null ||
      getKitIdFromStorage === undefined ||
      getKitIdFromStorage === 'undefined';

    if (
      kitIdFromParams !== null &&
      kitIdFromParams !== undefined &&
      kitIdNotStoredLocally
    ) {
      localStorage.setItem('kitid', kitIdFromParams);
      return true;
    }
  } else {
    return false;
  }
  return false;
};
