import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useWindowDimensions, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import OverviewScreen from 'screens/report/OverviewScreen';
import ResultsScreen from 'screens/report/ResultsScreen';
import DetailsScreen from 'screens/report/DetailsScreen';
import RecommendationsScreen from 'screens/report/RecommendationsScreen';
import ResourcesScreen from 'screens/report/ResourcesScreen';
import { PortalHeader } from 'components/ReportCommon';
import { Layout } from 'components/StyledView';
import { cmsClient, ReportLocalization, REPORT_LOCALIZATION } from 'sample-collection-instructions';
import { RouteProp } from '@react-navigation/native';

const ReportStack = createStackNavigator();

export function ReportNavigatorStack({ navigation, route }: { navigation: DrawerNavigationHelpers; route: RouteProp<{ params?: { kitId?: string } }, 'params'> }): JSX.Element {
  const result = useQuery(REPORT_LOCALIZATION('en'), { client: cmsClient });
  const { data = {} } = result;
  const { reportLocalization: localization } = data;
  const nav = navigation as DrawerNavigationHelpers;
  return (
    <ReportLocalization.Provider value={localization}>
      <ReportStack.Navigator
        headerMode="none" // change to 'screen' when ready to show
        screenOptions={{
          headerTitle: () => <PortalHeader />,
          headerLeft: () => <DrawerToggler navigation={nav} />,
          headerStyle: styles.portalHeader,
        }}
      >
        <ReportStack.Screen name="OverviewScreen">{(props) => <OverviewScreen {...props} navigation={navigation} route={route} />}</ReportStack.Screen>

        <ReportStack.Screen name="ResultsScreen">{(props) => <ResultsScreen {...props} navigation={navigation} route={route} />}</ReportStack.Screen>

        <ReportStack.Screen name="DetailsScreen">{(props) => <DetailsScreen {...props} navigation={navigation} route={route} />}</ReportStack.Screen>

        <ReportStack.Screen name="RecommendationsScreen">{(props) => <RecommendationsScreen {...props} navigation={navigation} route={route} />}</ReportStack.Screen>

        <ReportStack.Screen name="ResourcesScreen">{(props) => <ResourcesScreen {...props} navigation={navigation} route={route} />}</ReportStack.Screen>
      </ReportStack.Navigator>
    </ReportLocalization.Provider>
  );
}

const DrawerToggler = ({ navigation }: { navigation: DrawerNavigationHelpers }) => {
  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions.width >= 780;
  return (
    <Layout paddingLeft="regular">
      {!isMediumScreen ? (
        <TouchableOpacity onPress={() => navigation.toggleDrawer()}>
          <Ionicons name="menu" size={30} color="black" />
        </TouchableOpacity>
      ) : null}
    </Layout>
  );
};

const styles = StyleSheet.create({
  portalHeader: {
    borderBottomWidth: 0,
    height: 75,
    shadowColor: '#E8EEF3',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  navDrawer: { display: 'flex', height: 99 },
  navHeaderContainer: { flex: 1, minHeight: 100 },

  navHeader: {
    borderBottomColor: 'rgba(151, 151, 151, 0.2)',
    borderBottomWidth: 1,
    marginHorizontal: 25,
    paddingBottom: 30,
    paddingTop: 15,
    letterSpacing: 1.5,
  },
  navContainer: {
    flex: 7,
    minHeight: 250,
  },
  navItemContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  navIndicatorFocused: {
    borderWidth: 3,
    borderColor: 'black',
    borderRadius: 5,
    marginLeft: 2,
  },
  navIndicator: {
    borderWidth: 3,
    borderColor: 'white',
    borderRadius: 5,
    marginLeft: 2,
  },
  navBrandTrademark: { lineHeight: 22, textAlignVertical: 'top' },
  navButtonContainer: { flex: 1, justifyContent: 'center' },
  navButton: { paddingHorizontal: 70, marginHorizontal: 'auto' },
});
