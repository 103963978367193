import React from 'react';
import { Alert, View, StyleSheet, Image } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { Entypo } from '@expo/vector-icons';

import client from 'kit-activation';
import { logoutUser } from 'auth/auth0';
import Text from 'components/StyledText';
import { PartnerConfig } from 'types';

import { LogoutButton, SideNav, SideNavElement } from './styles';

const DesktopNavigation = ({
  navigation,
  componentLabel,
  partnerConfig,
}: {
  navigation: StackNavigationHelpers | DrawerNavigationHelpers;
  componentLabel: string;
  partnerConfig?: PartnerConfig;
}) => {
  const onLogoutPress = () =>
    client
      .clearStore()
      .then(() => logoutUser())
      .catch((err) => Alert.alert(err.message));

  const renderDesktopSubMenu = () =>
    partnerConfig?.navigationItems.items.map((props) => {
      const { component, name, image, isImage, isComponent, url } = props;
      const activeComponent = componentLabel === component;
      if (isComponent) {
        return (
          <SideNavElement
            active={activeComponent}
            onPress={() => navigation.navigate(`${component}`)}
            key={name}
            partnerConfig={partnerConfig}
          >
            {activeComponent ? (
              <View
                style={[
                  styles.activeIndicator,
                  { backgroundColor: partnerConfig?.theme.primaryColor },
                ]}
              />
            ) : null}
            {isImage ? (
              <Image style={styles.homeIcon} source={image} />
            ) : (
              <Entypo
                style={styles.passwordIcon}
                name={image?.name}
                size={24}
                color={partnerConfig.theme.primaryColor}
              />
            )}
            <Text lato style={styles.bold}>
              {name}
            </Text>
          </SideNavElement>
        );
      } else {
        return (
          <SideNavElement onPress={() => window.open(url, '_blank')} key={name}>
            <Entypo
              style={styles.passwordIcon}
              name={image?.name}
              size={24}
              color={partnerConfig.theme.primaryColor}
            />
            <Text lato style={styles.bold}>
              {name}
            </Text>
          </SideNavElement>
        );
      }
    });

  return (
    <View>
      <View style={styles.sideNavContainer}>
        <SideNav style={styles.sideNav}>
          <View style={styles.autoHeight}>
            <Image
              style={[styles.imawareLogo, partnerConfig?.theme.logoDimensions]}
              source={
                partnerConfig?.theme.logo || require('assets/images/logo.png')
              }
            />
            {renderDesktopSubMenu()}
          </View>
          <LogoutButton onPress={onLogoutPress}>Logout</LogoutButton>
        </SideNav>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bold: { fontWeight: '400', fontSize: 14 },
  autoHeight: { height: 'auto' },
  sideNavContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  sideNav: { display: 'flex', justifyContent: 'space-between' },
  imawareLogo: {
    width: 140,
    height: 40,
    resizeMode: 'contain',
    marginLeft: 25,
    marginBottom: 68,
  },
  activeIndicator: {
    height: 44,
    width: 6,
    marginRight: 22,
  },
  homeIcon: {
    width: 21,
    height: 23,
    marginRight: 20,
  },
  testsIcon: {
    width: 18,
    height: 22,
    marginRight: 20,
  },
  passwordIcon: {
    width: 17,
    height: 23,
    marginRight: 20,
  },
  helpIcon: {
    width: 18,
    height: 18,
    marginRight: 20,
  },
});

export default DesktopNavigation;
