import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

const PasswordInformation = ({
  navigation,
}: {
  navigation: StackNavigationHelpers;
}) => (
  <View style={styles.container}>
    <Text style={styles.title}>Password</Text>
    <TouchableOpacity
      onPress={() => navigation.navigate('ResetPasswordScreen')}
      testID="changePasswordBtn"
    >
      <Text style={styles.passwordText}>Change Password</Text>
    </TouchableOpacity>
  </View>
);

export default PasswordInformation;

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    width: '100%',
  },
  title: {
    fontWeight: '600',
    fontFamily: 'lora_500',
    fontSize: 18,
    paddingBottom: 15,
  },
  passwordText: {
    color: '#0000ffc2',
  },
});
