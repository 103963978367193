import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';

import Button from 'components/StyledButton';

import { HeaderBarContainerStyle, LayoutProps, PartnerConfig } from 'types';
import client, { GET_PARTNER_CONFIG } from 'kit-activation';

const HeaderBar = ({
  navigation,
  buttonStyles,
  withButtons,
  containerBackgroundColor = { backgroundColor: 'white' },
}: {
  navigation: StackNavigationHelpers;
  buttonStyles?: LayoutProps;
  withButtons?: boolean;
  containerBackgroundColor?: HeaderBarContainerStyle;
}) => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  return (
    <View
      style={[
        withButtons ? styles.headerBar : styles.headerNoBtn,
        containerBackgroundColor,
      ]}
    >
      <Image
        style={[
          styles.imawareLogo,
          partnerConfig?.theme.logoDimensions,
          withButtons ? styles.headerBar : styles.noBtn,
        ]}
        source={
          partnerConfig
            ? partnerConfig.theme.logo
            : require('assets/images/logo.png')
        }
      />
      {withButtons ? (
        <Button
          style={styles.dashboardButton}
          textStyle={styles.headerButtonText}
          ignoreWidth
          {...buttonStyles}
          onPress={() => navigation.navigate('PatientDashboardScreen')}
        >
          Back to Dashboard
        </Button>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  imawareLogo: {
    width: 120,
    aspectRatio: 5,
    height: 21,
    resizeMode: 'contain',
  },
  headerButtonText: { fontSize: 14, fontFamily: 'lato_400' },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
  },
  headerNoBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 60,
  },
  noBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  dashboardButton: {
    width: 192,
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    height: 36,
    marginTop: 0,
    marginRight: 50,
  },
});

export default HeaderBar;
