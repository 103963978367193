import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { useMediaQuery } from 'react-responsive';
import GlobalStyles from '../constants/Styles';

interface AddressModalProps {
  isVisible: boolean;
  addressString: string | null;
  onClose: () => void;
  onContinue: () => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ isVisible, addressString, onClose, onContinue }) => {
  const handleContinue = () => {
    onClose();
    onContinue();
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return (
    <Modal testID="addressModal" isVisible={isVisible} onBackdropPress={onClose} style={styles.modal}>
      <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
        <Text style={styles.modalText}>We weren't able to validate the address you provided:</Text>
        {addressString && <Text style={[styles.modalErrorDescription, { fontStyle: 'italic' }]}>{addressString}</Text>}
        <Text style={styles.modalInstructions}>Please double check that you inputted this address correctly before continuing.</Text>
        <View>
          <View style={styles.buttonsContainer}>
            {!isMobile && (
              <>
                <TouchableOpacity style={[styles.button, { marginRight: 10 }]} onPress={onClose}>
                  <Text style={styles.buttonText}>Edit Address</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, styles.disabledButton, { marginLeft: 10 }]} onPress={handleContinue}>
                  <Text style={styles.buttonText}>Continue With This Address</Text>
                </TouchableOpacity>
              </>
            )}
            {isMobile && (
              <>
                <TouchableOpacity style={[styles.button, { marginRight: 10, padding: 5, justifyContent: 'center', alignItems: 'center' }]} onPress={onClose}>
                  <Text style={styles.buttonText}>Edit Address</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.button, styles.disabledButton, { marginLeft: 10, padding: 5, justifyContent: 'center', alignItems: 'center' }]}
                  onPress={handleContinue}
                >
                  <Text style={styles.buttonText}>Continue With This Address</Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalText: {
    fontFamily: 'lato_400',
    fontSize: 16,
  },
  modalErrorDescription: {
    fontFamily: 'lato_400',
    fontSize: 16,
    marginVertical: 20,
  },
  modalInstructions: {
    fontFamily: 'lato_400',
    fontSize: 16,
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 20,
  },
  button: {
    flex: 1,
    backgroundColor: '#000',
    borderRadius: 50,
    color: '#fff',
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    paddingHorizontal: 30,
    paddingVertical: 15,
  },
  buttonText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disabledButton: {
    opacity: 0.5,
  },
  mobileContainer: {
    ...GlobalStyles.container,
    height: 'auto',
    backgroundColor: 'white',
    padding: 20,
  },
  desktopContainer: {
    width: 700,
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 40,
    borderRadius: 24,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
    paddingLeft: 68,
    paddingRight: 62,
    paddingTop: 42,
    paddingBottom: 47,
  },
  bottomContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
});

export default AddressModal;
