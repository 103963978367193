import { Biomarker, BiomarkerCode, Result, Sex, CmsColorCode, Segment } from 'types';
import { getColorCodeIndexWithDevConfigBoolean, getColorCodes } from '../utils/content';

export const calculateReportCode = (results: Result[], biomarkers: Biomarker[], sex: Sex): CmsColorCode => {
  const reportCodes = getColorCodes();
  let codeIndex = 0; // start at lowest code
  biomarkers.forEach((biomarker) => {
    const result = results.find(({ key }) => key === biomarker.developerConfig.key);
    if (result) {
      const { ranges } = biomarker;
      const sexSpecificRanges = ranges[sex] || ranges;
      const { reportable_range, segments, boolean } = sexSpecificRanges;
      const qualitativeBoolean = typeof reportable_range[0] === 'string';
      let value = boolean || qualitativeBoolean ? result.qualitativeResult ?? '' : result.quantitativeResult ?? 0;
      const lowercasedValue = value.toString().toLowerCase();
      if (qualitativeBoolean) {
        // eslint-disable-next-line no-unused-expressions
        lowercasedValue === 'positive' ? 'Positive' : 'Negative';
      } else if (value > reportable_range?.[1]) {
        value = reportable_range?.[1];
      } else if (value < reportable_range?.[0]) {
        value = reportable_range?.[0];
      }

      if (boolean) {
        if (value && codeIndex < 3) {
          codeIndex = getColorCodeIndexWithDevConfigBoolean('useForPositiveValues', true); // positive value will be RED
        }
      } else {
        const segment = segments.find((segment) => {
          let lowerBoundCondition;
          let upperBoundCondition;
          if (segment.lowerBoundInclusive) {
            lowerBoundCondition = value >= segment.range[0];
          } else {
            lowerBoundCondition = value > segment.range[0];
          }
          if (segment.upperBoundInclusive) {
            upperBoundCondition = value <= segment.range[1];
          } else {
            upperBoundCondition = value < segment.range[1];
          }
          return lowerBoundCondition && upperBoundCondition;
        });
        if (segment) {
          const matchedCodeIndex = reportCodes.findIndex((reportCode) => reportCode.color === segment.code);
          if (codeIndex < matchedCodeIndex) {
            codeIndex = matchedCodeIndex;
          }
        }
        if (qualitativeBoolean && (lowercasedValue === 'positive' || lowercasedValue === 'negative')) {
          codeIndex = reportCodes.length - 1;
        }
      }
    }
  });
  return reportCodes[codeIndex];
};

export const calculateBiomarkerCode = (results: Result[], biomarkers: Biomarker[], sex: Sex): BiomarkerCode[] => {
  const biomarkerStatuses: BiomarkerCode[] = [];

  biomarkers.forEach((biomarker) => {
    const result = results.find(({ key }) => key === biomarker.developerConfig.key);
    if (result) {
      const { ranges } = biomarker;
      const sexSpecificRanges = ranges[sex] || ranges;
      const { segments, reportable_range } = sexSpecificRanges;
      const qualitativeBoolean = typeof reportable_range[0] === 'string';
      const value = qualitativeBoolean ? result.qualitativeResult ?? '0' : result.quantitativeResult ?? 0;
      let segmentToConsider: Segment | undefined;

      if (value > reportable_range[1]) {
        segmentToConsider = segments[segments.length - 1];
      } else if (value < reportable_range[0]) {
        segmentToConsider = segments[0];
      } else {
        const segment = segments.find((segment) => {
          let lowerBoundCondition;
          let upperBoundCondition;
          if (segment.lowerBoundInclusive) {
            lowerBoundCondition = value >= segment.range[0];
          } else {
            lowerBoundCondition = value > segment.range[0];
          }
          if (segment.upperBoundInclusive) {
            upperBoundCondition = value <= segment.range[1];
          } else {
            upperBoundCondition = value < segment.range[1];
          }
          return lowerBoundCondition && upperBoundCondition;
        });

        segmentToConsider = segment;
      }

      biomarkerStatuses.push({ key: result.key, biomarkerDevConfig: biomarker.developerConfig, ...segmentToConsider });
    }
  });
  return biomarkerStatuses;
};
