import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import DrawerNavigator from './DrawerNavigator';

const KitStack = createStackNavigator();

const KitNavigator = (): JSX.Element => (
  <KitStack.Navigator headerMode="none">
    <KitStack.Screen name="Drawer" component={DrawerNavigator} />
  </KitStack.Navigator>
);

export default KitNavigator;
