 
import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Pressable, Image } from 'react-native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import * as Linking from 'expo-linking';
import { FontAwesome } from '@expo/vector-icons';
import axios, { AxiosError } from 'axios';
import * as WebBrowser from 'expo-web-browser';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from 'sentry-expo';
import { TouchableOpacity } from 'react-native-gesture-handler';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import Button from 'components/StyledButton';
import Checkbox from 'components/Checkbox';
import GlobalStyles from 'constants/Styles';
import env from 'constants/Config';
import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import { checkKitIdParam } from 'screens/registration/EmailRegistrationScreen/utils';
import { EmailRegistrationFields, PartnerConfig, UserRegistrationBody, UserRegistrationResponse } from 'types';

import { sendAccountAlreadyExistsEmail } from '../../../klaviyo/klaviyo';
import { ACCOUNT_EXISTS } from '../../../klaviyo/types';

WebBrowser.maybeCompleteAuthSession();

const EmailRegistrationScreen = ({ navigation }: { navigation: StackNavigationHelpers }): JSX.Element => {
  const { control, watch, handleSubmit } = useForm<EmailRegistrationFields>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      acceptedTerms: false,
    },
  });

  const [passwordShowing, setPasswordShowing] = useState(false);
  const [confirmPassShowing, setConfirmPassShowing] = useState(false);

  // eslint-disable-next-line max-len
  const getAcceptanceTermLink = (type: string, defaultLink: string, partnerConfig?: PartnerConfig) => partnerConfig?.navigationItems?.acceptanceTerms?.find((term) => term.type === type)?.link || defaultLink;

  useEffect(() => {
    checkKitIdParam();
  }, []);

  const password = useRef({});
  password.current = watch('password', '');

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  const termOfUse = () => {
    if (isMobile) {
      Linking.openURL(getAcceptanceTermLink('terms-of-use', 'https://www.imaware.health/terms-of-use', partnerConfig));
    } else {
      window.open(getAcceptanceTermLink('terms-of-use', 'https://www.imaware.health/terms-of-use', partnerConfig), '_blank');
    }
  };

  const privacyPolicy = () => {
    if (isMobile) {
      Linking.openURL(getAcceptanceTermLink('privacy-policy', 'https://www.imaware.health/privacy-policy', partnerConfig));
    } else {
      window.open(getAcceptanceTermLink('privacy-policy', 'https://www.imaware.health/privacy-policy', partnerConfig), '_blank');
    }
  };

  const consentAgreement = () => {
    if (isMobile) {
      Linking.openURL(getAcceptanceTermLink('informed-consent', 'https://www.imaware.health/informed-consent', partnerConfig));
    } else {
      window.open(getAcceptanceTermLink('informed-consent', 'https://www.imaware.health/informed-consent', partnerConfig), '_blank');
    }
  };

  const onSubmit: SubmitHandler<EmailRegistrationFields> = async ({ email, password: pw }) => {
    try {
      await axios.request<UserRegistrationBody, UserRegistrationResponse>({
        method: 'POST',
        url: `${env.AUTH0_DOMAIN}/dbconnections/signup`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          email,
          password: pw,
          connection: env.AUTH0_CONNECTION,
          client_id: env.AUTH0_CLIENT_ID,
        },
      });
    } catch (err) {
      const isAxiosErr = axios.isAxiosError(err);
      if (!isAxiosErr) {
        Sentry.Browser.captureException(err, {
          extra: {
            event: 'Auth0 Signup',
            message: `Auth0 account creation failed`,
          },
          user: {
            email,
          },
        });
      } else {
        const axiosErr = err as AxiosError<ErrAuth0>;
        if (axiosErr?.response?.data.code === 'invalid_signup') {
          await sendAccountAlreadyExistsEmail({
            event: ACCOUNT_EXISTS,
            email,
            partner: window?.config?.PARTNER_NAME || undefined,
            firstName: undefined,
            lastName: undefined,
          });
        }
      }
    }

    navigation.navigate('EmailVerificationScreen', { email });
  };

  return (
    <ScreenWrapper miniFooter excludeFooter={isMobile} style={styles.screenWrapper}>
      <View style={isMobile ? styles.mobileHeaderBar : styles.headerBar}>
        <Image style={[styles.imawareLogo, partnerConfig?.theme.logoDimensions]} source={{ uri: partnerConfig?.theme.logo ?? '' }} />
      </View>
      <View style={styles.subContainer}>
        {isMobile ? <Image style={styles.mobileCircleGraphic} source={require('assets/images/mobile-registration-1-icon.png')} /> : null}
        <View style={isMobile ? styles.mobileContainer : styles.desktopContainer}>
          <Text large lora fontWeight="500" style={isMobile ? styles.headerMobile : null}>
            Create Account
          </Text>
          <Text lato style={[GlobalStyles.subheader, isMobile ? styles.subHeaderMobile : styles.noPadding]}>
            Log in to our secure patient portal and get started.
          </Text>
          <View style={{ width: '100%' }}>
            <View style={isMobile ? styles.boxMobile : styles.boxDesktop}>
              <View style={!isMobile ? [styles.subBoxDesktop, styles.noMargin] : null}>
                <Text units lato style={GlobalStyles.label} fontWeight={'700'}>
                  EMAIL ADDRESS
                </Text>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: {
                      value: true,
                      message: 'A valid email address is required',
                    },
                    validate: (value) => !!value.trim(),
                    minLength: {
                      value: 3,
                      message: 'Email address must have at least 3 characters',
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/i,
                      message: 'Email address must be in the format of email@example.com',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={styles.inputContainer}>
                      <TextInput
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={[GlobalStyles.input, !isMobile && styles.inputDesktop, error && GlobalStyles.inputError]}
                        keyboardType="email-address"
                        placeholder="example@email.com"
                        placeholderTextColor="grey"
                        testID="email"
                        accessible={true}
                        accessibilityLabel="email"
                      />
                      {error ? (
                        <Text lato style={GlobalStyles.error} units>
                          {error.message}
                        </Text>
                      ) : null}
                    </View>
                  )}
                />
              </View>
            </View>
            <View style={isMobile ? styles.boxMobile : styles.boxDesktop}>
              <View style={!isMobile ? [styles.subBoxDesktop, styles.noMargin] : null}>
                <Text units lato style={GlobalStyles.label} fontWeight={'700'}>
                  PASSWORD
                </Text>
                <Controller
                  control={control}
                  name="password"
                  rules={{
                    required: 'You must specify a password',
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/,
                      message: 'Password must be at least 8 characters, contain 1 uppercase letter, 1 lowercase letter, and 1 number',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <>
                      <View style={[styles.inputContainer, { marginBottom: 0 }]}>
                        <TextInput
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          keyboardType="visible-password"
                          secureTextEntry={!passwordShowing}
                          style={[GlobalStyles.input, !isMobile && styles.inputDesktop, error && GlobalStyles.inputError]}
                          testID="password-field"
                          accessible={true}
                          accessibilityLabel="password-field"
                        />
                        {passwordShowing ? (
                          <FontAwesome name="eye" size={24} color="black" style={styles.eye} onPress={() => setPasswordShowing(!passwordShowing)} />
                        ) : (
                          <FontAwesome name="eye-slash" size={24} color="black" style={styles.eye} onPress={() => setPasswordShowing(!passwordShowing)} />
                        )}
                        {error ? (
                          <Text lato style={[GlobalStyles.error, styles.errorMessage]} units>
                            {error.message}
                          </Text>
                        ) : null}
                      </View>
                    </>
                  )}
                />
              </View>
              <View style={!isMobile ? styles.subBoxDesktop : null}>
                <Text units lato style={GlobalStyles.label} fontWeight={'700'}>
                  CONFIRM PASSWORD
                </Text>
                <Controller
                  control={control}
                  name="passwordConfirm"
                  rules={{
                    validate: (value) => value === password.current,
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <>
                      <View style={[styles.inputContainer, { marginBottom: 0 }]}>
                        <TextInput
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          keyboardType="visible-password"
                          secureTextEntry={!confirmPassShowing}
                          style={[GlobalStyles.input, !isMobile && styles.inputDesktop, error && GlobalStyles.inputError]}
                          testID="password-confirm-field"
                          accessible={true}
                          accessibilityLabel="password-confirm-field"
                        />
                        {confirmPassShowing ? (
                          <FontAwesome name="eye" size={24} color="black" style={styles.eye} onPress={() => setConfirmPassShowing(!confirmPassShowing)} />
                        ) : (
                          <FontAwesome name="eye-slash" size={24} color="black" style={styles.eye} onPress={() => setConfirmPassShowing(!confirmPassShowing)} />
                        )}
                        {error?.type === 'validate' && (
                          <Text lato style={[GlobalStyles.error, styles.errorMessage]} units>
                            Provided passwords do not match
                          </Text>
                        )}
                      </View>
                    </>
                  )}
                />
              </View>
            </View>
            <Text units lato fontWeight={'400'} style={isMobile ? styles.passwordRequirementTextMobile : styles.passwordRequirementText}>
              *Password must be at least 8 characters, contain 1 uppercase letter, 1 lowercase letter, and 1 number
            </Text>
          </View>
          <View style={[styles.termsContainer, styles.noPadding]}>
            <Controller
              name="acceptedTerms"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <View style={styles.termsCheckboxContainer}>
                    <Checkbox checked={value} onChange={onChange} testID={'checkboxTerm'} accessibilityLabel={'checkboxTerm'} style={styles.termsCheckbox} />
                    <View style={styles.termsText}>
                      <Text label>I have read and accept the </Text>
                      <Pressable onPress={termOfUse}>
                        <Text label style={styles.link}>
                          Terms of Use
                        </Text>
                      </Pressable>
                      <Text label>, </Text>
                      <Pressable onPress={privacyPolicy}>
                        <Text label style={styles.link}>
                          Privacy Policy
                        </Text>
                      </Pressable>
                      <Text label>, and </Text>
                      <Pressable onPress={consentAgreement}>
                        <Text label style={styles.link}>
                          Informed Consent.
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                  {error ? (
                    <Text lato style={GlobalStyles.error} units>
                      Please accept the terms and conditions
                    </Text>
                  ) : null}
                </>
              )}
            />
          </View>
          <View style={styles.btnBox}>
            <View>
              <Button
                onPress={handleSubmit(onSubmit)}
                primary
                bold
                testID={'sign-up-button'}
                accessible={true}
                accessibilityLabel={'signUpButton'}
                style={isMobile ? styles.createAccountButtonMobile : styles.createAccountButton}
                textStyle={{ fontFamily: 'lato_700' }}
              >
                Create account
              </Button>
            </View>

            <View style={[styles.haveAccountBox, isMobile ? styles.haveAccountBoxMobile : null]}>
              <Text>Already have an account? </Text>
              <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')}>
                <Text style={styles.link} fontWeight={'700'}>
                  Log In
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {isMobile ? <Image style={styles.mobileSquareGraphic} source={require('assets/images/mobile-registration-2-icon.png')} /> : null}
        {!isMobile ? <Image style={styles.desktopGraphic} source={require('assets/images/registration-screen-graphic.png')} /> : null}
      </View>
    </ScreenWrapper>
  );
};

export default EmailRegistrationScreen;

const styles = StyleSheet.create({
  inputContainer: {
    marginBottom: 12,
    width: '100%',
  },
  imawareLogo: {
    width: 160,
    height: 40,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  eye: {
    position: 'absolute',
    left: '88%',
    top: 18,
  },
  link: {
    textDecorationLine: 'underline',
  },
  termsContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 15,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  termsText: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 13,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  subContainer: {
    paddingTop: 15,
    display: 'flex',
    width: '100%',
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  boxMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  boxDesktop: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  subBoxDesktop: {
    flex: 1,
    marginHorizontal: 10,
  },
  subBoxMobile: {
    flex: 1,
    width: '100%',
  },
  inputDesktop: {
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    borderWidth: 0,
  },
  centerText: {
    textAlign: 'center',
  },
  headerBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
  },
  mobileHeaderBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  desktopGraphic: {
    width: 552,
    height: 638,
  },
  desktopContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 15,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 40,
    paddingBottom: 15,
    alignItems: 'baseline',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  mobileContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 65,
    marginHorizontal: 30,
    borderRadius: 24,
    paddingHorizontal: 30,
    paddingTop: 50,
    paddingBottom: 40,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
  },
  btnBox: {
    display: 'flex',
    width: '100%',
    paddingTop: 25,
  },
  haveAccountBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
  },
  haveAccountBoxMobile: {
    justifyContent: 'center',
  },
  headerMobile: {
    textAlign: 'center',
    fontSize: 28,
    lineHeight: 34,
    marginBottom: 6,
  },
  passwordRequirementText: {
    fontStyle: 'italic',
    fontSize: 12,
    lineHeight: 22,
  },
  passwordRequirementTextMobile: {
    fontStyle: 'italic',
    fontSize: 12,
    lineHeight: 22,
  },
  mobileCircleGraphic: {
    height: 404,
    width: 387,
    zIndex: -1,
    borderRadius: 243,
    position: 'absolute',
    left: 160,
    top: -15,
  },
  mobileSquareGraphic: {
    height: 468,
    width: 415,
    zIndex: -1,
    position: 'absolute',
    left: -65,
    bottom: 20,
  },
  errorMessage: {
    width: 250,
  },
  screenWrapper: { paddingLeft: 0, paddingRight: 0 },
  termsCheckbox: { height: 20, width: 20 },
  createAccountButton: { width: 175, height: 45 },
  createAccountButtonMobile: { display: 'flex', justifyContent: 'center' },
  subHeaderMobile: { width: 260 },
  subHeader: { paddingLeft: 10 },
  termsCheckboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  noPadding: { paddingHorizontal: 0 },
  noMargin: { marginLeft: 0 },
});
