import * as React from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logoutUser } from 'auth/auth0';
import { Localization } from 'types';
import fetch from 'cross-fetch';
import env from 'constants/Config';

export const httpLink = createHttpLink({
  uri: 'https://graphql.datocms.com/',
  fetch,
  ...(env.DATOCMS_ENV && {
    headers: {
      'X-Environment': env.DATOCMS_ENV,
    },
  }),
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        JSON.stringify(message, null, 2),
        JSON.stringify(locations, null, 2),
        JSON.stringify(path, null, 2)
      );
      if (extensions?.code === 'UNAUTHENTICATED') {
        logoutUser();
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const authLink = setContext((_, { headers }) => ({
  headers: Object.assign(headers || {}, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: '04caeb93d6ac5f4b6c771e36f89187',
  }),
}));
export const cmsClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
export const ReportLocalization = React.createContext<Localization | null>(null);

export const ALL_PARTNER_CONFIGS = gql`
  query AllPartnerConfigs {
    allPartnerConfigs {
      name
      navigationItems
      theme
      key
      messages
      contactEmail
      website
      collectionInstructionsSite
    }
  }
`;

export const ALL_COLOR_CODES = gql`
  query AllColorCodes {
    allColorCodes {
      color
      order
      summaryBackgroundColor
      developerConfig
      imaIntColorCode
    }
  }
`;

export const ALL_RECOMMENDATION_HEADER_COLORS = gql`
  query AllRecommendationHeaderColors {
    allRecommendationHeaderColors {
      color
      valueOrKey
      defaultHeaderColor
    }
  }
`;

export const ALL_REJECTION_REASONS = gql`
  query AllRejectionReasons {
    allRejectionReasons {
      labReason
      customReasonCopy
    }
  }
`;

/**
 * DatoCMS only queries first 20 records by default
 * https://www.datocms.com/docs/content-delivery-api/how-to-fetch-records#query-multiple-records
 * Set first to tell the number of records to query
 * default 20
 * limit 100
 */
export const ALL_REPORTS = (locale = 'en') =>
  gql`
    query allReports {
      allReports(first: 100, locale: ${locale}) {
        title
        pages {
          ... on ReportOverviewPageRecord {
            name
            welcomeMessage
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResultsPageRecord {
            name
            title
            scenarios {
              name
              code {
                name
                developerConfig
              }
              summary
              explanation
              nextSteps
            }
            biomarkerPanels {
              title
              biomarkers {
                name
                shortName
                note
                units
                ranges
                developerConfig
                scenarioCode {
                  developerConfig
                }
                elevatedRecs
                lowRecs
              }
              description
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportDetailsPageRecord {
            name
            title
            subtitle
            description
            biomarkers {
              name
              note
              units
              ranges
              developerConfig
              scenarioCode {
                developerConfig
              }
              elevatedRecs
              lowRecs
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportBackgroundPageRecord {
            name
            title
            subtitle
            description
            familyHistory
            pastMedicalHistory
            questions {
              familyHistory
              pastMedicalHistory
              yes
              no
              name
              developerConfig
            }
            breadcrumb
            goToButton
            scenarios {
                name
                code {
                  name
                  developerConfig
                }
                summary
            }
            __typename
          }
          ... on ReportRecommendationsPageRecord {
            name
            title
            subtitle
            description
            defaultDescription
            recommendations {
              title
              icon {
                url
              }
              code {
                name
                developerConfig
              }
              description
              link
              recommendationId
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResourcesPageRecord {
            name
            title
            subtitle
            description
            resources {
              title
              thumbnail {
                url
              }
              preview
              author
              link
            }
            breadcrumb
            goToButton
            __typename
          }
        }
        doctors {
          name
          positionTitle
          avatar {
            url
          }
        }
        theme {
          name
          primaryColor {
            hex
          }
        }
        developerConfig
      }
    }      
`;

export const SPECIFIC_REPORT = (testName?: string, locale = 'en') =>
  gql`
    query allReports {
      allReports(first: 100, locale: ${locale}, filter: { testName: { eq: "${testName || ''}" } } ) {
        title
        pages {
          ... on ReportOverviewPageRecord {
            name
            welcomeMessage
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResultsPageRecord {
            name
            title
            scenarios {
              name
              code {
                name
                developerConfig
              }
              summary
              explanation
              nextSteps
            }
            biomarkerPanels {
              title
              biomarkers {
                name
                shortName
                note
                units
                ranges
                developerConfig
                scenarioCode {
                  developerConfig
                }
                elevatedRecs
                lowRecs
              }
              description
              addPdfForClinicianOption
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportDetailsPageRecord {
            name
            title
            subtitle
            description
            biomarkers {
              name
              note
              units
              ranges
              developerConfig
              scenarioCode {
                developerConfig
              }
              elevatedRecs
              lowRecs
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportBackgroundPageRecord {
            name
            title
            subtitle
            description
            familyHistory
            pastMedicalHistory
            questions {
              familyHistory
              pastMedicalHistory
              yes
              no
              name
              developerConfig
            }
            breadcrumb
            goToButton
            scenarios {
                name
                code {
                  name
                  developerConfig
                }
                summary
            }
            __typename
          }
          ... on ReportRecommendationsPageRecord {
            name
            title
            subtitle
            description
            defaultDescription
            recommendations {
              title
              icon {
                url
              }
              code {
                name
                developerConfig
              }
              description
              link
              recommendationId
            }
            breadcrumb
            goToButton
            __typename
          }
          ... on ReportResourcesPageRecord {
            name
            title
            subtitle
            description
            resources {
              title
              thumbnail {
                url
              }
              preview
              author
              link
            }
            breadcrumb
            goToButton
            __typename
          }
        }
        doctors {
          name
          positionTitle
          avatar {
            url
          }
        }
        theme {
          name
          primaryColor {
            hex
          }
        }
        developerConfig
      }
    }      
`;

export const REPORT_LOCALIZATION = (locale = 'en') => gql`
  query reportLocalization {
    reportLocalization(locale: ${locale}) {
      biomarker
      biomarkerCutOff
      biomarkerElevatedOutcome
      biomarkerOptimalOutcome
      biomarkerLowOutcome
      biomarkerNormalOutcome
      biomarkerPositiveOutcome
      biomarkerNegativeOutcome
      biomarkerQualitative
      biomarkerQuantitative
      biomarkerReportableRange
      biomarkerTargetRange
      biomarkerDetectedOutcome
      biomarkerNotDetectedOutcome
      biomarkerInsufficientSampleOutcome
      headerBackToDashboard
      headerDownloadPdfReport
      navigationBackButton
      learnMore
      showMore
      activateTest
      myTests
      settings
      signOut
      brand
    }
  }
`;
