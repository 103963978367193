import { gql } from '@apollo/client';

/**
 * DatoCMS only queries first 20 records by default
 * https://www.datocms.com/docs/content-delivery-api/how-to-fetch-records#query-multiple-records
 * Set first to tell the number of records to query
 * default 20
 * limit 100
 */
export const ALL_TEST_REQUIREMENTS = gql`
  query allTestRequirements {
    allTestRequirements(first: 100, locale: en) {
      title
      instructionType {
        name
        instructionSteps {
          name
          image {
            url
          }
          title
          subtitle
          description
        }
        developerConfig
      }
      prepRequirements {
        title
        description
        link
      }
      developerConfig
    }
  }
`;

export const TEST_REQUIREMENTS_BY_SKU = gql`
  query allTestRequirements ($pattern: String!){
    allTestRequirements (first: 5, locale: en, filter: {sku: { matches: {pattern: $pattern, caseSensitive: false}}}) {
      title
      instructionType {
        name
        instructionSteps {
          name
          image {
            url
          }
          title
          subtitle
          description
        }
        developerConfig
      }
      prepRequirements {
        title
        description
        link
      }
      developerConfig
    }
  }
`;

export const GET_PLAN_DEFINITIONS = gql`
  query GetPlanDefinitions {
    getPlanDefinitions {
      name
      identifier
    }
  }
`;

export const GET_KIT_ID = gql`
  query GetKitId ($kitId: String!) {
    getKitId (kitId: $kitId){
      objectId
      kitId
      activationId
      labPrefix
      expiresAt
    }
  }
`;
