import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import Cookie from 'js-cookie';
import axios from 'axios';
import * as Sentry from 'sentry-expo';
import env from 'constants/Config';

import { AuthTokenRefreshBody, AuthTokenRefreshResponse } from 'types';

export default function useCheckOrRefreshAuth(): {
  isAuthenticated: boolean | null;
  isLoading: boolean;
  } {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const checkAuthStatus = async () => {
      let accessToken;
      if (process.env.NODE_ENV === 'test') {
        setIsLoading(false);
        return setIsAuthenticated(true);
      }
      if (Platform.OS === 'web') {
        accessToken = Cookie.get('accessToken');
      } else {
        accessToken = await SecureStore.getItemAsync('accessToken');
      }
      if (!accessToken) {
        setIsLoading(false);
        return setIsAuthenticated(false);
      }
      let expirationDate = '';
      let refreshToken = '';
      if (Platform.OS === 'web') {
        expirationDate = Cookie.get('expirationDate') ?? '';
        refreshToken = Cookie.get('refreshToken') ?? '';
      } else {
        expirationDate =
          (await SecureStore.getItemAsync('expirationDate')) ?? '';
        refreshToken = (await SecureStore.getItemAsync('refreshToken')) ?? '';
      }
      if (
        Platform.OS !== 'web' &&
        (!refreshToken || refreshToken === 'undefined')
      ) {
        setIsLoading(false);
        return setIsAuthenticated(false);
      }
      if (new Date(parseInt(expirationDate)) < new Date()) {
        if (Platform.OS === 'web') {
          Cookie.remove('accessToken');
          Cookie.remove('idToken');
          Cookie.remove('refreshToken');
          Cookie.remove('expirationDate');

          setIsLoading(false);
          return setIsAuthenticated(false);
        }
        axios
          .request<AuthTokenRefreshBody, AuthTokenRefreshResponse>({
            method: 'POST',
            url: `${env.AUTH0_DOMAIN}/oauth/token`,
            headers: { 'Content-Type': 'application/json' },
            data: {
              grant_type: 'refresh_token',
              client_id: env.AUTH0_CLIENT_ID,
              refresh_token: refreshToken,
            },
          })
          .then(async (response) => {
            const { access_token, id_token } = response.data;
            const today = new Date();
            expirationDate = today.setDate(today.getDate() + 1).toString();
            if (Platform.OS === 'web') {
              Cookie.set('accessToken', access_token);
              Cookie.set('idToken', id_token);
              Cookie.set('expirationDate', expirationDate);
            } else {
              await SecureStore.setItemAsync('accessToken', access_token);
              await SecureStore.setItemAsync('idToken', id_token);
              await SecureStore.setItemAsync('expirationDate', expirationDate);
            }
          })
          .catch((err) => Sentry.Browser.captureException(err, {
            extra: {
              event: 'RefreshAuthToken',
              message: `Auth0 token refresh failed`,
            },
          }));
      } else {
        setIsLoading(false);
        setIsAuthenticated(true);
      }
    };
    checkAuthStatus();
  }, []);

  return { isAuthenticated, isLoading };
}
