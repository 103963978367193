import React, { useState, useEffect } from 'react';
import { Platform, View, KeyboardAvoidingView, StyleSheet, Image, Linking } from 'react-native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import { useMutation, useLazyQuery } from '@apollo/client';
import DropDownPicker from 'react-native-dropdown-picker';
import { MaskedTextInput } from 'react-native-mask-text';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from 'sentry-expo';

import ScreenWrapper from 'components/ScreenWrapper';
import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import Button from 'components/StyledButton';
import RadioButton from 'components/RadioButton';
import LoadingModal from 'components/LoadingModal';

import { PartnerConfig, PersonalInfoRegistrationFields, StateName } from 'types';
import GlobalStyles from 'constants/Styles';
import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';

import { GET_PATIENT } from 'screens/dashboard/PatientDashboardScreen/queries';
import { FontAwesome } from '@expo/vector-icons';
import HeaderBar from 'components/HeaderBar';
import { createPatientBody, fields } from './utils';
import { CREATE_PATIENT } from './queries';
import PatientInformation from './components/PatientInformation';
import ShippingInformation from './components/ShippingInformation';
import AddressModal from '../../../components/AddressModal';
import DateTimePicker from '../../../components/DateTimePicker';
import client, { GET_PARTNER_CONFIG } from '../../../kit-activation';

const CompletePatientProfileScreen = ({ navigation }: { navigation: StackNavigationHelpers }) => {
  const [createPatient, { loading }] = useMutation(CREATE_PATIENT, {
    onCompleted: () => navigation.replace('KitActivation'),
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'CreatePatient',
          message: `Patient creation on CompletePatientProfileScreen failed`,
        },
        user: {
          username: `${getValues().firstName} ${getValues().lastName}`,
        },
      });
      if (err.message.includes("Invalid patient's home address")) {
        setPatientCreateError(400);
        setIsModalVisible(true);
      } else if (err.graphQLErrors?.[0]?.extensions?.exception) {
        const errorExtensions = err.graphQLErrors[0].extensions;
        const errorException = errorExtensions.exception as { status: number };
        const errorStatus = errorException.status;
        setPatientCreateError(errorStatus);
      } else {
        setPatientCreateError(500);
      }
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [patientCreateError, setPatientCreateError] = useState<null | number>(null);
  const [addressString, setAddressString] = useState<string | null>(null);
  const { isAuthenticated } = useCheckOrRefreshAuth();

  const [getPatientQuery] = useLazyQuery(GET_PATIENT, {
    onCompleted: ({ getPatient }) => {
      if (getPatient && !getPatient.email_verified) {
        navigation.navigate('EmailVerificationScreen');
      } else if (getPatient?.name?.[0]?.given?.[0]) {
        navigation.navigate('KitActivation');
      }
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetPatient',
          message: `Patient retrieval on CompletePatientProfileScreen failed`,
        },
        user: {
          username: `${getValues().firstName} ${getValues().lastName}`,
        },
      });
      if (err.graphQLErrors?.[0].extensions?.exception) {
        const errorExtensions = err.graphQLErrors[0].extensions;
        const errorException = errorExtensions.exception as { status: number };
        const errorStatus = errorException.status;
        setPatientCreateError(errorStatus);
      } else {
        setPatientCreateError(500);
      }
    },
  });

  useEffect(() => {
    if (isAuthenticated === false) {
      navigation.navigate('EmailRegistrationScreen');
    }
    if (isAuthenticated === true) {
      getPatientQuery();
    }
  }, [isAuthenticated]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<PersonalInfoRegistrationFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      birthDate: '',
      gender: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '' as StateName,
      zip: '',
      bypassAddressValidation: false,
    },
  });

  const handleErrors = () => {
    if (errors) {
      const requiredErrors = Object.values(errors).find((s) => s.type === 'required');
      const countRequiredErrors = Object.values(errors).length > 1;
      const validateError = Object.values(errors).find((s) => s.type === 'validate');
      const minError = Object.values(errors).find((s) => s.type === 'minLength');
      const patternError = Object.values(errors).find((s) => s.type === 'pattern');

      return (
        <View style={styles.errorBox}>
          {requiredErrors ? (
            <View style={styles.errorContainer}>
              <FontAwesome name="close" size={24} color="red" style={{ paddingRight: 10 }} />
              <Text style={styles.errorMessage}>{countRequiredErrors ? 'These fields are required' : requiredErrors.message}</Text>
            </View>
          ) : null}
          {validateError ? (
            <View style={styles.errorContainer}>
              <FontAwesome name="close" size={24} color="red" style={{ paddingRight: 10 }} />
              <Text style={styles.errorMessage}>{validateError.message}</Text>
            </View>
          ) : null}
          {minError ? (
            <View style={styles.errorContainer}>
              <FontAwesome name="close" size={24} color="red" style={{ paddingRight: 10 }} />
              <Text style={styles.errorMessage}>{minError?.message}</Text>
            </View>
          ) : null}
          {patternError ? (
            <View style={styles.errorContainer}>
              <FontAwesome name="close" size={24} color="red" style={{ paddingRight: 10 }} />
              <Text style={styles.errorMessage}>{patternError?.message}</Text>
            </View>
          ) : null}
        </View>
      );
    }

    return null;
  };

  const onSubmit: SubmitHandler<PersonalInfoRegistrationFields> = (data) => {
    setAddressString(`${data.addressLine1}${data.addressLine2 && `, ${data.addressLine2}`}; ${data.zip}; ${data.city} - ${data.state}`);
    createPatient({
      variables: {
        patientInput: createPatientBody(data),
      },
    });
  };

  const renderFields = () =>
    fields.map(({ name, sectionLabel, label, type, options = [], textContentType, half, placeholder, rules }) => (
      <React.Fragment key={name}>
        <View style={[half ? styles.half : styles.full]}>
          {sectionLabel && (
            <Text lora fontWeight={'700'} style={styles.sectionHeader}>
              {sectionLabel}
            </Text>
          )}
          <Text style={styles.inputLabel} lato fontWeight={'700'} units>
            {label}
          </Text>
          <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              if (type === 'select') {
                return (
                  <View>
                    <>
                      {/* Scrollview setting needed  to avoid nesting VirtualizedList in Scrollview error */}
                      {DropDownPicker.setListMode('SCROLLVIEW')}
                      <DropDownPicker
                        value={value}
                        items={options}
                        style={[GlobalStyles.input, styles.textContainer, error && GlobalStyles.inputError]}
                        setValue={onChange}
                        onChangeValue={onChange}
                        setOpen={setDropdownOpen}
                        open={dropdownOpen}
                        dropDownContainerStyle={{
                          borderColor: '#F7F7F7',
                          borderWidth: 2,
                        }}
                        textStyle={styles.itemText}
                        listItemContainerStyle={styles.itemContainerText}
                        dropDownDirection="TOP"
                        placeholder="Select State"
                        placeholderStyle={{
                          color: 'gray',
                          fontSize: 16,
                        }}
                      />
                      {error ? (
                        <Text units style={GlobalStyles.error}>
                          {error.message ? error.message : rules.message}
                        </Text>
                      ) : null}
                    </>
                  </View>
                );
              } else if (type === 'date') {
                return (
                  <View>
                    <DateTimePicker onChange={onChange} value={value ?? ''} onBlur={onBlur} testID={'birthDate'} accessible={true} error={undefined} />
                    {error ? (
                      <Text units style={GlobalStyles.error}>
                        {error.message ? error.message : rules.message}
                      </Text>
                    ) : null}
                  </View>
                );
              } else if (type === 'radio') {
                return (
                  <>
                    <View style={styles.optionsContainer}>
                      {options?.map((option) => (
                        <TouchableOpacity style={styles.optionContainer} onPress={() => onChange(option.value)} key={option.label}>
                          <RadioButton selected={value === option.value} />
                          <Text label> {option.label}</Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                    {error ? (
                      <Text units style={GlobalStyles.error}>
                        {error.message ? error.message : rules.message}
                      </Text>
                    ) : null}
                  </>
                );
              } else if (type === 'phone') {
                return (
                  <>
                    <MaskedTextInput
                      placeholder={placeholder}
                      mask="(999) 999-9999"
                      value={value}
                      style={[GlobalStyles.input, error && GlobalStyles.inputError]}
                      keyboardType="numeric"
                      onBlur={onBlur}
                      onChangeText={onChange}
                      testID="inputPhone"
                    />
                    {error ? (
                      <Text units style={GlobalStyles.error}>
                        {error.message ? error.message : rules.message}
                      </Text>
                    ) : null}
                  </>
                );
              }
              return (
                <>
                  <TextInput
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value as string}
                    style={[GlobalStyles.input, error && GlobalStyles.inputError]}
                    testID={name}
                    textContentType={textContentType}
                    placeholder={placeholder}
                    placeholderTextColor="grey"
                    keyboardType="default"
                  />
                  {error ? (
                    <Text units style={GlobalStyles.error}>
                      {error.message ? error.message : rules.message}
                    </Text>
                  ) : null}
                </>
              );
            }}
          />
        </View>
      </React.Fragment>
    ));

  const handleContinueWithAddress = () => {
    const values = getValues();
    values.bypassAddressValidation = true;
    onSubmit(values);
  };

  const getModal = () => <AddressModal isVisible={isModalVisible} addressString={addressString} onClose={() => setIsModalVisible(false)} onContinue={handleContinueWithAddress} />;

  const renderError = () => {
    const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
      query: GET_PARTNER_CONFIG,
    });
    const partnerConfig = data?.partnerConfig;
    const partnerEmail = partnerConfig?.contactEmail;

    if (patientCreateError && patientCreateError !== 400) {
      const errorText = (
        <Text testID="errorMessageGeneral">
          Something went wrong while trying to complete your profile. Please contact{' '}
          <TouchableOpacity
            onPress={async () => {
              await Linking.openURL(`mailto:${partnerEmail}`);
            }}
          >
            <Text style={{ color: '#DC412C', textDecorationLine: 'underline' }}>{partnerEmail}</Text>
          </TouchableOpacity>{' '}
          for further assistance
        </Text>
      );
      return (
        <View style={styles.errorWrapper}>
          <>
            <Image source={require('assets/images/error-icon.png')} style={styles.errorIcon} />
            <Text style={GlobalStyles.error} lato>
              {errorText}
            </Text>
            {getModal()}
          </>
        </View>
      );
    }
    return getModal();
  };

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  return isMobile ? (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={GlobalStyles.flex}>
      <ScreenWrapper>
        <View style={[GlobalStyles.container, GlobalStyles.paddingBottom]}>
          {loading ? (
            <LoadingModal />
          ) : (
            <>
              <Text lora fontWeight={'700'} style={[styles.header, styles.centeredText]} large>
                Complete your patient profile
              </Text>
              <Text lato style={styles.centeredText}>
                We just need a bit more information.
              </Text>
              <View style={styles.formContainer}>{renderFields()}</View>
              <Button primary testID="confirmBtn" style={GlobalStyles.button} onPress={handleSubmit(onSubmit, (err) => console.log('errors submitting', err))} arrowRight>
                Confirm
              </Button>
              {renderError()}
            </>
          )}
        </View>
      </ScreenWrapper>
    </KeyboardAvoidingView>
  ) : (
    <ScreenWrapper style={{ paddingLeft: 0, paddingRight: 0 }}>
      <HeaderBar navigation={navigation} />
      <View style={styles.desktopBackground}>
        <View style={styles.desktopContainer}>
          {loading ? (
            <LoadingModal />
          ) : (
            <>
              <Text xl lora style={styles.header}>
                Complete your patient profile
              </Text>
              <Text lato style={styles.subheader}>
                We just need a bit more information.
              </Text>
              <Text fontWeight="700" lora style={styles.sectionLabel}>
                Patient Information
              </Text>
              <View>
                <PatientInformation control={control} />
                <Text fontWeight="700" lora style={[styles.sectionLabel, styles.shippingLabel]}>
                  Address Information
                </Text>
                <ShippingInformation control={control} />
              </View>

              <View>{handleErrors()}</View>
              <Button
                primary
                testID="confirmBtn"
                style={[GlobalStyles.button, styles.submitButton]}
                textStyle={styles.submitButtonText}
                onPress={handleSubmit(onSubmit)}
                arrowRight
              >
                Confirm
              </Button>
              {renderError()}
            </>
          )}
        </View>
      </View>
    </ScreenWrapper>
  );
};

export default CompletePatientProfileScreen;

const styles = StyleSheet.create({
  formContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  half: {
    width: '47%',
    marginRight: 10,
  },
  full: {
    width: '100%',
  },
  header: {
    fontSize: 30,
    flexWrap: 'nowrap',
    overflow: 'visible',
    marginBottom: 10,
  },
  sectionHeader: {
    marginVertical: 15,
  },
  centeredText: {
    textAlign: 'center',
  },
  inputLabel: {
    textTransform: 'uppercase',
    letterSpacing: 0.8,
    marginTop: 5,
  },
  datePickerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(247, 247, 247, 0.8)',
    padding: 0,
    paddingRight: 18,
    marginTop: 10,
    height: 50,
  },
  datePicker: {
    textAlign: 'center',
    width: '100%',
  },
  picker: {
    marginTop: 0,
    paddingTop: 0,
  },
  optionsContainer: {
    flexDirection: 'row',
    height: 67,
  },
  optionContainer: {
    flexDirection: 'row',
    marginRight: 15,
    alignItems: 'center',
    height: 50,
    marginTop: 10,
  },
  imawareLogo: {
    width: '125px',
    height: '21px',
  },
  headerBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    alignContent: 'center',
    alignItems: 'center',
  },
  subheader: {
    marginBottom: 22,
  },
  sectionLabel: {
    marginBottom: 16,
  },
  shippingLabel: {
    marginTop: 34,
  },
  submitButton: { width: 230, height: 44 },
  submitButtonText: { fontWeight: '700', fontFamily: 'lato_400' },
  fieldLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 0.8,
    color: '#2F2F2F',
  },
  desktopBackground: {
    backgroundColor: '#FBF8F1',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 20,
  },
  desktopContainer: {
    width: 700,
    minHeight: 715,
    display: 'flex',
    backgroundColor: 'white',
    marginTop: 40,
    borderRadius: 24,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
    elevation: 3,
    paddingLeft: 68,
    paddingRight: 62,
    paddingTop: 42,
    paddingBottom: 47,
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23,
  },
  errorIcon: { height: 21, width: 21, marginRight: 10 },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 7,
  },
  errorMessage: {
    color: '#DC412C',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  itemText: {
    marginVertical: 5,
    fontSize: 14,
    fontWeight: '400',
    color: 'black',
  },
  itemContainerText: {
    display: 'flex',
    flexDirection: 'row',
  },
});
