import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { Layout } from './StyledView';

const ContentSummary = ({
  children,
  isMobile,
}: {
  children: React.ReactNode;
  isMobile: boolean;
}) => (
  <Layout marginHorizontal={isMobile && 'xl'} style={styles.layout}>
    {children}
  </Layout>
);

export const ContentSummaryContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return <ContentSummary isMobile={isMobile}>{children}</ContentSummary>;
};

export const ContentUnresultedBiomarkers = ({
  children,
  noMargin,
}: {
  children: any;
  noMargin?: boolean;
}): JSX.Element => <View style={noMargin ? null : styles.box}>{children}</View>;

const styles = StyleSheet.create({
  layout: {
    maxWidth: 700,
    textAlign: 'center',
    alignSelf: 'center',
  },
  box: {
    marginVertical: 20,
  },
});