import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from 'components/StyledText';
import format from 'date-fns/format';
import { useMediaQuery } from 'react-responsive';
import client, { GET_PARTNER_CONFIG, GET_REJECTION_REASONS } from 'kit-activation';
import { KitStatusRecord, PartnerConfig, RejectionReason, RejectionReasonCms, TestHistory, TestStatus } from 'types';
import ModalComponent from 'components/Modal';
import parseMarkdown from 'utils/markdown';
import { buttonTextMap, testStatusMap } from './utils';
import TestRowContainer, { TestBottomRow, TestRowButton, TestStatusContainer, TestStatusIndicator, TestText, TestTitle, TestTopRow } from './styles';

/**
 * Gets one of the most important statuses if they exist in the test history; otherwise returns the first status available.
 * @param kitStatuses The status history
 * @returns
 */
const getHighlightedStatus = (kitStatuses: KitStatusRecord[] | null) => {
  if (!kitStatuses?.length) {
    return undefined;
  }
  const keyStatus = kitStatuses.find(({ status }) => ['Fully Resulted By Lab', 'Partially Resulted By Lab', 'Rejected By Lab'].includes(status));
  return keyStatus ?? kitStatuses[0];
};

const TestRow = ({
  test,
  onViewCollectionInstructions,
  onViewResults,
  onTrackSample,
}: {
  test: TestHistory;
  // eslint-disable-next-line no-unused-vars
  onViewCollectionInstructions: (kitId: string) => void;
  // eslint-disable-next-line no-unused-vars
  onViewResults: (kitId: string) => void;
  // eslint-disable-next-line no-unused-vars
  onTrackSample: (t: TestHistory) => void;
}): JSX.Element => {
  const { testType, activationDate, kitStatus } = test;
  const highlightedStatus = getHighlightedStatus(kitStatus);
  const { status } = highlightedStatus ?? { status: 'Activated' };
  const testStatus = testStatusMap[status] as TestStatus;
  const rejectionReasons = highlightedStatus?.rejectionReasons;
  const buttonText = buttonTextMap[testStatus];
  const [rejectionModalisOn, setRejectionModalIsOn] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const onContactSupport = () => {
    setRejectionModalIsOn(true);
  };

  // eslint-disable-next-line consistent-return
  const determineOnClick = () => {
    switch (buttonText) {
      case 'View collection instructions':
        return onViewCollectionInstructions(test.kitId);
      case 'Track your sample':
        return onTrackSample(test);
      case 'View your results':
        return onViewResults(test.kitId);
      case 'View rejection reason':
        return onContactSupport();
      default:
        break;
    }
  };

  const getLatestRejectionReason = (rejectionReasons?: RejectionReason[] | null | undefined) => {
    if (!rejectionReasons?.length) {
      return undefined;
    }
    return rejectionReasons[rejectionReasons.length - 1];
  };

  const getCustomRejectionReason = (rejectionReasonCopy: string) => {
    const rejectionReasonsData = client.cache.readQuery<{
      rejectionReasons: RejectionReasonCms[];
    }>({
      query: GET_REJECTION_REASONS,
    });
    if (!rejectionReasonsData?.rejectionReasons?.length) {
      return rejectionReasonCopy;
    }
    const customRejectionReason = rejectionReasonsData.rejectionReasons.find((rejectionConfig) => rejectionConfig.labReason.toLowerCase() === rejectionReasonCopy.toLowerCase());
    return customRejectionReason?.customReasonCopy || rejectionReasonCopy;
  };

  const getRejectionText = () => {
    const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
      query: GET_PARTNER_CONFIG,
    });
    const partnerConfig = data?.partnerConfig;
    return <Text>{parseMarkdown(undefined, partnerConfig?.messages?.rejectedSample?.message, 16, 'lato_400')}</Text>;
  };

  const rejectionBox = () => {
    const latestRejectionReason = getLatestRejectionReason(rejectionReasons);
    let rejectionReasonCopy: string | undefined;
    if (latestRejectionReason) {
      rejectionReasonCopy = getCustomRejectionReason(latestRejectionReason.message);
    }
    return (
      <ModalComponent hideTitle={true} actions={[]} loading={false} onHidden={() => setRejectionModalIsOn(false)}>
        <View style={styles.rejectionContainer}>
          <View>
            {latestRejectionReason ? (
              <Text lato units key={latestRejectionReason.code} style={styles.helpText} testID="rejectionMessageId">
                {rejectionReasonCopy}
              </Text>
            ) : null}
          </View>

          <Text lato style={styles.helpText}>
            {getRejectionText()}
          </Text>
        </View>
      </ModalComponent>
    );
  };

  return (
    <TestRowContainer isMobile={isMobile} testID={`testRow${test.kitId}`}>
      {isMobile ? (
        <>
          <TestTopRow>
            <View style={styles.view}>
              <TestTitle fontWeight="700" lora>
                {testType}
              </TestTitle>
            </View>
          </TestTopRow>
          <TestBottomRow isMobile>
            <TestText small>Activated {format(new Date(activationDate), 'MMM d, y')}</TestText>
            <TestStatusContainer isMobile>
              <TestStatusIndicator testStatus={testStatus} testID="status-indicator" />
              <TestText small style={styles.italic}>{` ${testStatus}`}</TestText>
            </TestStatusContainer>
          </TestBottomRow>
          <View style={styles.buttonsContainer}>
            <TestRowButton isMobile small onPress={determineOnClick}>
              {buttonText}
            </TestRowButton>
            {/* {userAbleToSchedule() ? renderScheduleButton() : null} */}
          </View>
        </>
      ) : (
        <View style={styles.testContainer}>
          <View style={styles.view}>
            <TestTitle fontWeight="500" lora testID="testType">
              {testType}
            </TestTitle>
          </View>
          <View style={{ flex: 1 }}>
            <TestText style={styles.activationDate} testID="activationDate">
              {format(new Date(activationDate), 'MMM d, y')}
            </TestText>
          </View>
          <TestStatusContainer style={{ flex: 1 }}>
            <TestStatusIndicator testStatus={testStatus} testID="status-indicator" />
            <TestText style={styles.italic} testID="testStatus">{` ${testStatus}`}</TestText>
          </TestStatusContainer>
          <View style={styles.nextSteps}>
            <TestRowButton small onPress={determineOnClick}>
              {buttonText}
            </TestRowButton>
            {/* {userAbleToSchedule() ? renderScheduleButton() : null} */}
          </View>
        </View>
      )}
      {rejectionModalisOn ? rejectionBox() : null}
    </TestRowContainer>
  );
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    flex: 1,
  },
  text: {
    color: 'white',
  },
  italic: {
    fontStyle: 'italic',
    marginRight: 5,
  },
  activationDate: {
    textAlign: 'center',
  },
  testContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  nextSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  appointmentText: {
    textAlign: 'center',
    width: 250,
    fontStyle: 'italic',
    marginVertical: 5,
    fontSize: 14,
  },
  appointmentMobileText: {
    textAlign: 'center',
    width: '100%',
    fontStyle: 'italic',
    marginVertical: 5,
    fontSize: 14,
  },
  buttonsContainer: {
    paddingHorizontal: 10,
  },
  iconContainer: {
    position: 'relative',
  },
  tooltipContainer: {
    width: 225,
    borderWidth: 1,
    borderColor: '#868686',
    position: 'absolute',
    bottom: 20,
    left: -150,
    right: 250,
    zIndex: 10,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
  },
  rejectionReason: {
    marginBottom: 5,
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    paddingHorizontal: 35,
    fontStyle: 'italic',
  },
  helpText: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 15,
  },
  helpSubText: {
    textDecorationLine: 'underline',
  },
  rejectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15,
  },
});

export default TestRow;
