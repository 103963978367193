/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Path,
  G,
  Defs,
  Rect,
  ClipPath,
  Circle,
  Color,
  NumberProp,
} from 'react-native-svg';

export const Notification = ({
  bellColor,
  notifColor,
  notifOutline,
  size,
}: {
  bellColor: Color;
  notifColor: Color;
  notifOutline: Color;
  size: NumberProp;
}): JSX.Element => (
  <Svg width={size} height={size} viewBox="0 0 25 25" fill="none">
    <Path
      d="M20.2925 18.7507V19.0436H5.29248V18.7507L7.14603 16.8971L7.29248 16.7507V16.5436V11.5436C7.29248 8.63795 8.81467 6.3245 11.4078 5.71011L11.7925 5.61895V5.22358V4.54358C11.7925 3.98972 12.2386 3.54358 12.7925 3.54358C13.3463 3.54358 13.7925 3.98972 13.7925 4.54358V5.22358V5.61864L14.1768 5.71002C16.7606 6.32434 18.2925 8.6484 18.2925 11.5436V16.5436V16.7507L18.4389 16.8971L20.2925 18.7507ZM14.206 21.0436C13.9985 21.6242 13.4413 22.0436 12.7925 22.0436C12.1368 22.0436 11.5832 21.6249 11.3778 21.0436H14.206Z"
      fill="white"
      stroke={bellColor}
    />
    <Circle
      cx="17.7925"
      cy="6.54358"
      r="4.5"
      fill={notifColor}
      stroke={notifOutline}
    />
  </Svg>
);

export const Wand = ({
  color,
  size,
}: {
  color: Color;
  size: NumberProp;
}): JSX.Element => (
  <Svg width={size} height={size} viewBox="0 0 21 21" fill="none">
    <G clipPath="url(#clip0)">
      <Path
        d="M10.3686 7.06421L14.0809 10.7765L4.09308 20.7643L0.380804 17.052L10.3686 7.06421Z"
        fill={color}
      />
      <Path
        d="M16.5552 2.82202L18.323 4.58977L15.3178 7.59495L13.5501 5.8272L16.5552 2.82202Z"
        fill={color}
      />
      <Rect x="16.5616" y="9.5835" width="3.75" height="2.5" fill={color} />
      <Path
        d="M15.6149 14.3755L18.2666 17.0271L16.4988 18.7949L13.8472 16.1432L15.6149 14.3755Z"
        fill={color}
      />
      <Rect x="9.06165" y="0.833496" width="2.5" height="3.75" fill={color} />
      <Path
        d="M4.12445 2.87183L6.77608 5.52345L5.00833 7.2912L2.3567 4.63958L4.12445 2.87183Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.311646 0.833496)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export const CheckAll = ({
  color,
  size,
}: {
  color: Color;
  size: NumberProp;
}): JSX.Element => (
  <Svg width={size} height={size} viewBox="0 0 21 16" fill="none">
    <G clipPath="url(#clip0)">
      <Path
        d="M4.87531 14.3332L0.311646 9.49984L1.64498 8.1665L5.31165 11.1665L15.3116 0.166504L16.645 0.833171L6.92298 14.1998C6.69003 14.5209 6.32625 14.7215 5.93045 14.7473C5.53465 14.7731 5.14792 14.6213 4.87531 14.3332Z"
        fill={color}
      />
      <Path
        d="M19.9783 4.83317H17.3117C17.1276 4.83317 16.9783 4.68393 16.9783 4.49984C16.9783 4.31574 17.1276 4.1665 17.3117 4.1665H19.9783C20.1624 4.1665 20.3117 4.31574 20.3117 4.49984C20.3117 4.68393 20.1624 4.83317 19.9783 4.83317Z"
        fill={color}
      />
      <Path
        d="M19.9783 8.16642H14.9783C14.7942 8.16642 14.645 8.01718 14.645 7.83309C14.645 7.64899 14.7942 7.49976 14.9783 7.49976H19.9783C20.1624 7.49976 20.3116 7.64899 20.3116 7.83309C20.3116 8.01718 20.1624 8.16642 19.9783 8.16642Z"
        fill={color}
      />
      <Path
        d="M19.9783 11.4999H12.645C12.4609 11.4999 12.3116 11.3507 12.3116 11.1666C12.3116 10.9825 12.4609 10.8333 12.645 10.8333H19.9783C20.1624 10.8333 20.3116 10.9825 20.3116 11.1666C20.3116 11.3507 20.1624 11.4999 19.9783 11.4999Z"
        fill={color}
      />
      <Path
        d="M19.9783 14.8332H10.3117C10.1276 14.8332 9.97833 14.6839 9.97833 14.4998C9.97833 14.3157 10.1276 14.1665 10.3117 14.1665H19.9783C20.1624 14.1665 20.3117 14.3157 20.3117 14.4998C20.3117 14.6839 20.1624 14.8332 19.9783 14.8332Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect
          width="20"
          height="15"
          fill="white"
          transform="translate(0.311646 0.166504)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Settings = ({
  color,
  size,
}: {
  color: Color;
  size: NumberProp;
}): JSX.Element => (
  <Svg width={size} height={size} viewBox="0 0 21 19" fill="none">
    <G clipPath="url(#clip0)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9145 7.36583L18.3245 7.54504C18.887 7.61247 19.3123 8.09175 19.3116 8.65947V10.3396C19.3116 10.9073 18.8863 11.3866 18.3216 11.454L16.9145 11.6332C16.799 11.9911 16.6557 12.336 16.4878 12.6637L17.3585 13.7843C17.711 14.2387 17.6698 14.8786 17.2633 15.273L16.082 16.4543C15.6919 16.8571 15.0514 16.8983 14.5976 16.5457L13.4763 15.6751C13.1486 15.8422 12.8036 15.9856 12.4458 16.1017L12.2665 17.5117C12.1991 18.0742 11.7198 18.4995 11.1521 18.4995H9.47191C8.90417 18.4995 8.42488 18.0742 8.35745 17.508L8.17822 16.1011C7.82033 15.9856 7.47535 15.8422 7.14767 15.6742L6.02702 16.545C5.57258 16.8967 4.93274 16.8562 4.53832 16.4506L3.35697 15.2693C2.95416 14.8792 2.91296 14.2387 3.2655 13.785L4.1362 12.6637C3.96906 12.336 3.82569 11.9911 3.7095 11.6332L2.29949 11.454C1.73697 11.3866 1.31165 10.9073 1.31165 10.3396V8.65947C1.31165 8.09175 1.73697 7.61247 2.30169 7.54504L3.7095 7.36583C3.82569 7.00795 3.96824 6.66298 4.1362 6.33531L3.2655 5.21471C2.91296 4.76028 2.95416 4.11978 3.36067 3.72523L4.54202 2.54475C4.93274 2.14197 5.57327 2.10146 6.02633 2.45329L7.14684 3.32396C7.47466 3.15601 7.81964 3.01346 8.17822 2.89728L8.35745 1.48732C8.42488 0.92482 8.90417 0.499512 9.47191 0.499512H11.1521C11.7198 0.499512 12.1991 0.92482 12.2665 1.48952L12.4458 2.89728C12.8043 3.01346 13.1493 3.15683 13.4771 3.32396L14.597 2.45329C15.0514 2.10146 15.6919 2.14197 16.0857 2.54846L17.267 3.72977C17.6698 4.11978 17.711 4.76028 17.3585 5.21402L16.4878 6.33531C16.6549 6.66298 16.7983 7.00795 16.9145 7.36583ZM6.56179 9.49952C6.56179 11.5673 8.24413 13.2496 10.312 13.2496C12.3798 13.2496 14.0622 11.5673 14.0622 9.49952C14.0622 7.43176 12.3798 5.74948 10.312 5.74948C8.24413 5.74948 6.56179 7.43176 6.56179 9.49952Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect
          width="20"
          height="18"
          fill="white"
          transform="translate(0.311646 0.499512)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export const Brand = (): JSX.Element => (
  <Svg width="144" height="25" viewBox="0 0 144 25" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92289 0.346315C4.1832 0.177151 5.21161 0.584144 5.89724 1.65155C6.94252 3.27878 5.96418 5.44765 4.04008 5.81866C3.83758 5.8577 3.63002 5.87153 3.42481 5.89709C1.9968 5.88222 0.829569 4.953 0.562388 3.60322C0.259331 2.07232 1.3498 0.557444 2.92289 0.346315Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9375 15.771C36.9349 15.177 36.9015 14.58 36.8332 13.9902C36.7134 12.9578 36.3956 11.9859 35.8486 11.096C34.3691 8.68885 31.3798 7.51969 28.6586 8.02649C27.2014 8.29788 25.9367 8.96092 24.84 9.95716C24.7808 10.0109 24.7186 10.0611 24.6464 10.1226C24.0882 9.52541 23.4611 9.03532 22.744 8.66565C22.0186 8.29165 21.25 8.0553 20.442 7.9581C19.6432 7.86199 18.8507 7.90577 18.0647 8.09231C17.2746 8.27979 16.5612 8.63633 15.8409 9.12038V8.23737C15.2981 8.18433 11.6848 8.20594 11.4935 8.26011C11.4394 8.83397 11.4656 23.5615 11.5211 23.7558H16.0107C16.0107 23.6134 16.0107 23.5059 16.0107 23.3983C16.0107 20.3033 16.0125 17.2084 16.0066 14.1134C16.0062 13.9172 16.0599 13.7721 16.1929 13.6324C16.5553 13.2518 16.9511 12.9159 17.4103 12.6602C18.1643 12.2405 18.9634 12.0686 19.8216 12.2209C20.8662 12.4063 21.6161 13.1112 21.8184 14.1559C21.8984 14.5687 21.9279 14.9971 21.9294 15.4186C21.9391 18.0895 21.9344 20.7603 21.9345 23.4312V23.7513H26.4759C26.4759 23.6174 26.4759 23.5018 26.4759 23.3862C26.4759 21.0583 26.4829 18.7302 26.4719 16.4023C26.4683 15.6454 26.4239 14.8885 26.3838 14.1323C26.3758 13.9825 26.4105 13.8746 26.5037 13.7662C27.024 13.1614 27.6456 12.699 28.3898 12.4133C28.9961 12.1805 29.6204 12.105 30.2656 12.2174C31.3041 12.3984 32.0332 13.0794 32.2789 14.1085C32.3909 14.5775 32.4027 15.0557 32.4031 15.5338C32.4054 18.1595 32.4041 20.7853 32.4047 23.411C32.4047 23.5244 32.4108 23.6378 32.414 23.7451C32.8773 23.8013 36.7322 23.7806 36.9422 23.7192C36.9422 23.6186 36.9422 23.5116 36.9422 23.4046C36.9422 20.8601 36.9485 18.3155 36.9375 15.771Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.18237 23.5544H5.65258V8.1322H1.18237V23.5544Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.816 14.0316C122.074 12.153 123.892 10.0237 126.575 9.99597C129.002 9.9709 130.901 12.0187 131.156 14.0316H121.816ZM133.416 12.6917C132.801 10.9899 131.769 9.61917 130.21 8.68657C128.806 7.84623 127.278 7.55472 125.662 7.73748C123.42 7.99105 121.629 9.04099 120.396 10.9445C118.937 13.1968 118.629 15.6724 119.337 18.2374C120.078 20.924 121.823 22.7156 124.453 23.6052C125.617 23.9989 126.819 24.0706 128.037 23.9449C129.481 23.7959 130.814 23.3381 132.012 22.5048C132.507 22.1603 132.957 21.7631 133.364 21.2672C132.783 20.709 132.221 20.169 131.668 19.6374C131.624 19.655 131.605 19.6573 131.593 19.6685C131.515 19.7435 131.439 19.8199 131.363 19.8965C130.057 21.2158 128.474 21.7907 126.637 21.6742C124.16 21.5171 122.093 19.6528 121.66 17.1843C121.608 16.8865 121.554 16.5852 121.6 16.2512H133.902C133.92 16.1987 133.934 16.1741 133.937 16.1484C133.943 16.0857 133.947 16.0225 133.947 15.9594C133.946 14.8413 133.797 13.7462 133.416 12.6917Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.985 7.79941C113.502 8.08139 112.262 8.75537 111.403 10.0259C111.348 10.1078 111.29 10.1885 111.228 10.2658C111.2 10.3011 111.16 10.3267 111.089 10.3873C111.089 10.0792 111.089 9.82089 111.089 9.56257C111.089 9.3114 111.095 9.06007 111.088 8.80907C111.08 8.55398 111.121 8.29554 111.06 8.04357H108.502C108.446 8.3911 108.462 23.3545 108.518 23.5544H111.193V23.2139C111.193 19.9396 111.194 16.6654 111.191 13.3911C111.19 13.2145 111.224 13.0625 111.321 12.9108C111.675 12.3607 112.101 11.8753 112.614 11.4672C113.789 10.5328 115.108 10.0634 116.632 10.2602C116.71 10.2702 116.79 10.2615 116.887 10.2615C116.991 9.424 117.107 8.59567 117.184 7.76581C116.566 7.65101 115.741 7.65583 114.985 7.79941Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7972 19.1754C98.756 20.4702 97.4311 21.2757 95.7757 21.4251C92.7817 21.6952 90.3272 19.7182 89.8168 16.9505C89.457 14.9992 89.936 13.2562 91.3103 11.8099C92.2859 10.7832 93.5154 10.2777 94.9174 10.2449C96.9106 10.1982 98.532 10.9923 99.8056 12.5282C99.9156 12.6608 99.963 12.7956 99.9617 12.9696C99.9547 13.9261 99.9584 14.8827 99.9584 15.8393C99.9584 16.7959 99.9545 17.7525 99.9619 18.709C99.9632 18.8926 99.9106 19.0344 99.7972 19.1754ZM102.602 8.04727H100.058V9.79085C99.9922 9.76436 99.9728 9.76139 99.9599 9.75061C99.8771 9.68153 99.7952 9.6114 99.7141 9.54043C97.7603 7.83221 95.4971 7.36393 93.0093 7.889C91.3396 8.24139 90.0042 9.17287 88.9221 10.4765C86.6273 13.241 86.3722 17.3232 88.298 20.3617C90.3661 23.6247 93.9478 24.5419 96.9249 23.7149C97.9614 23.427 98.8783 22.9133 99.6734 22.1822C99.7823 22.0821 99.8933 21.9845 100.039 21.8534C100.089 22.4881 100.024 23.07 100.079 23.6417H102.62C102.68 23.2326 102.659 8.22956 102.602 8.04727Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.9095 19.1527C53.8593 20.4695 52.517 21.2849 50.8368 21.4287C47.8983 21.6803 45.5088 19.7917 44.9276 17.1389C44.5054 15.2114 44.9251 13.4599 46.2349 11.9683C47.1915 10.879 48.4288 10.3142 49.8631 10.2492C51.9226 10.1559 53.6017 10.9547 54.9146 12.5468C55.0129 12.666 55.0526 12.7861 55.0518 12.9394C55.0467 13.9052 55.0492 14.871 55.0492 15.8368C55.0492 16.8027 55.0461 17.7685 55.0522 18.7343C55.0532 18.8972 55.0117 19.0246 54.9095 19.1527ZM55.1526 9.75974C55.1332 9.76876 55.1137 9.7778 55.0942 9.78682C54.993 9.69915 54.8916 9.61175 54.7907 9.52378C52.9233 7.89554 50.7515 7.40681 48.3568 7.82945C46.5737 8.14414 45.1397 9.08621 43.9981 10.4714C42.5763 12.1965 41.9585 14.1977 42.1057 16.4299C42.2471 18.573 43.0851 20.3961 44.6236 21.8966C46.7179 23.9389 49.8238 24.4422 52.2831 23.6349C53.2458 23.3188 54.095 22.8123 54.8382 22.1221C54.9251 22.0414 55.0005 21.9367 55.1609 21.89V23.632C55.5216 23.6878 57.5397 23.6739 57.7302 23.6181V8.0464H55.1526V9.75974Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.0377 19.5886C79.0172 19.5845 78.9966 19.5804 78.9761 19.5763C77.4589 15.7674 75.9417 11.9585 74.4268 8.15546H72.5372C71.0158 11.9745 69.497 15.7866 67.9542 19.6591C66.4067 15.7742 64.8868 11.9586 63.3734 8.15917C62.7966 8.11155 60.8659 8.13048 60.6361 8.18864C62.7272 13.3823 64.8168 18.5726 66.9081 23.7668H68.7943C70.3481 19.8477 71.9011 15.9308 73.454 12.0139C73.4733 12.0154 73.4926 12.0168 73.5119 12.0182C75.0649 15.9354 76.618 19.8526 78.1742 23.7779H79.1078H80.0514C82.1497 18.5661 84.2388 13.377 86.3398 8.15857H83.5911C82.0764 11.9607 80.5571 15.7747 79.0377 19.5886Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0107 23.7558C14.4929 23.7558 13.007 23.7558 11.5211 23.7556C11.4656 23.5615 11.4394 8.83398 11.4935 8.26012C11.6847 8.20594 15.2981 8.18434 15.8409 8.23738V9.12039C16.5611 8.63634 17.2746 8.2798 18.0647 8.09231C18.8507 7.90575 19.6432 7.862 20.442 7.95811C21.25 8.0553 22.0185 8.29166 22.744 8.66566C23.4611 9.03532 24.0882 9.52539 24.6464 10.1226C24.7185 10.0611 24.7808 10.0109 24.84 9.95714C25.9367 8.9609 27.2014 8.29786 28.6586 8.0265C31.3798 7.51967 34.3691 8.68886 35.8486 11.096C36.3956 11.9859 36.7134 12.9578 36.8331 13.9902C36.9016 14.58 36.9349 15.177 36.9375 15.771C36.9485 18.3155 36.9422 20.8601 36.9422 23.4046C36.9422 23.5116 36.9422 23.6186 36.9422 23.7192C36.7322 23.7805 32.8773 23.8013 32.4139 23.7451C32.4109 23.6378 32.4047 23.5244 32.4047 23.411C32.4041 20.7853 32.4054 18.1595 32.4032 15.5338C32.4028 15.0557 32.3909 14.5775 32.2789 14.1085C32.0331 13.0794 31.3041 12.3984 30.2656 12.2174C29.6204 12.105 28.9961 12.1806 28.3898 12.4133C27.6456 12.699 27.024 13.1614 26.5037 13.7662C26.4105 13.8745 26.3758 13.9825 26.3838 14.1323C26.4239 14.8885 26.4683 15.6455 26.4719 16.4023C26.4829 18.7303 26.4759 21.0583 26.4759 23.3862C26.4759 23.5018 26.4759 23.6174 26.4759 23.7514H21.9345V23.4312C21.9344 20.7603 21.9391 18.0895 21.9294 15.4186C21.9279 14.9971 21.8984 14.5687 21.8184 14.1559C21.6161 13.1112 20.8662 12.4063 19.8216 12.2209C18.9634 12.0686 18.1643 12.2405 17.4104 12.6602C16.9511 12.9159 16.5553 13.2518 16.1929 13.6324C16.0599 13.7721 16.0062 13.9172 16.0066 14.1134C16.0124 17.2084 16.0107 20.3033 16.0107 23.3983C16.0107 23.5059 16.0107 23.6134 16.0107 23.7558Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.0377 19.5886C80.5571 15.7747 82.0764 11.9607 83.5911 8.15857H86.3398C84.2388 13.377 82.1497 18.5661 80.0514 23.7779H79.1078H78.1742C76.618 19.8526 75.0649 15.9354 73.5119 12.0182C73.4926 12.0168 73.4733 12.0154 73.454 12.0139C71.9011 15.9308 70.3481 19.8477 68.7943 23.7668H66.9081C64.8168 18.5726 62.7272 13.3823 60.6361 8.18864C60.8659 8.13048 62.7966 8.11155 63.3734 8.15917C64.8868 11.9586 66.4067 15.7742 67.9542 19.6591C69.497 15.7866 71.0158 11.9745 72.5372 8.15546H74.4268C75.9417 11.9585 77.4589 15.7674 78.9761 19.5763C78.9966 19.5804 79.0172 19.5845 79.0377 19.5886Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7972 19.1754C98.756 20.4702 97.4311 21.2757 95.7757 21.4251C92.7817 21.6952 90.3272 19.7182 89.8168 16.9505C89.457 14.9992 89.936 13.2562 91.3103 11.8099C92.2859 10.7832 93.5154 10.2777 94.9174 10.2449C96.9106 10.1982 98.532 10.9923 99.8056 12.5282C99.9156 12.6608 99.963 12.7956 99.9617 12.9696C99.9547 13.9261 99.9584 14.8827 99.9584 15.8393C99.9584 16.7959 99.9545 17.7525 99.9619 18.709C99.9632 18.8926 99.9106 19.0344 99.7972 19.1754ZM102.602 8.04727H100.058V9.79085C99.9922 9.76436 99.9728 9.76139 99.9599 9.75061C99.8771 9.68153 99.7952 9.6114 99.7141 9.54043C97.7603 7.83221 95.4971 7.36393 93.0093 7.889C91.3396 8.24139 90.0042 9.17287 88.9221 10.4765C86.6273 13.241 86.3722 17.3232 88.298 20.3617C90.3661 23.6247 93.9478 24.5419 96.9249 23.7149C97.9614 23.427 98.8783 22.9133 99.6734 22.1822C99.7823 22.0821 99.8933 21.9845 100.039 21.8534C100.089 22.4881 100.024 23.07 100.079 23.6417H102.62C102.68 23.2326 102.659 8.22956 102.602 8.04727Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.9095 19.1527C53.8593 20.4695 52.517 21.2849 50.8368 21.4287C47.8983 21.6803 45.5088 19.7917 44.9276 17.1389C44.5054 15.2114 44.9251 13.4599 46.2349 11.9683C47.1915 10.879 48.4288 10.3142 49.8631 10.2492C51.9226 10.1559 53.6017 10.9547 54.9146 12.5468C55.0129 12.666 55.0526 12.7861 55.0518 12.9394C55.0505 13.1808 55.0497 13.4223 55.0492 13.6637C55.0478 14.3881 55.0492 15.1125 55.0492 15.8368C55.0492 16.5612 55.0475 17.2856 55.0492 18.01C55.0497 18.2514 55.0507 18.4929 55.0522 18.7343C55.0532 18.8972 55.0117 19.0246 54.9095 19.1527ZM55.1526 8.0464V9.75974C55.1332 9.76876 55.1137 9.7778 55.0942 9.78682C54.993 9.69915 54.8916 9.61175 54.7907 9.52378C52.9233 7.89554 50.7515 7.40681 48.3568 7.82945C46.5737 8.14414 45.1397 9.08621 43.9981 10.4714C42.5763 12.1965 41.9585 14.1977 42.1057 16.4299C42.2471 18.573 43.0851 20.3961 44.6236 21.8966C46.7179 23.9389 49.8238 24.4422 52.2831 23.6349C53.2458 23.3188 54.095 22.8123 54.8382 22.1221C54.9251 22.0414 55.0005 21.9367 55.1609 21.89V23.632C55.5216 23.6878 57.5397 23.6739 57.7302 23.6181V8.0464H55.1526Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.0008 15.8433C55.0008 16.5511 54.9977 17.2589 55.0007 17.9667C54.9977 17.2589 55.0008 16.5511 55.0008 15.8433C55.0008 15.1355 54.9982 14.4277 55.0008 13.72C54.9982 14.4277 55.0008 15.1355 55.0008 15.8433Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.816 14.0316C122.074 12.153 123.892 10.0237 126.575 9.99597C129.002 9.9709 130.901 12.0187 131.156 14.0316H121.816ZM133.416 12.6917C132.801 10.9899 131.769 9.61917 130.21 8.68657C128.806 7.84623 127.278 7.55472 125.662 7.73748C123.42 7.99105 121.629 9.04099 120.396 10.9445C118.937 13.1968 118.629 15.6724 119.337 18.2374C120.078 20.924 121.823 22.7156 124.453 23.6052C125.617 23.9989 126.819 24.0706 128.037 23.9449C129.481 23.7959 130.814 23.3381 132.012 22.5048C132.507 22.1603 132.957 21.7631 133.364 21.2672C132.783 20.709 132.221 20.169 131.668 19.6374C131.624 19.655 131.605 19.6573 131.593 19.6685C131.515 19.7435 131.439 19.8199 131.363 19.8965C130.057 21.2158 128.474 21.7907 126.637 21.6742C124.16 21.5171 122.093 19.6528 121.66 17.1843C121.608 16.8865 121.554 16.5852 121.6 16.2512H133.902C133.92 16.1987 133.934 16.1741 133.937 16.1484C133.943 16.0857 133.947 16.0225 133.947 15.9594C133.946 14.8413 133.797 13.7462 133.416 12.6917Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.18237 23.5544H5.65258V8.1322H1.18237V23.5544Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.193 23.5544H108.518C108.462 23.3545 108.446 8.3911 108.502 8.04357H111.06C111.121 8.29557 111.08 8.55398 111.088 8.8091C111.095 9.06007 111.089 9.3114 111.089 9.56259C111.089 9.82089 111.089 10.0792 111.089 10.3873C111.16 10.3267 111.2 10.3011 111.228 10.2659C111.29 10.1885 111.348 10.1079 111.403 10.0259C112.262 8.75537 113.502 8.08139 114.985 7.79941C115.741 7.65583 116.566 7.65101 117.184 7.76581C117.107 8.5957 116.991 9.42402 116.887 10.2615C116.79 10.2615 116.71 10.2702 116.632 10.2602C115.108 10.0634 113.789 10.5328 112.614 11.4672C112.101 11.8753 111.675 12.3607 111.321 12.9108C111.224 13.0625 111.19 13.2145 111.191 13.3911C111.194 16.6654 111.193 19.9396 111.193 23.2139V23.5544Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.42481 5.89709C1.9968 5.88222 0.829569 4.953 0.562388 3.60325C0.259331 2.07234 1.3498 0.557461 2.92289 0.346305C4.1832 0.177167 5.21158 0.584162 5.89724 1.65157C6.94252 3.2788 5.96418 5.44767 4.04008 5.81866C3.83758 5.85773 3.63002 5.87155 3.42481 5.89709Z"
      fill="black"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.948 0.753166V0.0858283H137.204V0.753166H135.915V5.00306H135.236V0.753166H133.948ZM142.433 1.69445L141.042 3.94935H140.567L139.177 1.69445V5.00304H138.498V0.0858154H138.973L140.805 3.08532L142.636 0.0858154H143.111V5.00304H142.433V1.69445Z"
      fill="black"
    />
  </Svg>
);
