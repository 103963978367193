import React from 'react';
import { View, useWindowDimensions, Platform, StyleSheet } from 'react-native';

const Container = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 900;
  if (Platform.OS === 'web') {
    return (
      <View style={isLargeScreen ? styles.test2Large : styles.test2}>
        {children}
      </View>
    );
  }
  return <View style={styles.containerContent}>{children}</View>;
};

const styles = StyleSheet.create({
  containerContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  test2Large: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  test2: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
});

export default Container;
