import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { ApolloError } from '@apollo/client';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';

import { LayoutProps } from 'types';

import Container from './Container';
import Content from './Content';
import Footer from './Footer';
import LoadingErrorWrapper from './LoadingErrorWrapper';

const ScreenWrapper = ({
  loading = false,
  error = null,
  children,
  style = {},
  excludeFooter,
  miniFooter,
  excludeWrapper = false,
}: {
  loading?: boolean;
  error?: Error | ApolloError | null;
  children: ReactNode;
  style?: LayoutProps;
  excludeFooter?: boolean;
  miniFooter?: boolean;
  excludeWrapper?: boolean;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <>
      <SafeAreaProvider
        initialSafeAreaInsets={{ top: 1, left: 2, right: 3, bottom: 4 }}
      >
        {(isMobile && !excludeWrapper) || !excludeWrapper ? (
          <Container>
            <Content style={style}>
              <View style={styles.container}>
                <LoadingErrorWrapper loading={loading} error={error}>
                  {children}
                </LoadingErrorWrapper>
                {excludeFooter ? null : <Footer miniFooter={miniFooter} />}
              </View>
            </Content>
          </Container>
        ) : (
          <LoadingErrorWrapper loading={loading} error={error}>
            {children}
            {excludeFooter ? null : <Footer miniFooter={miniFooter} />}
          </LoadingErrorWrapper>
        )}
      </SafeAreaProvider>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default ScreenWrapper;
