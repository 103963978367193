import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import GlobalStyles from 'constants/Styles';
import Text from './StyledText';
import StyledView from './styles/StyledView';

const LoadingModal = (): JSX.Element => (
  <View
    style={[GlobalStyles.container, { backgroundColor: 'rgb(255,255,255)' }]}
  >
    <StyledView>
      <ActivityIndicator size="large" color="#116FA4" />
      <Text fontWeight={'500'} large style={{ marginLeft: 10 }}>
        Loading...
      </Text>
    </StyledView>
  </View>
);

export default LoadingModal;
