import { StyleSheet, Platform } from 'react-native';

let height;
if (Platform.OS === 'ios' || Platform.OS === 'android') {
  height = 'auto';
} else {
  height = '100%';
}

const GlobalStyles = StyleSheet.create({
  container: {
    height,
    padding: 16,
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
  },
  button: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
  input: {
    width: 'auto',
    height: 50,
    marginVertical: 10,
    padding: 10,
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    borderWidth: 0,
  },
  inputDesktop: {
    width: 200,
    height: 40,
    margin: 12,
    borderWidth: 1,
    borderRadius: 100,
    padding: 10,
    backgroundColor: '#F7F7F7',
  },
  inputError: {
    borderWidth: 1,
    borderColor: '#DC412C',
    borderRadius: 4,
  },
  paddingBottom: {
    paddingBottom: 50,
  },
  flex: {
    flex: 1,
  },
  image: {
    width: 350,
    height: 350,
  },
  error: {
    color: '#DC412C',
    lineHeight: 20,
    letterSpacing: 0.41,
  },
  subheader: {
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 30,
    paddingHorizontal: 6,
  },
  label: {
    alignSelf: 'flex-start',
    letterSpacing: 1,
    color: '#2F2F2F',
  },
  fullWidth: {
    width: '100%',
  },
});

export default GlobalStyles;
