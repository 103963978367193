import React from 'react';

import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { Alert, StyleSheet, View, Image } from 'react-native';
import { DrawerActions, NavigationAction } from '@react-navigation/native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import { useMediaQuery } from 'react-responsive';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

import ScanQRCodeScreen from 'screens/kitActivation/ScanQRCodeScreen';
import EnterKitIdScreen from 'screens/kitActivation/EnterKitIdScreen';
import ConfirmTestingRequirementsScreen from 'screens/kitActivation/ConfirmTestingRequirementsScreen';
import PatientDashboardScreen from 'screens/dashboard/PatientDashboardScreen';
import ResetPasswordScreen from 'screens/login/ResetPasswordScreen';
import CompletePatientProfileScreen from 'screens/registration/CompletePatientProfileScreen';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import Button from 'components/StyledButton';
import GlobalStyles from 'constants/Styles';
import { logoutUser } from 'auth/auth0';
import SuccessResetPasswordScreen from 'screens/login/SuccessResetPasswordScreen';
import AccountDetailsScreen from 'screens/setting/AccountDetailsScreen';
import EmailVerificationScreen from '../screens/registration/EmailVerificationScreen';
import { ReportNavigatorStack } from './ReportNavigator';
import { PartnerConfig } from '../types';
import LoginScreen from '../screens/login/LoginScreen';

const AuthStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const getComponent = (componentName: string) => {
  switch (componentName) {
    case 'AccountDetailsScreen':
      return AccountDetailsScreen;
    default:
      return PatientDashboardScreen;
  }
};

const CustomIconComponent = ({
  partnerConfig,
  image,
}: {
  partnerConfig: PartnerConfig;
  image: {
    name: 'info' | 'book' | 'home' | 'user' | 'cog' | 'help';
  };
}): JSX.Element => (
  <View style={{ marginRight: -15 }}>
    <Entypo style={styles.passwordIcon} name={image?.name} size={24} color={partnerConfig.theme.primaryColor} />
  </View>
);

const authenticatedScreens = () => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <AuthStack.Navigator headerMode="none">
      <AuthStack.Screen name="PatientDashboardScreen">{(props) => <PatientDashboardScreen isDesktop={!isMobile} {...props} />}</AuthStack.Screen>
      <AuthStack.Screen name="CompletePatientProfileScreen" component={CompletePatientProfileScreen} />
      <AuthStack.Screen name="EnterKitIdScreen" component={EnterKitIdScreen} />
      <AuthStack.Screen name="ScanQRCodeScreen" component={ScanQRCodeScreen} />
      <AuthStack.Screen name="ConfirmTestingRequirementsScreen" component={ConfirmTestingRequirementsScreen} />
      <AuthStack.Screen name="ResetPasswordScreen">{(props) => <ResetPasswordScreen isDesktop={!isMobile} {...props} />}</AuthStack.Screen>
      <AuthStack.Screen name="SuccessResetPasswordScreen" component={SuccessResetPasswordScreen} />
      <AuthStack.Screen name="Report" component={ReportNavigatorStack} />
      <AuthStack.Screen name="AccountDetailsScreen">{(props) => <AccountDetailsScreen isDesktop={!isMobile} {...props} />}</AuthStack.Screen>
      <AuthStack.Screen name="EmailVerificationScreen" component={EmailVerificationScreen} />
      <AuthStack.Screen name="LoginScreen" component={LoginScreen} />
    </AuthStack.Navigator>
  );
};

const LogoTitle = () => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });

  const partnerConfig = data?.partnerConfig;
  return (
    <Image
      style={[styles.imawareLogo, partnerConfig?.theme.logoDimensions]}
      source={
        partnerConfig?.theme.logo || require('assets/images/logo.png')
      }
    />
  );
};

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });

  const partnerConfig = data?.partnerConfig;

  const customNavigation = {
    dispatch: (event: NavigationAction) => {
      // If you are on Reports page and click in the Dashboard menu item,
      // this is the event that will be thrown and we want to force the
      // navigation to happen
      if (event.type === 'CLOSE_DRAWER') {
        if (props.state.routeNames[props.state.index] === 'Dashboard') {
          props.navigation.navigate('PatientDashboardScreen');
        }
      } else {
        // Navigate normally
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (name property)
        const routeName: string = event?.payload?.name;
        const routes = props.state.routes;
        if (routeName) {
          return props.navigation.reset({
            index: routes.findIndex((r) => r.name === routeName),
            routes: routes.map((r) => ({ name: r.name, key: r.key })),
          });
        }
      }
      return props.navigation.dispatch(DrawerActions.closeDrawer());
    },
  };

  const onLogoutPress = () =>
    client
      .clearStore()
      .then(() => logoutUser())
      .catch((err) => Alert.alert(err.message));

  // Reference: https://github.com/react-navigation/react-navigation/issues/2021#issuecomment-747534976
  const { state, navigation, ...rest } = props;
  const newState = { ...state };
  newState.routes = newState.routes.filter(
    (item) => item.name !== 'ResetPasswordScreen'
  );

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={styles.drawerContainer}
    >
      <View
        style={
          isMobile
            ? styles.drawerItemsContainer
            : styles.drawerItemsContainerDesktop
        }
      >
        <View style={styles.drawerLogoBox}>
          {isMobile ? (
            <Ionicons
              name="close"
              size={34}
              color="black"
              testID="closeButton"
              onPress={() => {
                props.navigation.dispatch(DrawerActions.closeDrawer());
              }}
            />
          ) : null}
          <Image
            style={[styles.imawareLogo, partnerConfig?.theme.logoDimensions]}
            source={
              partnerConfig?.theme.logo || require('assets/images/logo.png')
            }
          />
        </View>
        <DrawerItemList
          state={newState}
          navigation={customNavigation}
          {...rest}
        />
        {partnerConfig?.navigationItems.items
          .filter((navItem) => !navItem.isComponent)
          .map((navItem) => {
            const { name, image, url } = navItem;
            return (
              <DrawerItem
                onPress={() => window.open(url, '_target')}
                key={name}
                style={isMobile ? styles.drawerItem : styles.drawerDesktopItem}
                icon={() => (
                  <CustomIconComponent
                    partnerConfig={partnerConfig}
                    image={image}
                  />
                )}
                inactiveTintColor="#000000"
                label={name}
              />
            );
          })}
      </View>

      <Button
        onPress={onLogoutPress}
        style={(GlobalStyles.button, styles.logoutButton)}
        bold
      >
        Logout
      </Button>
    </DrawerContentScrollView>
  );
};

const renderSubMenu = (partnerConfig?: PartnerConfig) => partnerConfig?.navigationItems.items
  .filter((item) => item.isComponent)
  .map((props) => {
    const { component, name, image } = props;
    const isDashboard = name === 'Dashboard';
    return (
      <Drawer.Screen
        name={name}
        component={
          isDashboard ? authenticatedScreens : getComponent(component!)
        }
        options={{
          drawerIcon: () => (
            <CustomIconComponent
              partnerConfig={partnerConfig}
              image={image}
            />
          ),
        }}
      />
    );
  })
  .concat([
    <Drawer.Screen
      name={'ResetPasswordScreen'}
      component={ResetPasswordScreen}
      options={{ headerTitle: LogoTitle }}
    />,
  ]);

const DrawerNavigator = ({
  navigation,
}: {
  navigation: DrawerNavigationHelpers;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  return (
    <Drawer.Navigator
      drawerContent={(props) => (
        <CustomDrawerContent {...props} navigation={navigation} />
      )}
      screenOptions={{
        headerStyle: {
          backgroundColor: 'white',
        },
        headerShown: isMobile,
        swipeEnabled: false,
        headerTitle: LogoTitle,
      }}
      drawerContentOptions={{
        activeBackgroundColor: partnerConfig?.theme.secondaryColor ?? '#E1E9F3',
        activeTintColor: 'black',
        inactiveTintColor: 'black',
      }}
    >
      {renderSubMenu(partnerConfig)?.filter((menuItem) => !!menuItem)}
    </Drawer.Navigator>
  );
};

export default DrawerNavigator;

const styles = StyleSheet.create({
  drawerContainer: {
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: 'white',
    paddingBottom: 60,
  },
  drawerItemsContainer: {
    paddingTop: 30,
    paddingHorizontal: 10,
  },
  drawerItemsContainerDesktop: {
    paddingHorizontal: 0,
  },
  drawerItem: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#000000',
    paddingLeft: 10,
  },
  drawerDesktopItem: {
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#000000',
    backgroundColor: 'white',
    paddingHorizontal: 10,
  },
  logoutButton: {
    alignSelf: 'center',
    width: '90%',
    justifyContent: 'center',
  },
  logo: {
    width: 130,
    aspectRatio: 5,
    height: 100,
    resizeMode: 'contain',
    display: 'flex',
    alignItems: 'flex-start',
  },
  drawerLogoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 100,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  imawareLogo: {
    width: 120,
    aspectRatio: 5,
    height: 21,
    resizeMode: 'contain',
    flexGrow: 1,
  },
  homeIcon: {
    width: 21,
    height: 23,
  },
  testsIcon: {
    width: 18,
    height: 22,
  },
  passwordIcon: {
    width: 17,
    height: 23,
  },
  helpIcon: {
    width: 18,
    height: 18,
  },
});
