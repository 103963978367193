import * as React from 'react';
import { Text as BaseText } from 'react-native';
import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';
import {
  Lora_400Regular,
  Lora_500Medium,
  Lora_600SemiBold,
  Lora_700Bold,
} from '@expo-google-fonts/lora';
import { Lato_400Regular, Lato_700Bold } from '@expo-google-fonts/lato';

import { useMediaQuery } from 'react-responsive';
import { FontWeight, FontKeyProps, TextProps } from 'types';

const fontWeightMap = {
  roboto: {
    100: Roboto_100Thin,
    300: Roboto_300Light,
    400: Roboto_400Regular,
    500: Roboto_500Medium,
    700: Roboto_700Bold,
    bold: Roboto_700Bold,
    900: Roboto_900Black,
  },
  lora: {
    100: null,
    300: null,
    400: Lora_400Regular,
    500: Lora_500Medium,
    600: Lora_600SemiBold,
    700: Lora_700Bold,
  },
  lato: {
    100: null,
    300: null,
    400: Lato_400Regular,
    500: null,
    700: Lato_700Bold,
  },
};

const fontSizeMap = {
  xxl: {
    desktop: 38,
    mobile: 32,
    lineHeight: null,
  },
  xl: {
    desktop: 31,
    mobile: 25,
    lineHeight: null,
  },
  large: {
    desktop: 25,
    mobile: 20,
    lineHeight: 31,
  },
  medium: {
    desktop: 21,
    mobile: 19,
    lineHeight: null,
  },
  regular: {
    desktop: 17,
    mobile: 16,
    lineHeight: 25,
  },
  small: {
    desktop: 15,
    mobile: 15,
    lineHeight: null,
  },
  button: {
    desktop: 16,
    mobile: 16,
    lineHeight: null,
  },
  label: {
    desktop: 14,
    mobile: 14,
    lineHeight: null,
  },
  units: {
    desktop: 12,
    mobile: 12,
    lineHeight: null,
  },
  tiny: {
    desktop: 11,
    mobile: 11,
    lineHeight: null,
  },
};

export default (props: TextProps): JSX.Element => {
  let fontName: 'roboto' | 'lora' | 'lato' = 'roboto';
  if (props.lora) fontName = 'lora';
  if (props.lato) fontName = 'lato';

  const fontWeight: FontWeight = props.fontWeight || '400';
  const fontFamily = `${fontName}_${fontWeight}`;
  useFonts({
    [fontFamily]: fontWeightMap[fontName][fontWeight],
  });
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  let fontSize;
  let lineHeight: number | undefined;
  for (const [key, value] of Object.entries(fontSizeMap)) {
    const fontSizeKey = key as FontKeyProps;
    if (props[fontSizeKey]) {
      fontSize = isMobile ? value.mobile : value.desktop;
      lineHeight = value.lineHeight ?? undefined;
    }
  }
  if (!fontSize) {
    fontSize = 16;
  }
  return (
    <BaseText
      {...props}
      style={[
        {
          fontFamily,
          fontSize,
          lineHeight,
        },
        props.style,
      ]}
    />
  );
};
