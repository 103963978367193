import React from 'react';
import { TextInput as BaseTextInput } from 'react-native';
import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';
import { Lora_400Regular, Lora_500Medium } from '@expo-google-fonts/lora';
import { useMediaQuery } from 'react-responsive';
import { FontWeight, FontKeyProps, TextInputProps } from 'types';
import Text from './StyledText';

const fontWeightMap = {
  roboto: {
    100: Roboto_100Thin,
    300: Roboto_300Light,
    400: Roboto_400Regular,
    500: Roboto_500Medium,
    700: Roboto_700Bold,
    bold: Roboto_700Bold,
    900: Roboto_900Black,
  },
  lora: {
    100: null,
    300: null,
    400: Lora_400Regular,
    500: Lora_500Medium,
    700: null,
  },
};

const fontSizeMap = {
  xxl: {
    desktop: 38,
    mobile: 32,
    lineHeight: null,
  },
  xl: {
    desktop: 31,
    mobile: 25,
    lineHeight: null,
  },
  large: {
    desktop: 25,
    mobile: 20,
    lineHeight: 31,
  },
  medium: {
    desktop: 21,
    mobile: 19,
    lineHeight: null,
  },
  regular: {
    desktop: 17,
    mobile: 16,
    lineHeight: 25,
  },
  small: {
    desktop: 15,
    mobile: 15,
    lineHeight: null,
  },
  button: {
    desktop: 16,
    mobile: 16,
    lineHeight: null,
  },
  label: {
    desktop: 14,
    mobile: 14,
    lineHeight: null,
  },
  units: {
    desktop: 12,
    mobile: 12,
    lineHeight: null,
  },
  tiny: {
    desktop: 11,
    mobile: 11,
    lineHeight: null,
  },
};

const TextInput = (props: TextInputProps): JSX.Element => {
  const fontName = props.lora ? 'lora' : 'roboto';
  const fontWeight: FontWeight = props.fontWeight || '400';
  const fontFamily = props.lora
    ? `${fontName}_${fontWeight}`
    : `${fontName}_${fontWeight}`;
  useFonts({
    [fontFamily]: fontWeightMap[fontName][fontWeight],
  });
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  let fontSize;
  let lineHeight: number | undefined;
  for (const [key, value] of Object.entries(fontSizeMap)) {
    const fontSizeKey = key as FontKeyProps;
    if (props[fontSizeKey]) {
      fontSize = isMobile ? value.mobile : value.desktop;
      lineHeight = value.lineHeight ?? undefined;
    }
  }
  if (!fontSize) {
    fontSize = 16;
  }
  return (
    <>
      {props.label && (
        <Text large fontWeight="700">
          {props.label}
        </Text>
      )}
      <BaseTextInput
        {...props}
        style={[props.style, { fontFamily, fontSize, lineHeight }]}
      />
    </>
  );
};

export default TextInput;
