import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation CreatePatient($patientInput: PatientInput!) {
    createPatient(patientInput: $patientInput) {
      id
      telecom {
        system
        value
      }
    }
  }
`;
