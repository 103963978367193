import ModalComponent from 'components/Modal';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ErrorModalMissingBiomarkersTypes, PartnerConfig } from 'types';
import client, { GET_PARTNER_CONFIG } from '../../../kit-activation';

const ErrorModalMissingBiomarkers = ({ onHidden }: ErrorModalMissingBiomarkersTypes) => {
  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;
  const partnerEmail = partnerConfig?.contactEmail || 'our support team';

  return (
    <ModalComponent loading={false} actions={[]} title="Error" onHidden={onHidden} hideTitle={true} errorMode={true}>
      <View>
        <Text style={styles.text}>Something went wrong. Please contact {partnerEmail} for further assistance.</Text>
      </View>
    </ModalComponent>
  );
};

export default ErrorModalMissingBiomarkers;

const styles = StyleSheet.create({
  text: {
    fontFamily: 'lato',
    lineHeight: 19.2,
    paddingVertical: 10,
    fontSize: 16,
  },
});
