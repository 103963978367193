import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types';
import * as Sentry from 'sentry-expo';

import client, { GET_PARTNER_CONFIG } from 'kit-activation';
import ScreenWrapper from 'components/ScreenWrapper';
import DesktopNavigation from 'components/DesktopNavigation';
import useCheckOrRefreshAuth from 'hooks/useCheckOrRefreshAuth';
import { PartnerConfig } from 'types';

import TestResultsTable from './components/TestResultsTable';

import { GET_PATIENT } from './queries';

const PatientDashboardScreen = ({ navigation, isDesktop }: { navigation: StackNavigationHelpers; isDesktop?: boolean }): JSX.Element => {
  const [patientName, setPatientName] = useState<string | null>(null);
  const { isAuthenticated } = useCheckOrRefreshAuth();

  useEffect(() => {
    if (isAuthenticated === null) {
      return;
    }
    if (isAuthenticated === false) {
      navigation.navigate('Registration');
    } else {
      getPatientQuery();
    }
  }, [isAuthenticated]);

  const [getPatientQuery, { loading, error }] = useLazyQuery(GET_PATIENT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPatient }) => {
      if (getPatient?.name?.[0]?.given?.[0]) {
        setPatientName(getPatient.name[0].given[0]);
      } else if (getPatient.id) {
        navigation.navigate('KitActivation');
      } else if (getPatient.email_verified) {
        navigation.navigate('CompletePatientProfileScreen');
      } else {
        navigation.navigate('EmailVerificationScreen');
      }
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetPatient',
          message: `Patient retrieval on PatientDashboardScreen failed`,
        },
      });
    },
  });

  const data = client.cache.readQuery<{ partnerConfig: PartnerConfig }>({
    query: GET_PARTNER_CONFIG,
  });
  const partnerConfig = data?.partnerConfig;

  return (
    <ScreenWrapper loading={loading || !data?.partnerConfig} excludeFooter excludeWrapper error={error} style={styles.wrapper}>
      <View style={Platform.OS === 'web' ? styles.view : {}}>
        {isDesktop ? <DesktopNavigation navigation={navigation} componentLabel={'PatientDashboardScreen'} partnerConfig={partnerConfig} /> : null}
        <TestResultsTable navigation={navigation} name={patientName} />
      </View>
    </ScreenWrapper>
  );
};

export default PatientDashboardScreen;

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  wrapper: {
    padding: '0',
  },
});
