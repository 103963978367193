import { format, parse } from 'date-fns';
import { TextInputIOSProps } from 'react-native';
import { FieldName, StateName } from 'types';

export const states = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Alabama',
    value: 'Alabama',
  },
  {
    label: 'Alaska',
    value: 'Alaska',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    label: 'Arizona',
    value: 'Arizona',
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
  },
  {
    label: 'California',
    value: 'California',
  },
  {
    label: 'Colorado',
    value: 'Colorado',
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
  },
  {
    label: 'Delaware',
    value: 'Delaware',
  },
  {
    label: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
  },
  {
    label: 'Florida',
    value: 'Florida',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Guam',
    value: 'Guam',
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
  },
  {
    label: 'Idaho',
    value: 'Idaho',
  },
  {
    label: 'Illinois',
    value: 'Illinois',
  },
  {
    label: 'Indiana',
    value: 'Indiana',
  },
  {
    label: 'Iowa',
    value: 'Iowa',
  },
  {
    label: 'Kansas',
    value: 'Kansas',
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
  },
  {
    label: 'Maine',
    value: 'Maine',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    label: 'Maryland',
    value: 'Maryland',
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    label: 'Michigan',
    value: 'Michigan',
  },
  {
    label: 'Minnesota',
    value: 'Minnesota',
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
  },
  {
    label: 'Missouri',
    value: 'Missouri',
  },
  {
    label: 'Montana',
    value: 'Montana',
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
  },
  {
    label: 'Nevada',
    value: 'Nevada',
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
  },
  {
    label: 'New York',
    value: 'New York',
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
  },
  {
    label: 'North Dakota',
    value: 'North Dakota',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    label: 'Ohio',
    value: 'Ohio',
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    label: 'Oregon',
    value: 'Oregon',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
  },
  {
    label: 'South Dakota',
    value: 'South Dakota',
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
  },
  {
    label: 'Texas',
    value: 'Texas',
  },
  {
    label: 'Utah',
    value: 'Utah',
  },
  {
    label: 'Vermont',
    value: 'Vermont',
  },
  {
    label: 'Virgin Islands',
    value: 'Virgin Islands',
  },
  {
    label: 'Virginia',
    value: 'Virginia',
  },
  {
    label: 'Washington',
    value: 'Washington',
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
  },
];

export const stateCodeMap = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const validatingOldDate = (mydate: string) => {
  const isdate = Date.parse(mydate);
  if (Number.isNaN(isdate)) {
    return false;
  }

  const enteredDate = new Date(isdate);
  const todayIs = new Date();

  if (isDate18orMoreYearsOld(mydate) === false) {
    return 'You must be 18 or older \n to take a test.';
  }
  if (enteredDate.getFullYear() < todayIs.getFullYear() - 150) {
    return 'Please enter a valid birthdate.';
  }

  return undefined;
};

const isDate18orMoreYearsOld = (dob: string) => {
  const dobValues = dob.split('-');
  const year = parseInt(dobValues[0]);
  const month = parseInt(dobValues[1]);
  const day = parseInt(dobValues[2]);

  return new Date(year + 18, month - 1, day) <= new Date();
};

export const fields: {
  name: FieldName;
  label: string;
  type: string;
  textContentType: TextInputIOSProps['textContentType'];
  options?: { value: string; label: string }[];
  sectionLabel?: string;
  half?: boolean;
  placeholder?: string;
  rules: {
    required: boolean;
    valueAsNumber?: boolean;
    valueAsDate?: boolean;
    minLength?: {
      value: number;
      message: string;
    };
    maxLength?: {
      value: number;
      message: string;
    };
    message?: string;
    // eslint-disable-next-line no-unused-vars
    validate?: (e: string) => void;
  };
}[] = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    sectionLabel: 'Patient Information',
    textContentType: 'givenName',
    placeholder: '',
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    textContentType: 'familyName',
    placeholder: '',
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'phone',
    label: 'Phone Number',
    type: 'phone',
    textContentType: 'telephoneNumber',
    placeholder: '',
    rules: {
      required: true,
      minLength: {
        value: 14,
        message: 'Please enter your full phone number.',
      },
      message: 'This field is required.',
    },
  },
  {
    name: 'birthDate',
    label: 'Date of Birth',
    type: 'date',
    textContentType: 'none',
    half: true,
    placeholder: '',
    rules: {
      required: true,
      valueAsDate: true,
      minLength: {
        value: 8,
        message: 'Please enter your full date of birth.',
      },
      validate: validatingOldDate,
    },
  },
  {
    name: 'gender',
    label: 'Sex Assigned at Birth',
    type: 'radio',
    textContentType: 'none',
    options: [
      { label: 'Female', value: 'female' },
      { label: 'Male', value: 'male' },
    ],
    half: true,
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'addressLine1',
    label: 'Street Address',
    type: 'text',
    sectionLabel: 'Shipping Information',
    textContentType: 'streetAddressLine1',
    placeholder: '',
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'addressLine2',
    label: 'Apartment, Suite, Etc. (optional)',
    type: 'text',
    textContentType: 'streetAddressLine2',
    placeholder: '',
    rules: {
      required: false,
    },
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    textContentType: 'addressCity',
    half: true,
    placeholder: '',
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'state',
    label: 'State',
    type: 'select',
    textContentType: 'none',
    options: states,
    half: true,
    placeholder: '',
    rules: {
      required: true,
      message: 'This field is required.',
    },
  },
  {
    name: 'zip',
    label: 'Zip Code',
    type: 'text',
    textContentType: 'none',
    placeholder: '',
    rules: {
      required: true,
      minLength: {
        value: 5,
        message: 'zip should be 5 digits',
      },
      maxLength: {
        value: 5,
        message: 'zip should be 5 digits',
      },
      message: 'This field is required.',
    },
  },
];

export const createPatientBody = ({
  firstName,
  lastName,
  phone,
  city,
  state,
  zip,
  addressLine1,
  addressLine2,
  gender,
  birthDate,
  bypassAddressValidation,
}: {
  firstName: string;
  lastName: string;
  phone: string;
  city: string;
  state: StateName;
  zip: string;
  addressLine1: string;
  addressLine2: string;
  gender: string;
  birthDate: string;
  bypassAddressValidation: boolean;
}) => ({
  name: [
    {
      given: [firstName],
      family: lastName,
    },
  ],
  telecom: [
    {
      system: 'phone',
      value: phone,
    },
  ],
  address: [
    {
      city,
      state: stateCodeMap[state],
      postalCode: zip,
      country: 'United States of America',
      line: [addressLine1, ...(addressLine2 && [addressLine2])],
    },
  ],
  gender,
  birthDate: format(parse(birthDate, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd'),
  bypassAddressValidation,
});
