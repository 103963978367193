import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Control, Controller } from 'react-hook-form';
import { Picker } from '@react-native-picker/picker';

import Text from 'components/StyledText';
import TextInput from 'components/StyledTextInput';
import GlobalStyles from 'constants/Styles';
import { PersonalInfoRegistrationFields } from 'types';
import { states } from '../../utils';

const ShippingInformation = ({
  control,
}: {
  control?: Control<PersonalInfoRegistrationFields>;
}): JSX.Element => (
  <>
    <View style={styles.addressRow}>
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'addressLine1'}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <View style={styles.marginField}>
            <Text style={styles.fieldLabel}>Address Line 1</Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[
                GlobalStyles.input,
                styles.addressField,
                error && GlobalStyles.inputError,
              ]}
              testID={'addressLine1'}
              accessible={true}
            />
          </View>
        )}
      />
      <Controller
        control={control}
        name={'addressLine2'}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <View style={styles.column}>
            <Text style={styles.fieldLabel}>
              Apartment, Suite, Etc. (optional)
            </Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[
                GlobalStyles.input,
                styles.addressField,
                error && GlobalStyles.inputError,
              ]}
              testID={'addressLine2'}
              accessible={true}
            />
          </View>
        )}
      />
    </View>
    <View style={styles.row}>
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'city'}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <View style={styles.marginField}>
            <Text style={styles.fieldLabel}>City</Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[
                GlobalStyles.input,
                styles.cityField,
                error && GlobalStyles.inputError,
              ]}
              testID={'city'}
              accessible={true}
            />
          </View>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
        }}
        name={'state'}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <View style={styles.marginField}>
            <Text style={styles.fieldLabel}>State</Text>
            <Picker
              selectedValue={value}
              onValueChange={(val) => onChange(val)}
              onBlur={onBlur}
              style={[styles.picker, error && GlobalStyles.inputError]}
              testID="statePicker"
            >
              {states?.map((option) => (
                <Picker.Item
                  key={option.label}
                  label={option.label}
                  value={option.value}
                  testID={option.label}
                />
              ))}
            </Picker>
          </View>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: 'This field is required',
          pattern: {
            value: /^[0-9]*$/,
            message: 'Zip code must only contain numbers',
          },
          minLength: {
            value: 5,
            message: 'Zip code must have 5 digits',
          },
        }}
        name={'zip'}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <View style={styles.column}>
            <Text style={styles.fieldLabel}>Zip Code</Text>
            <TextInput
              onChangeText={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              style={[
                GlobalStyles.input,
                styles.zipField,
                error && GlobalStyles.inputError,
              ]}
              testID={'zipCode'}
              accessible={true}
              maxLength={5}
            />
          </View>
        )}
      />
    </View>
  </>
);

const styles = StyleSheet.create({
  fieldLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 0.8,
    color: '#2F2F2F',
  },
  addressRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  addressField: {
    width: 275,
  },
  cityField: {
    width: 175,
  },
  zipField: {
    width: 180,
  },
  picker: {
    height: 50,
    backgroundColor: '#F7F7F7',
    marginTop: 10,
    width: 180,
    paddingLeft: 16,
    borderWidth: 0,
  },
  marginField: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default ShippingInformation;
