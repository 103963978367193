import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
  Image,
  useWindowDimensions,
} from 'react-native';
import '@expo/match-media';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { ApolloError, gql, useMutation } from '@apollo/client';
import * as Sentry from 'sentry-expo';

import reportClient, { GET_KIT_ID } from 'kit-activation';
import { ReportLocalization } from 'sample-collection-instructions';
import { GET_PATIENT } from 'screens/dashboard/PatientDashboardScreen/queries';
import { Page, Patient } from 'types';

import Text from './StyledText';
import { Layout, ScrollLayout } from './StyledView';
import { Header } from './ReportHeader';
import { Footer, ScrollingFooterNavigation } from './ReportFooter';
import { Notification } from './Icons';

export const GET_RESULT_REPORT = gql`
  mutation GetResultReport($kitId: String!, $patientId: String!) {
    getResultReport(kitId: $kitId, patientId: $patientId)
  }
`;

export const Container = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 900;
  if (Platform.OS === 'web') {
    return (
      <View style={isLargeScreen ? styles.test2Large : styles.test2}>
        {children}
      </View>
    );
  }
  return <View style={styles.containerContent}>{children}</View>;
};

export const Content = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  const isIOS = Platform.OS === 'ios';
  if (isMobile) {
    return (
      <ScrollLayout marginTop={isIOS && 'xl'} style={styles.content}>
        {children}
      </ScrollLayout>
    );
  }
  return (
    <ScrollLayout
      contentContainerStyle={styles.contentDesktop}
      style={styles.content}
    >
      {children}
    </ScrollLayout>
  );
};
export const Span = ({
  children,
  bold,
  uppercase,
}: {
  children: React.ReactNode;
  bold?: boolean;
  uppercase?: boolean;
}): JSX.Element => (
  <Text style={[bold && styles.bold, uppercase && styles.uppercase]}>
    {children}
  </Text>
);
export const Paragraph = ({
  children,
  leftAlignOnMobile,
}: {
  children: React.ReactNode;
  leftAlignOnMobile?: boolean;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Text
      regular
      style={[
        styles.paragraph,
        leftAlignOnMobile && isMobile && { textAlign: 'left' },
      ]}
    >
      {children}
    </Text>
  );
};
export const ContentTitle = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Text
      large
      lora
      fontWeight={'500'}
      style={[styles.contentTitle, isMobile && { textAlign: 'left' }]}
    >
      {children}
    </Text>
  );
};
export const ContentSubTitle = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Layout paddingVertical={'small'}>
      <Text
        fontWeight={'700'}
        tiny
        style={[styles.contentSubTitle, isMobile && { textAlign: 'center' }]}
      >
        {children}
      </Text>
    </Layout>
  );
};

export const PortalHeader = (): JSX.Element => {
  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions.width >= 780;
  const [notif] = React.useState(false);
  return (
    <Layout style={styles.layoutPortalHeader}>
      <Layout style={styles.portalHeader}>
        {!isMediumScreen ? (
          <Text xxl fontWeight={'700'}>
            im
            <Text xxl fontWeight={'400'}>
              aware
              <Text medium style={styles.navBrandTrademark}>
                ™
              </Text>
            </Text>
          </Text>
        ) : null}
      </Layout>
      <Notification
        bellColor="#C2CFE0"
        notifColor={notif ? '#F7685B' : 'none'}
        notifOutline={notif ? '#FFFFFF' : 'none'}
        size={30}
      />
      <TouchableOpacity style={styles.btnImage}>
        <Image
          style={styles.image}
          source={{
            uri: 'https://i.pinimg.com/originals/ee/0e/70/ee0e70b2ae91f7209f3a78247986e280.jpg',
          }}
        />
      </TouchableOpacity>
    </Layout>
  );
};

export const ReportContainer = ({
  children,
  title,
  screen,
  navigation,
  pages,
  setLoading,
  setError,
}: {
  children: React.ReactNode;
  title: string;
  screen: string;
  navigation: DrawerNavigationHelpers;
  pages: Page[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });

  const kitIdData = reportClient.cache.readQuery<{ kitId: string }>({
    query: GET_KIT_ID,
  });

  const patientData = reportClient.cache.readQuery<{ getPatient: Patient }>({
    query: GET_PATIENT,
  });

  const kitId = kitIdData?.kitId ?? '';
  const patient = patientData?.getPatient ?? ({} as Patient);

  const [getResultReport] = useMutation(GET_RESULT_REPORT, {
    onCompleted: ({ getResultReport: reportBuffer }) => {
      const { given = [], family } = patient.name?.[0] ?? {};
      const currentDate = format(new Date(), 'MMM d, yyyy');
      /* We don't run this code block in a test environment since Blobs are only available in Node and can't be easily replicated through jest */
      if (process.env.NODE_ENV !== 'test') {
        const blob = new Blob([Buffer.from(reportBuffer, 'base64')], {
          type: 'application/pdf',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = `Report - ${given[0]} ${family} - ${currentDate}.pdf`;
        link.download = fileName;
        link.click();
      }
      setLoading(false);
    },
    onError: (err) => {
      Sentry.Browser.captureException(err, {
        extra: {
          event: 'GetResultReport',
          message: `Result report retrieval for kit id ${kitId} failed`,
          patientId: patient?.id,
        },
        user: {
          email: patient?.telecom?.[1]?.value ?? 'No email found',
        },
      });
      console.error(err);
      setError(err);
    },
  });

  const onDownloadReport = () => {
    setLoading(true);
    getResultReport({
      variables: { kitId, patientId: patient.id },
    });
  };

  return (
    <ReportLocalization.Consumer>
      {(localization) => (
        <SafeAreaProvider>
          <Container>
            <Header
              localization={localization}
              title={title}
              navigation={navigation}
              onDownloadReport={onDownloadReport}
            />
            <Content>
              {children}
              <ScrollingFooterNavigation
                localization={localization}
                navigation={navigation}
                pages={pages.filter((p) => p.goToButton !== 'View Background')}
                screen={screen}
              />
            </Content>
            {!isMobile && (
              <Footer
                localization={localization}
                navigation={navigation}
                pages={pages.filter((p) => p.goToButton !== 'View Background')}
                screen={screen}
              />
            )}
          </Container>
          {isMobile && (
            <Footer
              localization={localization}
              navigation={navigation}
              pages={pages.filter((p) => p.goToButton !== 'View Background')}
              screen={screen}
            />
          )}
        </SafeAreaProvider>
      )}
    </ReportLocalization.Consumer>
  );
};

export const ContentHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <Layout marginTop={isMobile && 'small'} marginHorizontal={isMobile && 'xl'}>
      {title ? <ContentSubTitle>{title}</ContentSubTitle> : null}
      {subtitle ? <ContentTitle>{subtitle}</ContentTitle> : null}
    </Layout>
  );
};

export const InformationParagraph = ({
  onPress,
}: {
  onPress: () => void;
}): JSX.Element => {
  const isMobile = useMediaQuery({
    maxWidth: 600,
  });
  return (
    <View>
      <Layout
        padding={'small'}
        style={
          isMobile
            ? styles.mobileBiomarkerContainer
            : styles.containerBiomarkers
        }
      >
        <View
          style={
            isMobile
              ? styles.paragraphMobileContainer
              : styles.paragraphContainer
          }
        >
          <Text lato style={styles.biomarkersInformationText}>
            We were unable to gather enough data for all of the biomarkers
            included in the panel.
          </Text>
          <TouchableOpacity
            style={isMobile ? styles.learnMoreMobileBtn : styles.learnMoreBtn}
            onPress={onPress}
          >
            <Text style={styles.learnText}>Learn More</Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </View>
  );
};

const styles = StyleSheet.create({
  hoverContainer: {
    paddingBottom: 3,
    marginBottom: -3,
  },
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  containerContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  test2Large: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    // margin: 50, uncomment when ready for dashboard view
  },
  test2: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  headerMobile: {
    flexDirection: 'column',
  },
  headerTitle: {
    fontSize: 20,
  },
  headerButtons: {
    flexDirection: 'row',
    marginHorizontal: -12,
  },
  headerButton: {
    marginHorizontal: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderRadius: 50,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  headerButtonPrimary: {
    backgroundColor: '#000',
  },
  headerButtonSecondary: {
    backgroundColor: '#fff',
  },
  buttonHover: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    elevation: 5,
    transform: [
      {
        translateY: -2,
      },
    ],
  },
  headerButtonTextPrimary: {
    color: '#fff',
  },
  headerButtonTextSecondary: {
    color: '#000',
  },
  test: {
    width: '100%',
    height: '100vh',
  },
  content: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  contentTitle: {
    color: '#000',
    textAlign: 'center',
    letterSpacing: 0.27,
  },
  contentSubTitle: {
    textTransform: 'uppercase',
    color: 'rgb(120, 120, 120)',
    textAlign: 'center',
  },
  paragraph: {
    lineHeight: 25,
    marginTop: 16,
    marginBottom: 16,
  },
  bold: {
    fontWeight: 'bold',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  navigationContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
  nextButton: {
    backgroundColor: '#000',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
  },
  nextButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  previousButton: {
    backgroundColor: '#fff',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
  },
  previousButtonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
  },
  portalHeader: { flex: 1, textAlign: 'center', letterSpacing: 1.5 },
  navBrandTrademark: { lineHeight: 22, textAlignVertical: 'top' },
  contentDesktop: { minHeight: '100%' },
  layoutPortalHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnImage: { marginRight: '3%', marginLeft: 25 },
  image: { width: 45, height: 45, borderRadius: 50 },
  paragraphContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
  paragraphMobileContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'baseline',
    padding: '10px',
    height: '100%',
  },
  biomarkersInformationText: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'roboto_500',
  },
  containerBiomarkers: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 96, 24, 0.17)',
    alignSelf: 'center',
  },
  mobileBiomarkerContainer: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 96, 24, 0.17)',
    alignSelf: 'center',
    height: 126,
  },
  learnMoreMobileBtn: {
    display: 'flex',
    paddingHorizontal: 45,
    borderRadius: 20,
    borderStyle: 'solid',
    borderColor: 'black',
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  learnMoreBtn: {
    display: 'flex',
    paddingHorizontal: 40,
    borderRadius: 20,
    borderStyle: 'solid',
    borderColor: 'black',
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  learnText: {
    fontSize: 11,
    fontFamily: 'roboto_500',
  },
});
